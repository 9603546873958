/**
 * ReferAndEarn Messages
 *
 * This contains all the text for the ReferAndEarn Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.ReferAndEarn";

export default defineMessages({
  copytext: {
    id: `${scope}.copytext`,
    defaultMessage: "Copy Link",
  },
  invite: {
    id: `${scope}.invite`,
    defaultMessage: "Invite Friends Via:",
  },
  contacts: {
    id: `${scope}.contacts`,
    defaultMessage: "Invite Google Contacts",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Refer & Earn Mad Coins",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Introduce a friend to Mad Fan and Get 100 Mad Coins",
  },


  
});
