/**
 *
 * Quiz Component
 *
 */
import React, { useState, useEffect, Suspense } from "react";
import { Switch, Route, useHistory, useParams } from "react-router-dom";

import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";

import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowWidth } from "@react-hook/window-size";
import {
    MEDIA_DEVICE_DESKTOP,
    MEDIA_DEVICE_MOBILE,
    BREAKING_POINT,
} from "../../utils/constants";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Influencer } from "../SearchCeleb/reducer";
import ImageCard from "../../components/ImageCard";
import PlayQuiz from "../../components/PlayQuiz";
import EarnedCoin from "../../components/EarnedCoin";
import BonusCoin from "../../components/BonusCoin";
import { Dialog } from "@material-ui/core";

type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    loading: boolean;

    influencers: Influencer[];

    load: () => void;

    onLoadQuiz: (id: string) => void;

    setStartQuiz: (id: string) => void;

    acceptQuiz: (inf: any) => void;
};

function Quiz(props: Props) {
    const { classes, style, className, formatMessage, loading, load } = props;

    useEffect(() => {
        load();
    }, []);

    const history = useHistory();

    const mediaDevice =
        useWindowWidth() > BREAKING_POINT
            ? MEDIA_DEVICE_DESKTOP
            : MEDIA_DEVICE_MOBILE;
    return (
        <div className={classnames(classes.container, className)} style={style}>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <Switch>
                    <Suspense fallback={() => { }}>
                        <Route
                            path="/profile/win-coins/quiz/:id"
                            render={(componentProps) => (
                                <InfluencerQuiz
                                    {...props}
                                    {...componentProps}
                                    mediaDevice={mediaDevice}
                                    history={history}
                                />
                            )}
                        />
                        <Route
                            path="/profile/win-coins/quiz"
                            exact
                            render={(componentProps) => (
                                <QuizInfluencerList
                                    {...props}
                                    {...componentProps}
                                    mediaDevice={mediaDevice}
                                    history={history}
                                />
                            )}
                        />
                    </Suspense>
                </Switch>
            )}
        </div>
    );
}

function InfluencerQuiz(props) {
    const {
        classes,
        questions,
        quizLoading,
        onLoadQuiz,
        formatMessage,
        mediaDevice,
        history,
        onQuizAnswered,
        singleCoinEarned,
    } = props;
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            onLoadQuiz(id);
        }
    }, [id]);

    const [quesIndex, setQuesIndex] = useState(0);
    const [showEarnedCoin, setShowEarnedCoin] = useState(0);
    const [totalCorrect, setTotalCorrect] = useState(0);

    const coinEarned =
        quesIndex === questions.length ? (
            <BonusCoin
                allCorrect={totalCorrect === questions.length}
                onClick={() => history.push("/profile/win-coins/quiz")}
                coinReward={singleCoinEarned}
            />
        ) : (
            <EarnedCoin
                message={
                    showEarnedCoin === 1 ? "Answer is correct" : "Answer is incorrect"
                }
                buttonName="Next"
                onClick={() => {
                    setQuesIndex(quesIndex + 1);
                    if (quesIndex !== questions.length - 1) setShowEarnedCoin(0);
                }}
            />
        );

    return (
        <div className={classes.container}>
            {quizLoading ? (
                <CircularProgress color="secondary" />
            ) : (
                <div className={classes.box}>
                    <div className={classes.header}>
                        <div className={classes.winCoins}>
                            {mediaDevice === MEDIA_DEVICE_MOBILE && (
                                <FontAwesomeIcon icon={faCoins} className={classes.coinIcon} />
                            )}
                            <span>{formatMessage(messages.winCoins)}</span>
                        </div>
                        <div className={classes.subTitle}>
                            <Breadcrumbs separator=">">
                                <Link
                                    className={classes.link}
                                    onClick={() => history.push("/profile/win-coins/quiz")}
                                >
                                    {formatMessage(messages.playQuiz)}
                                </Link>
                                <Link
                                    className={classnames(classes.link, classes.nameLink)}
                                    onClick={() => history.push(`/celebrity/${id}`)}
                                >
                                    {questions?.length && questions[0]?.name}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className={classes.contentBox}>
                        {!!showEarnedCoin
                            ? coinEarned
                            : questions[quesIndex] && (
                                <PlayQuiz
                                    quesNo={quesIndex + 1}
                                    quesLength={questions.length}
                                    question={questions[quesIndex].question}
                                    image={questions[quesIndex]?.question_image}
                                    options={[
                                        questions[quesIndex].option1,
                                        questions[quesIndex].option2,
                                        questions[quesIndex].option3,
                                        questions[quesIndex].option4,
                                    ]}
                                    onChange={(ans) => {
                                        if (ans === questions[quesIndex].answer) {
                                            setShowEarnedCoin(1);
                                            setTotalCorrect(totalCorrect + 1);
                                        } else {
                                            setShowEarnedCoin(2);
                                        }
                                        if (quesIndex === questions.length - 1) {
                                            onQuizAnswered(
                                                questions[quesIndex].id,
                                                questions[quesIndex].coin,
                                                ans,
                                                quesIndex === questions.length - 1,
                                                ans === questions[quesIndex].answer &&
                                                totalCorrect >= questions.length - 1
                                            );
                                        } else {
                                            onQuizAnswered(
                                                questions[quesIndex].id,
                                                questions[quesIndex].coin,
                                                ans,
                                                false,
                                                false
                                            );
                                        }
                                    }}
                                />
                            )}
                    </div>
                </div>
            )}
        </div>
    );
}

function QuizInfluencerList(props) {
    const {
        classes,
        formatMessage,
        mediaDevice,
        influencers,
        history,
        acceptQuiz,
        totalCoins,
        setStartQuiz,
        startQuiz,
    } = props;
    const [influencerList, setInfluencerList] = useState(influencers);
    useEffect(() => {
        setInfluencerList(influencers);
    }, [influencers]);
    useEffect(() => {
        if (startQuiz) {
            history.push(`/profile/win-coins/quiz/${startQuiz}`);
            setStartQuiz("");
        }
    }, [startQuiz]);
    const [selectedInf, setSelectedInf] = useState(null);
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <div className={classes.winCoins}>
                    {mediaDevice === MEDIA_DEVICE_MOBILE && (
                        <FontAwesomeIcon icon={faCoins} className={classes.coinIcon} />
                    )}
                    <span>{formatMessage(messages.winCoins)}</span>
                </div>
                <div className={classes.subTitle}>
                    {formatMessage(messages.playQuiz)}
                </div>
            </div>
            <div className={classes.influencers}>
                {influencerList?.map((inf) => (
                    <ImageCard
                        img={inf?.profile_photo}
                        className={classes.influencer}
                        alt={inf?.name}
                        size={mediaDevice === MEDIA_DEVICE_DESKTOP ? "large" : "small"}
                        celebName={inf?.name}
                        famousFor={inf?.familiar_for?.split(",")}
                        isHoverable={false}
                        price={inf?.coin_earned}
                        onClick={() => setSelectedInf(inf)}
                    />
                ))}
            </div>
            <Dialog open={!!selectedInf} onClose={() => setSelectedInf(null)}>
                <div
                    style={{
                        padding: 60,
                        boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
                    }}
                >
                    <EarnedCoin
                        message={
                            parseInt(totalCoins) < parseInt(selectedInf?.quiz_rate)
                                ? `You don't have sufficent coins to play this quiz`
                                : `You will earn ${selectedInf?.coin_earned}  Mad Coins.`
                        }
                        buttonName={
                            parseInt(totalCoins) < parseInt(selectedInf?.quiz_rate)
                                ? "Cancel"
                                : "Start"
                        }
                        onClick={() => {
                            parseInt(totalCoins) < parseInt(selectedInf?.quiz_rate)
                                ? setSelectedInf(null)
                                : acceptQuiz(selectedInf);
                        }}
                    />
                </div>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(withIntl(Quiz));
