/**
 *
 * otpModal Component
 *
 */
import React, { useState } from "react";
import styles from "./styles";
import withIntl from "../utils/withIntl";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import AdornedButton from "./spinnerBtn";

import AllActions from "../store/actions";
import axios from "../shared/axios-details";

type Props = {
  // internal
  classes: any;

  // internal
  formatMessage: Function;

  // class applied to top level container
  className?: Object;

  // style applied to top level container
  style?: Object;

  handleClose: any;

  open: boolean;
};

function OtpModal(props: Props) {
  const [otp, setOTP] = useState("");
  const [mobile, setMobile] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [stateResend, setStateResend] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const [otpSuccess, setOTPSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!otpSuccess) {
      setLoading(true);
      if (mobile.length === 10) {
        const token = {
          info_type: 1,
          mobile_number: mobile,
        };
        axios
          .post("/login_mobile.php", token)
          .then((res) => {
            //console.log(res.data);
            if (res.data.status) {
              setOTPSuccess(true);
              setError(res.data.message);
            } else {
              setError(res.data.message);
            }
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        setError("Mobile number should be of 10 digits.");
        setLoading(false);
      }
    } else {
      if (stateResend) {
        setLoadingResend(true);
        const token = {
          info_type: 3,
          mobile_number: mobile,
        };
        axios
          .post("/login_mobile.php", token)
          .then((res) => {
            //console.log(res.data);
            if (res.data.status) {
              setError(res.data.message);
            } else {
              setError(res.data.message);
            }
            setStateResend(false);
            setLoadingResend(false);
          })
          .catch((err) => {
            setStateResend(false);
            setLoadingResend(false);
            //console.log(err)
          });
      } else {
        setLoading(true);
        const token = {
          info_type: 2,
          mobile_number: mobile,
          otp: otp,
        };
        //console.log(token);
        axios
          .post("/login_mobile.php", token)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              console.log("done");
              dispatch(
                AllActions.Auth.auth(res.data.data.token, res.data.data.name)
              );
              props.handleClose();
            } else {
              setError(res.data.message);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  };

  const { classes, formatMessage, className, style, handleClose, open } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      className={classes.modal}
      style={{ backgroundColor: "transparent" }}
    >
      <div className={classes.container}>
        <div className={classes.modalBox} style={style}>
          <div className={classes.heading}>Enter Mobile Number</div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15%",
                marginLeft: 0,
                marginRight: 0,
              }}
              spacing={1}
            >
              {error ? (
                <Grid
                  item
                  xs={12}
                  style={{ color: "red", fontSize: "1em", marginLeft: "8px" }}
                >
                  {error}
                </Grid>
              ) : null}
              {!otpSuccess ? (
                <>
                  <Grid item xs={12}>
                    <input
                      type="number"
                      required
                      value={mobile}
                      className={classes.input}
                      placeholder="Mobile Number"
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <AdornedButton loading={loading} bg="#1975d2">
                      Submit{loading ? <span>&nbsp;</span> : null}
                    </AdornedButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <input
                      type="number"
                      value={otp}
                      className={classes.input}
                      placeholder="OTP"
                      onChange={(e) => setOTP(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <AdornedButton
                      loading={loadingResend}
                      bg="#dd3f4e"
                      onClick={() => setStateResend(true)}
                    >
                      Resend{loadingResend ? <span>&nbsp;</span> : null}
                    </AdornedButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <AdornedButton loading={loading} bg="#1975d2">
                      Submit{loading ? <span>&nbsp;</span> : null}
                    </AdornedButton>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(withIntl(OtpModal));
