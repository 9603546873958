/**
 * LoginModal Messages
 * 
 * This contains all the text for the LoginModal Component
 * 
 */

import {defineMessages} from 'react-intl';

export const scope = 'app.component.LoginModal';

export default defineMessages({
    heading:{
        id:`${scope}.heading`,
        defaultMessage:"Glad to meet you!",
    }
})