const styles = (theme: any) => ({
  container: {
    width: "60%",
    margin: "auto",
    fontFamily: "Poopins",
    padding: 20,
  },
  header: {
    fontSize: 24,
    color: "#e90041",
    textAlign: "center",
    fontWeight: "900",
  },
  content: { fontSize: 16, textAlign: "center", opacity: 0.7 },
  "@media (max-width: 967px)": {
    container: { width: "80%" },
    header: { fontSize: 20 },
  },
  "@media (max-width: 600px)": { container: { width: "100%" } },
});

export default styles;
