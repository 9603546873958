/**
 *
 * NavigationLink Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classnames from "classnames";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  icon: any;

  name: string;

  selected?: boolean;

  onClick: () => void;
};

function NavigationLink(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    icon,
    name,
    selected,
    onClick,
  } = props;
  const buttonClass = classnames(classes.btn, {
    [classes.btnSelected]: selected,
    className,
  });
  return (
    <Button
      onClick={onClick}
      variant="contained"
      classes={{ root: buttonClass }}
      disableElevation
      startIcon={
        <div
          style={{
            fontSize: 20,
            color: selected ? "#e90041" : "#7f7075",
            marginRight: 16,
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      }
      size="large"
      fullWidth
    >
      {name}
    </Button>
  );
}

export default withStyles(styles)(withIntl(NavigationLink));
