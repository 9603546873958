/**
 *
 * Bookings Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "../Button";
import ImageCard from "../ImageCard";
type Props = {
  /** internal */
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  img: any;

  alt: string;

  price: number;

  celebName: string;

  famousFor: string[];

  date: string;

  size: string;

  eventname: string;
  onClick: () => void;
};

function Bookings(props: Props) {
  const {
    classes,
    img,
    alt,
    price,
    celebName,
    famousFor,
    date,
    eventname,
    size,
    onClick,
  } = props;

  return (
    <Card className={classes.container}>
      {/* <CardActionArea>
      <CardMedia
        classes={{
          media: classes.media,
        }}
        image={img}
        title={alt}
        component="img"
        onClick={onClick}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <div className={classes.celebName}>{celebName}</div>
        <div className={classes.famousFor}>{famousFor && famousFor.join(", ")}</div>
      </CardContent>
    </CardActionArea>
    <CardActions classes={{ root: classes.cardActionRoot }}>
      <Button
        className={classes.priceButton}
        name={`$${price}`}
        buttonSize="small"
        buttonType="primary"
        onClick={onClick}
      />
    </CardActions> */}
      <ImageCard
        img={img}
        alt={alt}
        price={price}
        celebName={celebName}
        size={size}
        famousFor={famousFor}
        onClick={onClick}
      />
      <h3 className={classes.eventName}>{eventname}</h3>
      <div className={classes.datehead}>Tentative Receiving Date:</div>
      <span className={classes.datevalue}>{date}</span>
    </Card>
  );
}

export default withStyles(styles)(withIntl(Bookings));
