import { put, call, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import { useHistory } from "react-router-dom";
import axios from "../../shared/axios-details";
import {
  setLoading,
  setInfluencers,
  setQuizQuestions,
  setQuizLoading,
  startQuiz,
} from "./actions";
import { setUserCoins, getNotifications } from "./../App/actions";
import { selectToken } from "./../App/selectors";

const quizApi = (infoType: string, token: string, options: {} = {}) => {
  return axios
    .post("/quiz.php", {
      info_type: infoType,
      token,
      ...options,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
function* onLoad() {
  try {
    const token = yield select(selectToken);
    if (token) {
      const influencers = yield call(quizApi, "1", token);
      if (influencers.status) {
        yield put(setInfluencers(influencers.data));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* onLoadQuiz(action) {
  const id = action.payload;
  try {
    const token = yield select(selectToken);
    if (token) {
      const quizQues = yield call(quizApi, "2", token, { influencer_id: id });
      if (quizQues.status) {
        yield put(setQuizQuestions(quizQues.data, quizQues.coin));
      }
    }
    yield put(setQuizLoading(false));
  } catch (e) {
    yield put(setQuizLoading(false));
  }
}

function* handleQuizAnswered(action) {
  // const { question_id, coin, answer,is_last,all_correct } = action.payload;
  try {
    const token = yield select(selectToken);
    if (token) {
      const res = yield call(quizApi, "3", token, action.payload);
      if (res.status) {
        yield put(setUserCoins(res.total_coin));
        yield put(getNotifications());
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* onAcceptQuiz(action) {
  const inf = action.payload;
  try {
    const token = yield select(selectToken);
    if (token) {
      const res = yield call(quizApi, "4", token, {
        influencer_id: inf.influencer_id,
      });
      if (res.status) {
        yield put(setUserCoins(res.coin));
        yield put(startQuiz(inf.influencer_id));
        yield put(getNotifications());
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* quizSaga() {
  yield takeLatest(ACTION_TYPES.LOAD, onLoad);
  yield takeLatest(ACTION_TYPES.ON_LOAD_QUIZ, onLoadQuiz);
  yield takeLatest(ACTION_TYPES.ON_QUIZ_ANSWERED, handleQuizAnswered);
  yield takeLatest(ACTION_TYPES.ACCEPT_QUIZ, onAcceptQuiz);
}
