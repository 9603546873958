/**
 *
 * UserProfile actions
 *
 */

import * as ACTION_TYPES from "./constants";

export function onRedeemGiftCard(data: any) {
  return {
    type: ACTION_TYPES.ON_REDEEM_GIFT_CARD,
    payload: data,
  };
}
export function setRedeemLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_REDEEM_LOADING,
    payload: value,
  };
}
export function setRedeemStatus(status: boolean, message: string) {
  return {
    type: ACTION_TYPES.SET_REDEEM_STATUS,
    payload: { status, message },
  };
}
export function sendMails(payload: []) {
  return {
    type: ACTION_TYPES.SEND_MAILS,
    payload,
  };
}
export function setSendingMailSuccess(payload: boolean) {
  return {
    type: ACTION_TYPES.SET_SENDING_MAIL_SUCCESS,
    payload,
  };
}
