import { put, call, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import {
  setLoading,
  setInfluencerDetail,
  setRecommendedInfs,
  setOrderDetail,
  setCouponLoading,
  setCouponDiscount,
  setDiscountedAmount,
} from "./actions";
import { setUserCoins, getNotifications } from "../App/actions";
import { selectToken } from "../App/selectors";

const getInfluencer = (celebId: string, infoType: string, token: any) => {
  return axios
    .post("/influencer.php", {
      info_type: infoType,
      influencer_id: celebId,
      token:token
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getRecommendedInfuencers = (infoType: string, id: String) => {
  return axios
    .post("/influencer.php", {
      info_type: infoType,
      influencer_id: id,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const bookOrder = (data: any, token: string, infoType: string) => {
  return axios
    .post("/order.php", {
      ...data,
      token,
      info_type: infoType,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const applyCoupon = (data: any, token: string, infoType: string) => {
  return axios
    .post("/coupons.php", {
      ...data,
      token,
      info_type: infoType,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

function* onLoad(action) {
  const celebId = action.payload;

  try {
    const token = yield select(selectToken);
    const influencer = yield call(getInfluencer, celebId, "2", token);
    if (influencer.status) {
      yield put(setInfluencerDetail(influencer.data[0]));
    }
    const recommendedInfs = yield call(getRecommendedInfuencers, "3", celebId);
    if (recommendedInfs.status) {
      yield put(setRecommendedInfs(recommendedInfs.data));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* onBookOrder(action) {
  const data = action.payload;

  try {
    setLoading(true);
    const token = yield select(selectToken);
    if (token) {
      const res = yield call(bookOrder, data, token, "1");
      if (res.status) {
        yield put(setOrderDetail(res.status, res.message));
        yield put(setUserCoins(res.total_coin));
        yield put(getNotifications());
      }
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
}
function* onApplyCoupon(action) {
  const data = action.payload;
  try {
    const token = yield select(selectToken);
    if (token) {
      const res = yield call(applyCoupon, data, token, "1");
      if (res.status) {
        if (res.message === "Success") {
          yield put(setCouponDiscount(res.data.discount));
          yield put(setDiscountedAmount(res.data.final_amt, data.video_type));
        } else {
          yield put(setCouponDiscount(0));
        }
      }
    }
    yield put(setCouponLoading(false));
  } catch (e) {
    yield put(setCouponLoading(false));
  }
}
export function* celebritySaga() {
  yield takeLatest(ACTION_TYPES.LOAD, onLoad);
  yield takeLatest(ACTION_TYPES.BOOK_ORDER, onBookOrder);
  yield takeLatest(ACTION_TYPES.APPLY_COUPON, onApplyCoupon);
}
