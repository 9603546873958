const styles = (theme: any) => ({
  wrapper: {
    maxWidth: 333,
    height: "auto",
    borderRadius: 20,
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    cursor: "pointer",
    paddingTop: 40,
  },
  close: {
    justifyContent: "flex-end",
    display: "flex",
    padding: 20,
  },
  circle: {
    maxHeight: 160,
    maxWidth: 160,
    margin: "auto",
  },
  circleIcon: {
    height: "100%",
    width: "100%",
  },
  header: {
    fontFamily: "Poppins",
    fontSize: 28,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    width: 258,
    margin: "auto",
    marginTop: 16,
  },
  success: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    width: 258,
    margin: "auto",
  },
  bottom: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    paddingBottom: 30,
    marginTop: 15,
  },
  listheader: {
    borderRadius: 10,
  },
  listitem: {
    padding: 0,
    opacity: 0.76,
    fontSize: 15,
  },
  avatar: {
    padding: "6px 9px",
    background: "#fdb428",
    fontSize: 9,
    width: "auto",
    height: "auto",
    color: "black",
    fontWeight: "bold",
  },
  avatarwrapper: {
    marginRight: 20,
    minWidth: "auto",
  },

  "@media (max-width: 967px)": {
    wrapper: {
      height: "revert",
      maxWidth: "revert",
    },
    circleIcon: {
      fontSize: "10rem",
    },
    header: {
      fontSize: 26,
    },
    success: {
      fontSize: 13,
    },
  },
  "@media (max-width: 600px)": {
    wrapper: {
      height: "revert",
      maxWidth: "revert",
    },
    circleIcon: {
      fontSize: "10rem",
    },
    header: {
      fontSize: 26,
    },
    success: {
      fontSize: 13,
    },
  },
});

export default styles;
