/**
 *
 * Main
 *
 */
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";
import { selectIsLoggedIn } from "../App/selectors";
import Layout from "./layout";
import reducer from "./reducer";
import { selectUserDetails, selectNotifications } from "./../App/selectors";

import { editProfile } from "../App/actions";

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn,
  userDetails: selectUserDetails,
  notifications: selectNotifications,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editProfile }, dispatch);

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);
