import { UserDetails } from "./../App/reducer";
/**
 *
 * Quiz
 *
 */
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";

import Layout from "./layout";
import reducer from "./reducer";

import {
  load,
  onLoadQuiz,
  onQuizAnswered,
  acceptQuiz,
  startQuiz,
} from "./actions";
import {
  selectLoading,
  selectInfluencers,
  selectQuizQuestions,
  selectQuizLoading,
  selectStartQuiz,
  selectCoinEarned
} from "./selectors";

import { selectTotalCoins } from "../App/selectors";

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  influencers: selectInfluencers,
  questions: selectQuizQuestions,
  quizLoading: selectQuizLoading,
  startQuiz: selectStartQuiz,
  totalCoins: selectTotalCoins,
  singleCoinEarned: selectCoinEarned,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      load,
      onLoadQuiz,
      acceptQuiz,
      onQuizAnswered,
      setStartQuiz: startQuiz,
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);
