import produce from "immer";
import * as ACTION_TYPES from "./constants";
import { Influencer } from "../SearchCeleb/reducer";

export type InfluencerDetail = {
  profile: string;
  familiar_for: string;
  name: string;
  category: string;
  rate: string;
  rate_videocall: string;
  buy_rate: string;
  buy_rate_videocall: string;
  responds_in: number;
  id: number;
  description: string;
};
export type Celebrity = {
  loading: boolean;
  couponLoading: boolean;
  celebId: string;
  influencerDetail: InfluencerDetail;
  recommendedInfs: Influencer[];
  orderDetail: { status: boolean; message: string };
  couponDiscount: string | null;
};

export const initialState: Celebrity = {
  loading: false,
  couponLoading: false,
  celebId: "",
  influencerDetail: {},
  recommendedInfs: [],
  orderDetail: {},
  couponDiscount: null,
};

const celebrityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.DEFAULT_ACTION:
        break;
      case ACTION_TYPES.LOAD:
        draft.loading = true;
        draft.celebId = action.payload;
        break;
      case ACTION_TYPES.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ACTION_TYPES.SET_INFLUENCER_DETAIL:
        draft.influencerDetail = action.payload;
        break;
      case ACTION_TYPES.SET_RECOMMENDED_INFLUENCERS:
        draft.recommendedInfs = action.payload;
        break;
      case ACTION_TYPES.SET_ORDER_DETAIL:
        draft.orderDetail = action.payload;
        break;
      case ACTION_TYPES.APPLY_COUPON:
        draft.couponLoading = true;
        break;
      case ACTION_TYPES.SET_COUPON_LOADING:
        draft.couponLoading = action.payload;
        break;
      case ACTION_TYPES.SET_COUPON_DISCOUNT:
        draft.couponDiscount = action.payload;
        break;
      case ACTION_TYPES.SET_DISCOUNTED_AMOUNT: {
        if (action.payload.videoFor === "Video") {
          draft.influencerDetail.buy_rate = action.payload.amount;
        }
        if (action.payload.videoFor === "Video-Call") {
          draft.influencerDetail.buy_rate_videocall = action.payload.amount;
        }
        break;
      }
    }
  });

export default celebrityReducer;
