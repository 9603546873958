/**
 *
 * WinMadCoins Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Container } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import quizL from "../../images/quizL.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  onClick: () => void;
};

function WinMadCoins(props: Props) {
  const { classes, style, className, formatMessage, onClick } = props;
  const { title, subtitle, step1, step2, step3, step4 } = messages;
  const ChallengeFragment = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.listheader}
    >
      <ListItem className={classes.listitem}>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>1</Avatar>
        </ListItemAvatar>
        <ListItemText primary={formatMessage(step1)} />
      </ListItem>
      <ListItem className={classes.listitem}>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>2</Avatar>
        </ListItemAvatar>
        <ListItemText primary={formatMessage(step2)} />
      </ListItem>
      <ListItem className={classes.listitem}>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>3</Avatar>
        </ListItemAvatar>
        <ListItemText primary={formatMessage(step3)} />
      </ListItem>
      <ListItem className={classes.listitem}>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>4</Avatar>
        </ListItemAvatar>
        <ListItemText primary={formatMessage(step4)} />
      </ListItem>
    </List>
  );
  return (
    <div className={classes.wrapper} style={style} onClick={onClick}>
      <Container>
        {/* <div className={classes.close}><CloseIcon /></div> */}
        <div className={classes.circle}>
          <img src={quizL} className={classes.circleIcon} alt="" />
        </div>
        <div className={classes.header}>{formatMessage(title)}</div>
        <div className={classes.success}>{formatMessage(subtitle)}</div>
        <div className={classes.bottom}>{<ChallengeFragment />}</div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(WinMadCoins));
