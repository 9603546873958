/**
 * RedeemInfoCard Messages
 *
 * This contains all the text for the RedeemInfoCard Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.RedeemInfoCard";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Gift Card",
  },
});
