const styles = (theme: any) => ({
  wrapper: {
    maxWidth: 500,
    height: "auto",
    borderRadius: 20,
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    paddingTop: 40,
  },
  close: {
    justifyContent: "flex-end",
    display: "flex",
    paddingTop: 20,
    cursor: "pointer",
  },
  circle: {
    justifyContent: "center",
    display: "flex",
  },

  header: {
    fontFamily: "Poppins",
    fontSize: 42,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
  },
  success: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    marginTop: 16,
  },
  bottom: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    paddingBottom: 30,
    marginTop: 15,
  },
  buttonBooking:{
    background: '#ed5756',
    color: '#ffffff',
    padding: '3px 15px',
    fontSize: 20,
    marginTop: 10,
    fontWeight: 500,
    borderRadius: 11,
    fontFamily: 'Poppins',
    marginTop: 25,
    "&:hover": {
      backgroundColor: "#ed5756",
    },
  },
  coinicon: {
    color: "#fdb428",
    textAlign: "left",
    height: 28,
    width: 25,
    marginRight: 3,
  },
  button: {
    color: "#e90041",

    padding: "10px 20px",
    backgroundColor: "#f3f3f3",
    borderRadius: 40,
    marginTop: 8,
    fontWeight: 500,
    fontSize: 24,
  },
  buttonwrapper: {
    marginTop: 28,
  },
  listheader: {
    borderRadius: 10,
    border: "solid 1px #fdb428",
    backgroundColor: "#fff6e5",
  },
  avatar: {
    padding: "6px 9px",
    background: "#fdb428",
    fontSize: 9,
    width: "auto",
    height: "auto",
    color: "black",
    fontWeight: "bold",
  },
  avatarwrapper: {
    marginRight: 20,
    minWidth: "auto",
  },
  challenge: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "black",
  },
  "@media (max-width: 967px)": {
    wrapper: {
      height: "revert",
      maxWidth: "revert",
    },
    circleIcon: {
      fontSize: "10rem",
    },
    header: {
      fontSize: 26,
    },
    success: {
      fontSize: 13,
    },
  },
  "@media (max-width: 600px)": {
    wrapper: {
      height: "revert",
      maxWidth: "revert",
    },
    circleIcon: {
      fontSize: "10rem",
    },
    header: {
      fontSize: 26,
    },
    success: {
      fontSize: 13,
    },
  },
});

export default styles;
