/**
 *
 * Quiz constants
 *
 */

const scene = "app/Quiz";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const LOAD = `${scene}/LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_INFLUENCERS = `${scene}/SET_INFLUENCERS`;
export const ON_LOAD_QUIZ = `${scene}/ON_LOAD_QUIZ`;
export const SET_QUIZ_QUESTIONS = `${scene}/SET_QUIZ_QUESTIONS`;
export const SET_QUIZ_LOADING = `${scene}/SET_QUIZ_LOADING`;
export const ON_QUIZ_ANSWERED = `${scene}/ON_QUIZ_ANSWERED`;
export const ACCEPT_QUIZ = `${scene}/ACCEPT_QUIZ`;
export const START_QUIZ = `${scene}/START_QUIZ`;
