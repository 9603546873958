import produce from "immer";
import { Influencer } from "../SearchCeleb/reducer";
import * as ACTION_TYPES from "./constants";

export type Question = {
  id: string;
  question: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  answer: string;
  coin: string;
  name: string;
  question_image: string;
};

export type Quiz = {
  loading: boolean;
  quizLoading: boolean;
  influencers: Influencer[];
  quizQuestions: Question[];
  coinEarned:any;
  singleCoinEarned: any;
};

export const initialState: Quiz = {
  loading: false,
  influencers: [],
  quizQuestions: [],
  quizLoading: false,
  startQuiz: "",
  singleCoinEarned: ""
};

const quizReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.DEFAULT_ACTION:
        break;
      case ACTION_TYPES.LOAD:
        draft.loading = true;
        break;
      case ACTION_TYPES.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ACTION_TYPES.SET_INFLUENCERS:
        draft.influencers = action.payload;
        break;
      case ACTION_TYPES.ON_LOAD_QUIZ:
        draft.quizLoading = true;
        break;
      case ACTION_TYPES.SET_QUIZ_QUESTIONS:
        draft.quizQuestions = action.payload;
        draft.singleCoinEarned = action.coin;
        break;
      case ACTION_TYPES.SET_QUIZ_LOADING:
        draft.quizLoading = action.payload;
        break;
      case ACTION_TYPES.START_QUIZ:
        draft.startQuiz = action.payload;
        break;
    }
  });

export default quizReducer;
