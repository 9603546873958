/**
 *
 * BookVideoForm Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RequestType } from "../CelebProfile/enums";
import { useForm, Controller } from "react-hook-form";
import classnames from "classnames";
import coin from "../../images/coin.png";
import tag from "../../images/tag.png";
import leftIcon from "../../images/leftIcon.png";
import arrow from "../../images/arrow.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  paymentMethods?: { methodName: string; img: any }[];

  price: number;

  loading?: boolean;

  resetData?: boolean;

  bookingType: number;

  onApplyCoupon: () => void;

  onBook: (data: {}) => void;
};

function convertDate(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function BookVideoForm(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    paymentMethods,
    price,
    loading,
    resetData,
    bookingType,
    onApplyCoupon,
    onBook,
  } = props;

  const [videoFor, setVideoFor] = useState(0);
  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      to: "",
      from: "",
      email: "",
      contact: "",
      message: "",
      paymentMethod: "none",
      callDuration: "",
    },
  });

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  useEffect(() => {
    reset();
    setVideoFor(0);
    // setSelectedDate(null);
  }, [resetData]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <div className={classnames(className, classes.container)} style={style}>
      <div className={classes.bookVideo}>
        {formatMessage(messages.bookVideo)}
      </div>
      <div className={classes.awesome}>{formatMessage(messages.awesome)}</div>
      <form>
        <div className={classes.card}>
          <div className={classes.table}>
            <div className={classes.smallCol}>
              <div className={classes.sno}>1</div>
            </div>
            <div className={classes.bigCol}>
              <div className={classes.stepMsg}>
                {formatMessage(messages.step1Msg)}
              </div>
              {bookingType === RequestType.Video ? (
                <>
                  <>
                    <Button
                      variant="contained"
                      color={videoFor ? "default" : "secondary"}
                      onClick={() => setVideoFor(0)}
                      className={classes.selfButton}
                    >
                      {formatMessage(messages.mySelf)}
                    </Button>
                    <Button
                      variant="contained"
                      color={videoFor ? "secondary" : "default"}
                      onClick={() => setVideoFor(1)}
                      className={classes.giftButton}
                    >
                      {formatMessage(messages.someoneElse)}
                    </Button>
                  </>
                  <div className={classes.toFrom}>
                    <div className={classes.wrapper}>
                      <label className={classes.label}>
                        {formatMessage(messages.to)}
                      </label>
                      <TextField
                        placeholder="Enter Name"
                        variant="filled"
                        color="secondary"
                        InputProps={{
                          classes: { root: classes.inputField },
                        }}
                        classes={{ root: classes.textField }}
                        name="to"
                        size="small"
                        margin="none"
                        inputRef={register({ required: true })}
                      />
                      {errors.to && (
                        <span className={classes.error}>
                          ⚠ <ErrorMessage error={errors.to} />
                        </span>
                      )}
                    </div>

                    <div className={classes.wrapper}>
                      {!!videoFor && (
                        <>
                          <label className={classes.label}>
                            {formatMessage(messages.from)}
                          </label>

                          <TextField
                            placeholder="Enter Name"
                            variant="filled"
                            color="secondary"
                            InputProps={{
                              classes: { root: classes.inputField },
                            }}
                            classes={{ root: classes.textField }}
                            name="from"
                            size="small"
                            margin="none"
                            inputRef={register({ required: true })}
                          />
                          {errors.from && (
                            <span className={classes.error}>
                              ⚠ <ErrorMessage error={errors.from} />
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.wrapper}>
                  <label className={classes.label}>
                    {formatMessage(messages.callDuration)}
                  </label>
                  <TextField
                    placeholder="Enter Video Duration (in minutes)"
                    variant="filled"
                    color="secondary"
                    InputProps={{
                      classes: { root: classes.inputField },
                    }}
                    classes={{ root: classes.textField }}
                    name="callDuration"
                    size="small"
                    type="number"
                    inputRef={register({
                      required: true,
                      max: 60,
                    })}
                    style={{ width: "50%" }}
                  />
                  {errors.callDuration && (
                    <span className={classes.error}>
                      ⚠ <ErrorMessage error={errors.callDuration} />
                    </span>
                  )}
                </div>
              )}
              <div className={classes.instrn}>
                <div className={classes.wrapper}>
                  <label className={classes.label}>
                    {formatMessage(messages.drop)}
                  </label>
                  <TextField
                    multiline
                    rows={6}
                    placeholder="Type your message here"
                    variant="filled"
                    color="secondary"
                    InputProps={{
                      classes: { root: classes.inputField },
                    }}
                    classes={{ root: classes.textField }}
                    name="message"
                    size="small"
                    inputRef={register({ required: true, maxLength: 300 })}
                  />
                  {errors.message && (
                    <span className={classes.error}>
                      ⚠ <ErrorMessage error={errors.message} />
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.instrn}>
                <div className={classes.wrapper}>
                  <label className={classes.label}>
                    {formatMessage(messages.byWhen)}
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label=""
                      style={{
                        backgroundColor: "#f3f3f3",
                        paddingTop: 16,
                        borderRadius: 10,
                        width: 300,
                      }}
                      InputProps={{
                        style: { paddingLeft: 16 },
                      }}
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />{" "}
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={classes.hrLine}></div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.smallCol}>
              <div className={classes.sno}>2</div>
            </div>
            <div className={classes.bigCol}>
              <div className={classes.stepMsg}>
                {formatMessage(messages.step2Msg)}
                <div className={classes.stepSubMsg}>
                  {formatMessage(messages.step2SubMsg)}
                </div>
              </div>
              <div className={classes.toFrom}>
                <div className={classes.wrapper}>
                  <label className={classes.label}>
                    {formatMessage(messages.email)}
                  </label>
                  <TextField
                    placeholder="Enter Email"
                    variant="filled"
                    color="secondary"
                    InputProps={{
                      classes: { root: classes.inputField },
                    }}
                    classes={{ root: classes.textField }}
                    name="email"
                    size="small"
                    inputRef={register({
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  {errors.email && (
                    <span className={classes.error}>
                      ⚠ <ErrorMessage error={errors.email} />
                    </span>
                  )}
                </div>
                <div className={classes.wrapper}>
                  <label className={classes.label}>
                    {formatMessage(messages.mobile)}
                  </label>
                  <TextField
                    placeholder="Enter Mobile Number"
                    variant="filled"
                    color="secondary"
                    InputProps={{
                      classes: { root: classes.inputField },
                    }}
                    style={{padding: '14px 12px'}}
                    classes={{ root: classes.textField }}
                    name="contact"
                    size="small"
                    type="number"
                    inputRef={register({
                      required: true,
                      minLength: 10,
                    })}
                  />
                  {errors.contact && (
                    <span className={classes.error}>
                      ⚠ <ErrorMessage error={errors.contact} />
                    </span>
                  )}
                </div>
              </div>
              <Button
                className={classes.coupon}
                classes={{ root: classes.couponButton }}
                fullWidth
                onClick={onApplyCoupon}
              >
                <img src={tag} alt="Offer Tag" className={classes.tagImage} />
                <div className={classes.apply}>
                  {formatMessage(messages.applyCoupon)}
                  <span className={classes.ifYouHave}>
                    {formatMessage(messages.ifYouHave)}
                  </span>
                </div>
                <img
                  src={leftIcon}
                  alt="Left Icon"
                  className={classes.leftIcon}
                />
              </Button>
              <div className={classes.hrLine}></div>
            </div>
          </div>
          {/* remove negative margin and comment for payment options */}
          <div className={classes.table} style={{ marginTop: -56 }}>
            {/* <div className={classes.smallCol}>
              <div className={classes.sno}>3</div>
            </div> */}
            <div className={classes.bigCol}>
              {/* <div className={classes.stepMsg}>
                {formatMessage(messages.step3Msg)}
                <div className={classes.stepSubMsg}>
                  {formatMessage(messages.step3SubMsg)}
                </div>
              </div> */}
              <>
                <Controller
                  as={
                    <RadioGroup
                      aria-label="payment type"
                      className={classes.payment}
                    >
                      {paymentMethods?.map((el) => (
                        <FormControlLabel
                          value={el.methodName}
                          control={<Radio />}
                          label={
                            <img
                              src={el.img}
                              alt={el.methodName}
                              className={classes.methodImg}
                            />
                          }
                        />
                      ))}
                    </RadioGroup>
                  }
                  name="paymentMethod"
                  control={control}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.paymentMethod && (
                  <span className={classes.error}>
                    ⚠ <ErrorMessage error={errors.paymentMethod} />
                  </span>
                )}
              </>
              <Button
                classes={{ root: classes.bookButton }}
                variant="contained"
                fullWidth
                color="secondary"
                onClick={handleSubmit((d) =>
                  onBook({
                    ...d,
                    call_duration: d.callDuration,
                    for: videoFor ? "SomeoneElse" : "MySelf",
                    by_when: convertDate(selectedDate),
                    coin: parseInt(price),
                  })
                )}
                size="large"
              >
                <>
                  <div className={classes.wrap}>
                    {formatMessage(messages.bookVideoMsg)}
                    <img src={coin} alt="Coin" className={classes.coinImg} />
                    <div className={classes.price}>{parseInt(price)}</div>
                  </div>
                  <img src={arrow} alt="Arrow" className={classes.arrowImg} />
                </>
              </Button>
              <div className={classes.deliveryContainer}>
                <div className={classes.deliveryHeader}>
                  {formatMessage(messages.delivery)}
                </div>
                <div className={classnames(classes.table, classes.row)}>
                  <div className={classes.stepSmallCol}>
                    <div className={classes.stepSno}>1</div>
                  </div>
                  <div className={classes.stepBigCol}>
                    {formatMessage(messages.step1)}
                  </div>
                </div>
                <div className={classnames(classes.table, classes.row)}>
                  <div className={classes.stepSmallCol}>
                    <div className={classes.stepSno}>2</div>
                  </div>
                  <div className={classes.stepBigCol}>
                    {formatMessage(messages.step2)}
                  </div>
                </div>
                <div className={classnames(classes.table, classes.row)}>
                  <div className={classes.stepSmallCol}>
                    <div className={classes.stepSno}>3</div>
                  </div>
                  <div className={classes.stepBigCol}>
                    {formatMessage(messages.step3)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export function ErrorMessage({ error }) {
  if (error) {
    switch (error.type) {
      case "required":
        return <>This is required</>;
      case "minLength":
        return <>The mobile number should have 10 digits</>;
      case "maxLength":
        return <>It should have less than 300 words</>;
      case "pattern":
        return <>Enter a valid email address</>;
      case "max":
        return <>The value should be less than 60 minutes</>;
      case "validate":
        return <></>;
      default:
        return null;
    }
  }

  return null;
}
export default withStyles(styles)(withIntl(BookVideoForm));
