/**
 *
 * App constants
 *
 */

const scene = "app/App";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const LOAD = `${scene}/LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_USER_DETAILS = `${scene}/SET_USER_DETAILS`;
export const CONFIGURE_USER_DETAILS = `${scene}/CONFIGURE_USER_DETAILS`;
export const SET_USER_COINS = `${scene}/SET_USER_COINS`;
export const SET_NOTIFICATIONS = `${scene}/SET_NOTIFICATIONS`;
export const GET_NOTIFICATIONS = `${scene}/GET_NOTIFICATIONS`;
export const LOGOUT = `${scene}/LOGOUT`;
export const EDIT_PROFILE = `${scene}/EDIT_PROFILE`;
