/**
 * ReedemMadCoins Messages
 *
 * This contains all the text for the ReedemMadCoins Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.ReedemMadCoins";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage:
      "Withdraw your Mad Rewards as Amazon Pay gift card balance into your Amazon Pay wallet.",
  },
  uHave: {
    id: `${scope}.uHave`,
    defaultMessage: "You have - ",
  },
});
