/**
 *
 * MessageBox Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import checkcircle from "../../images/checkcircle.png";
type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  type: string;

  header: string;

  message: string;
};

function MessageBox(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    type,
    header,
    message,
  } = props;

  return (
    <div className={classnames(classes.container, className)} style={style}>
      {type === "success" && (
        <div className={classes.circle}>
          <img src={checkcircle} alt="Icon" className={classes.circleIcon} />
        </div>
      )}
      <div className={classes.header}>{header}</div>
      <div className={classes.message}>{message}</div>
    </div>
  );
}

export default withStyles(styles)(withIntl(MessageBox));
