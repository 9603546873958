import produce from "immer";
import * as ACTION_TYPES from "./constants";

export type UserProfile = {
  redeemLoading: boolean;
  redeemStatus: { status: boolean; message: string };
  sendingMailSuccess: boolean | null;
};

export const initialState: UserProfile = {
  redeemLoading: false,
  sendingMailSuccess: null,
};

const userProfileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.ON_REDEEM_GIFT_CARD: {
        draft.redeemLoading = true;
        break;
      }
      case ACTION_TYPES.SET_REDEEM_LOADING: {
        draft.redeemLoading = action.payload;
        break;
      }
      case ACTION_TYPES.SET_REDEEM_STATUS: {
        draft.redeemStatus = action.payload;
        break;
      }
      case ACTION_TYPES.SET_SENDING_MAIL_SUCCESS: {
        draft.sendingMailSuccess = action.payload;
        break;
      }
    }
  });

export default userProfileReducer;
