import { initial } from "lodash";

const styles = (theme: any) => ({
  wrapper: {
    // maxWidth: 500,
    height: "auto",
    borderRadius: 30,
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    paddingTop: 24,
  },
  close: {
    justifyContent: "flex-end",
    display: "flex",
    padding: 20,
  },
  image: {
    maxWidth: 140,
    objectFit: "contain",
    borderRadius: 18,
    // width:"30%",
    minHeight: "100%",
  },
  headerwrapper: {
    display: "flex",
    margin: "auto",
  },
  firsttext: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Poppins",
    lineHeight: 1.51,
    color: "#000000",
    marginTop: 30,
    maxWidth: 174,
    margin: "auto",
  },
  second: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "Poppins",
    lineHeight: 1.56,
    color: "#000000",
    marginBottom: 20,
    maxWidth: 215,
    margin: "auto",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  buttontype: {
    borderRadius: 10,
    marginBottom: 30,
  },
  header: {
    width: "60%",
  },
  labelwrapper: {
    marginTop: 30,
    marginBottom: 30,
  },
  innerwrapper: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  "@media (max-width: 967px)": {
    headerwrapper: {
      display: "initial",
    },
    image: {
      maxWidth: "revert",
      minHeight: "revert",
      width: "100%",
    },
    firsttext: {
      maxWidth: "revert",
      textAlign: "left",
      fontSize: 18,
    },
    second: {
      fontSize: 14,
      maxWidth: "revert",
      textAlign: "left",
    },
    buttontype: {
      fontSize: 14,
    },
  },
  "@media (max-width: 600px)": {
    headerwrapper: {
      display: "initial",
    },
    image: {
      maxWidth: "revert",
      minHeight: "revert",
      width: "100%",
    },
    firsttext: {
      maxWidth: "revert",
      textAlign: "left",
      fontSize: 18,
    },
    second: {
      fontSize: 14,
      maxWidth: "revert",
      textAlign: "left",
    },
    buttontype: {
      fontSize: 11,
    },
  },
  "@media (max-width: 400px)": {},
});

export default styles;
