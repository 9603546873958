/**
 *
 * ReedemMadCoins Component
 *
 */
import React from "react";
import Grid from '@material-ui/core/Grid';
import CardActions from "@material-ui/core/CardActions";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import amazon from "../../images/amazon.png";
import coin from "../../images/coin.png";
import arrow from "../../images/arrow.png";


type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    madCoins: string;

    amazonAmount: string;

    onReedem: () => void;

    rewardPoints:any;
};

function ReedemMadCoins(props: Props) {
    const {
        classes,
        style,
        className,
        formatMessage,
        madCoins,
        amazonAmount,
        rewardPoints,
        onReedem,
    } = props;

    const onClick = () => {

    }

    return (
        <div className={classnames(classes.container, className)} style={style}>

            <Grid item xs={12} style={{ textAlign: "center" }}>
                <CardActions classes={{ root: classes.cardActionRoot }}>
                    Reward Points<Button
                        className={classes.priceButton}
                        onClick={onClick}
                        size="small"
                    >
                        <img src={coin} alt="Coin" className={classes.coinImg} />
                        <div className={classes.noOfCoin}>{rewardPoints}</div>
                    </Button>
                </CardActions>

            </Grid>



            <div className={classes.title}>
                <img src={amazon} alt="Amazon pay" className={classes.amazonPay} />
                <div className={classes.head}>{formatMessage(messages.header)}</div>
            </div>
            <div className={classes.hrLine}></div>
            <div className={classes.content}>
                <div className={classes.uHave}>{formatMessage(messages.uHave)}</div>
                <div className={classes.row}>
                    <div className={classes.wrapper}>
                        <img src={coin} alt="Coin" className={classes.img} />
                        <div className={classes.wrap}>
                            <div className={classes.amount}>{madCoins}</div>
                            <div className={classes.text}>MadRewards</div>
                        </div>
                        <div className={classes.equal}>=</div>
                        <img src={amazon} alt="Amazon" className={classes.img} />
                        <div className={classes.wrap}>
                            <div className={classes.amount}>{amazonAmount}</div>
                            <div className={classes.text}>AmazonPay</div>
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        onClick={onReedem}
                        color="secondary"
                        size="large"
                        className={classes.btn}
                    >
                        REDEEM NOW
                        <img src={arrow} alt="Arrow" className={classes.arrowImg} />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(withIntl(ReedemMadCoins));
