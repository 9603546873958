/**
 *
 * SearchCeleb actions
 *
 */

import * as ACTION_TYPES from "./constants";
import { Influencer } from "./reducer";

export function load() {
  return {
    type: ACTION_TYPES.ON_LOAD,
  };
}
export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}

export function setCategories(categories: string[]) {
  return {
    type: ACTION_TYPES.SET_CATEGORIES,
    payload: categories,
  };
}
export function setLanguages(languages: { id: number; language: string }) {
  return {
    type: ACTION_TYPES.SET_LANGUAGES,
    payload: languages,
  };
}
export function setInfluencers(influencers: Influencer[]) {
  return {
    type: ACTION_TYPES.SET_INFLUENCERS,
    payload: influencers,
  };
}
