import { UserDetails } from "./reducer";
/**
 *
 * App
 *
 */
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";

import Layout from "./layout";
import reducer from "./reducer";

import { load, configureUserDetails, getNotifications } from "./actions";
import { selectLoading, selectToken } from "./selectors";

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  token: selectToken,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      load,
      configureUserDetails,
      getNotifications,
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);
