/**
 *
 * RechargeWallet Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import RechargeSection from "../../components/RechargeSection";
import { RAZORPAY_KEY } from '../../shared/contant';
import axios from "../../shared/axios-details";
import { useSelector } from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import Congratulations from '../../components/Congratulations';
import RechargePopUp from '../../components/RechargePopUp'
import TransecionTable from "../../components/TransecionTable";
type Props = {
	/** internal */
	classes: any;

	/** internal */
	formatMessage: Function;

	/** class applied to top level container */
	className?: any;

	/** style applied to top level container */
	style?: Object;

	userDetails: any;

	setUserCoins?: any
};

const loadScript = () => {
	return new Promise(resolve => {
		const script = document.createElement('script');
		script.src = "https://checkout.razorpay.com/v1/checkout.js";

		script.onload = () => {
			resolve(true);
		}
		script.onerror = () => {
			resolve(false);
		}

		document.body.appendChild(script);
	})
}


function RechargeWallet(props: Props) {

	const { classes, style, className, formatMessage, userDetails, setUserCoins } = props;

	const [orderData, setOrderData] = useState([]);
	const [coinAmount, setCoinsAmount] = useState([]);
	const [selectedCoinAmount, setSelectedCoinAmount] = useState({});
	const [congrats, setCongrats] = useState(false);
	const [finalCoin, setFinalCoin] = useState(0);

	const [manualPopup, setManualPopup] = useState(false);
	const [transaction, setTransaction] = useState([])


	const token = useSelector((state: any) => state.auth.token);

	//console.log(props.userDetails)

	useEffect(() => {
		fetchAmount();
		fetchTransaction();
	}, [])

	const fetchAmount = () => {
		const data = {
			token: token,
			info_type: 3
		}
		//console.log(data);
		axios
			.post("/razorpay_integration.php", data)
			.then((res) => {
				setCoinsAmount(res.data.data);
			})
			.catch((err) => console.log(err));
	}

	const fetchOrderId = (coinDetails: any) => {
		//console.log(token);\
		setSelectedCoinAmount(coinDetails);
		const data = {
			token: token,
			info_type: 1,
			amount: parseInt(coinDetails.price),
			coin: parseInt(coinDetails.mad_coin)
		}
		console.log(data);
		axios
			.post("/razorpay_integration.php", data)
			.then((res) => {
				//console.log(res.data);
				if (res.data.status) {
					console.log(res.data.data);
					setOrderData(res.data.data);
					displayRazorPay(res.data.data);
				} else {
					//console.log(res.data);
				}
			})
			.catch((err) => console.log(err));
	}

	const fetchTransaction = () => {
		const data = {
			token: token,
			info_type: 2,
			trans_type:"mad_coin"
		}
		//console.log(data);
		axios
			.post("/user.php", data)
			.then((res) => {
				console.log(res)
				setTransaction(res.data.data);
			})
			.catch((err) => console.log(err));
	}

	const completeTransaction = (response: any, orderData: any) => {

		console.log(response, orderData)
		const data = {
			token: token,
			info_type: 2,
			payment_id: response.razorpay_order_id,
			razorpay_data: JSON.stringify(response)
		}
		console.log(data);
		axios
			.post("/razorpay_integration.php", data)
			.then((res) => {
				console.log(res.data);
				if (res.data.status) {
					console.log(res.data.data);

				} else {
					console.log(res.data);
				}
				setFinalCoin(res.data.data.wallet);
				setUserCoins(res.data.data.wallet);
				setCongrats(true);
			})
			.catch((err) => console.log(err));
	}
	const displayRazorPay = async (data: any) => {

		const res = await loadScript();

		if (!res) {
			alert("error");
			return;
		}
		var options = {
			"key": RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
			"amount": data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			"currency": data.currency,
			"name": userDetails.name,
			"description": "Recharge",
			"image": "https://madfan.codebuckets.in/static/media/logo.8d9ca1ca.png",
			"order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
			"handler": function (response: any) {
				// console.log(response)
				//   alert(response.razorpay_payment_id);
				//   alert(response.razorpay_order_id);
				//   alert(response.razorpay_signature)
				completeTransaction(response, orderData)
			},
			"prefill": {
				"name": userDetails.name,
				"email": userDetails.email,
				"contact": userDetails.mobile_number
			},
		};

		const _window = window as any;
		const paymentObject = new _window.Razorpay(options);
		paymentObject.open();
	}

	const handleManual = (price) => {
		if (price) {
			fetchOrderId({
				price: price,
				mad_coin: price
			})
		}
		setManualPopup(false)
	}

	console.log(selectedCoinAmount);

	return (
		<div className={className} style={style}>
			<RechargeSection
				coinAmount={coinAmount}
				handleRecharge={fetchOrderId}
				rechargeManual={() => setManualPopup(true)}
			/>
			<br />
			<br />
			{
				transaction && transaction.length > 0
				?
				<TransecionTable 
					transaction={transaction}
					title={"Mad Coin Transactions"}
				/>
				: null
			}
			

			<Dialog
				open={congrats}
				onClose={() => {
					setCongrats(false);
				}}
			>
				<Congratulations type="coin" coin={finalCoin} onClose={() => setCongrats(false)} />
			</Dialog>

			<Dialog
				open={manualPopup}
				onClose={() => {
					setManualPopup(false);
				}}
			>
				<RechargePopUp handleManual={handleManual} />
			</Dialog>
		</div>
	);
}

export default withStyles(styles)(withIntl(RechargeWallet));
