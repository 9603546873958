/**
 *
 * RedeemInfoCard Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "../BookVideoForm";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import classnames from "classnames";
type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  email: string;

  mobile: number | string;

  addressLine1: string;

  addressLine2: string;

  city: string;

  region: string;

  pincode: string;

  loading: boolean;

  onSave: Function;
};

function RedeemInfoCard(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    email,
    mobile,
    addressLine1,
    addressLine2,
    city,
    region,
    pincode,
    loading,
    onSave,
  } = props;

  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      email: email,
      mobile: mobile,
      addressLine1: addressLine1 || "",
      addressLine2: addressLine2 || "",
      city: city || "",
      region: region || "",
      pincode: pincode || "",
      price: 10,
    },
  });

  const handleData = (data) => {
    onSave(data);
  };

  return (
    <div className={classnames(className, classes.container)} style={style}>
      <div className={classes.header}>{formatMessage(messages.header)}</div>
      <div className={classes.box}>
        <div className={classes.wrapper}>
            <TextField
              placeholder="Amount"
              variant="filled"
              color="secondary"
              InputProps={{
                classes: { root: classes.inputField },
              }}
              classes={{ root: classes.textField }}
              name="price"
              margin="none"
              type="number"
              size="small"
              inputRef={register({ minLength: 2, required: true })}
            />
            {errors.mobile && (
              <span className={classes.error}>
                 <ErrorMessage error={errors.mobile} />
              </span>
            )}
          </div>
          <div className={classes.wrapper}>
          <TextField
            placeholder="Email"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="email"
            margin="none"
            size="small"
            inputRef={register({
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
          {errors.email && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.email} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Mobile"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="mobile"
            margin="none"
            type="number"
            size="small"
            inputRef={register({ minLength: 10, required: true })}
          />
          {errors.mobile && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.mobile} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Address Line 1"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="addressLine1"
            margin="none"
            // type="number"
            size="small"
            inputRef={register({ required: true })}
          />
          {errors.addressLine1 && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.addressLine1} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Address Line 2"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="addressLine2"
            margin="none"
            // type="number"
            size="small"
            inputRef={register({})}
          />
          {errors.addressLine2 && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.addressLine2} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="City"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="city"
            margin="none"
            // type="number"
            size="small"
            inputRef={register({})}
          />
          {errors.city && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.city} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="State"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="region"
            margin="none"
            // type="number"
            size="small"
            inputRef={register({ required: true })}
          />
          {errors.region && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.region} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Pincode"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="pincode"
            margin="none"
            type="number"
            size="small"
            inputRef={register({ minLength: 6, required: true })}
          />
          {errors.pincode && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.pincode} />
            </span>
          )}
        </div>
      </div>
      <Button
        color="secondary"
        variant="contained"
        style={{ width: "100%", marginTop: 40 }}
        size="large"
        onClick={loading ? () => { } : handleSubmit((d) => handleData(d))}
      >
        {loading ? (
          <CircularProgress color="default" style={{ width: 40 }} />
        ) : (
          "SAVE"
        )}
      </Button>
      <p>
        Please refer <a style={{ color: "#5486fe" }} href="https://amazon.in/giftcardtnc" target="_blank">terms and conditions</a> for more information.
      </p>
    </div>
  );
}

export default withStyles(styles)(withIntl(RedeemInfoCard));
