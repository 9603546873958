/**
 * Quiz Messages
 *
 * This contains all the text for the Quiz Scene
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.scene.Quiz";

export default defineMessages({
  winCoins: {
    id: `${scope}.winCoins`,
    defaultMessage: "Win Mad Coins",
  },
  playQuiz: {
    id: `${scope}.playQuiz`,
    defaultMessage: "Play Quiz",
  },
});
