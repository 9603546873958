/**
 * BookVideoForm Messages
 *
 * This contains all the text for the BookVideoForm Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.BookVideoForm";

export default defineMessages({
  bookVideo: {
    id: `${scope}.bookVideo`,
    defaultMessage: "Book a recorded video clip",
  },
  awesome: {
    id: `${scope}.awesome`,
    defaultMessage:
      "Awesome, We are excited to make your moment special, please fill the form and we’ll roll.",
  },
  step1Msg: {
    id: `${scope}.step1Msg`,
    defaultMessage: "This video message is for: ",
  },
  mySelf: {
    id: `${scope}.mySelf`,
    defaultMessage: "My Self",
  },
  someoneElse: {
    id: `${scope}.someoneElse`,
    defaultMessage: "Someone Else",
  },
  callDuration: {
    id: `${scope}.callDuration`,
    defaultMessage: "Call Duration",
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: "To",
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: "From",
  },
  drop: {
    id: `${scope}.drop`,
    defaultMessage: "Drop Your Message or Video Instruction",
  },
  byWhen: {
    id: `${scope}.byWhen`,
    defaultMessage: "By when do you need this video?",
  },
  step2Msg: {
    id: `${scope}.step2Msg`,
    defaultMessage: "Your Contact Details:",
  },
  step2SubMsg: {
    id: `${scope}.step2SubMsg`,
    defaultMessage: "We need these details to share receipt and order updates.",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Contact Email",
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: "Contact Mobile Number",
  },
  applyCoupon: {
    id: `${scope}.applyCoupon`,
    defaultMessage: "Apply Coupon",
  },
  ifYouHave: {
    id: `${scope}.ifYouHave`,
    defaultMessage: "(if you have)",
  },
  step3Msg: {
    id: `${scope}.step3Msg`,
    defaultMessage: "Payment Options:",
  },
  // We support almost all type of digital payment platforms.
  step3SubMsg: {
    id: `${scope}.step3SubMsg`,
    defaultMessage:
      "We'll provide almost all type of digital payment platforms.",
  },
  bookVideoMsg: {
    id: `${scope}.bookVideoMsg`,
    defaultMessage: "BOOK VIDEO MESSAGE",
  },
  delivery: {
    id: `${scope}.delivery`,
    defaultMessage: "How you'll get delivery:-",
  },
  step1: {
    id: `${scope}.step1`,
    defaultMessage:
      "Your order updates and your booking receipt would be shared on the email id provided by you",
  },
  step2: {
    id: `${scope}.step2`,
    defaultMessage:
      "When your request is completed, we shall share the link on your phone number and in your email inbox",
  },
  step3: {
    id: `${scope}.step3`,
    defaultMessage:
      "If is not able to complete your request, we shall refund the entire money immediately",
  },
});
