/**
 *
 * Quiz actions
 *
 */

import * as ACTION_TYPES from "./constants";
import { Question } from "./reducer";

export function defaultAction() {
  return {
    type: ACTION_TYPES.DEFAULT_ACTION,
  };
}
export function load() {
  return {
    type: ACTION_TYPES.LOAD,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}
export function setInfluencers(value: any) {
  return {
    type: ACTION_TYPES.SET_INFLUENCERS,
    payload: value,
  };
}
export function onLoadQuiz(id: string) {
  return {
    type: ACTION_TYPES.ON_LOAD_QUIZ,
    payload: id,
  };
}
export function setQuizQuestions(ques: Question[], coin: any) {
  console.log(ques, coin);
  return {
    type: ACTION_TYPES.SET_QUIZ_QUESTIONS,
    payload: ques,
    coin:coin
  };
}
export function setQuizLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_QUIZ_LOADING,
    payload: value,
  };
}
export function onQuizAnswered(
  question_id: number,
  coin: number,
  answer: string,
  is_last: boolean,
  all_correct: boolean
) {
  return {
    type: ACTION_TYPES.ON_QUIZ_ANSWERED,
    payload: { question_id, coin, answer, is_last, all_correct },
  };
}
export function acceptQuiz(inf: any) {
  return {
    type: ACTION_TYPES.ACCEPT_QUIZ,
    payload: inf,
  };
}
export function startQuiz(id: any) {
  return {
    type: ACTION_TYPES.START_QUIZ,
    payload: id,
  };
}
