/**
 *
 * UserProfile Component
 *
 */
import React, { useEffect, lazy, Suspense, useState } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Switch as Toggle } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Switch, Route, useHistory } from "react-router-dom";
import classnames from "classnames";
import UserProfileCard from "../../components/UserProfile";
import ReferAndEarn from "../../components/ReferAndEarn";
import { useWindowWidth } from "@react-hook/window-size";
import { BREAKING_POINT, GIFT_CARD_AMOUNT } from "./constants";
import {
    MEDIA_DEVICE_DESKTOP,
    MEDIA_DEVICE_MOBILE,
} from "../../utils/constants";
import EditProfile from "../../components/EditProfile";
import ReedemMadCoins from "../../components/ReedemMadCoins";
import ReedemMadCoinsCards from "../../components/ReedemMadCoins-Cards";
import Dialog from "@material-ui/core/Dialog";
// import sample from "../../images/sample.png";
import { UserDetails } from "../App/reducer";
import defaultProfile from "../../images/defaultProfile.png";
import WinCoins from "../WinCoins";
import MyBookings from "../MyBookings";
import RechargeWallet from "../RechargeWallet";
import RedeemInfoCard from "../../components/RedeemInfoCard";
import MessageBox from "../../components/MessageBox";
import GoogleContacts from "../../components/GoogleContacts";
import { parseInt } from "lodash";
import axios from "../../shared/axios-details";
import { useSelector } from 'react-redux';

type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    userDetails: UserDetails;

    isLoggedIn: boolean;

    editProfile: Function;

    redeemLoading: boolean;

    redeemStatus: any;

    logout: () => void;

    sendingMailSuccess: boolean;

    sendMails: Function;

    setSendingMailSuccess: (status: boolean | null) => void;

    onRedeemGiftCard: Function;
};

function UserProfile(props: Props) {
    const {
        classes,
        style,
        className,
        formatMessage,
        isLoggedIn,
        userDetails,
        editProfile,
        redeemLoading,
        redeemStatus,
        logout,
        setSendingMailSuccess,
        sendMails,
        sendingMailSuccess,
        onRedeemGiftCard,
    } = props;
    const history = useHistory();

    // re-routes to search page if not logged in and try to access /profile/*
    if (
        !isLoggedIn &&
        history?.location?.pathname?.split("/")?.includes("profile")
    ) {
        history.push("/");
    }

    const mediaDevice =
        useWindowWidth() > BREAKING_POINT
            ? MEDIA_DEVICE_DESKTOP
            : MEDIA_DEVICE_MOBILE;

    const [showEditProfile, setShowEditProfile] = useState(false);
    const [showGoogleContacts, setShowGoogleContacts] = useState(false);
    const [googleContacts, setGoogleContacts] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (sendingMailSuccess !== null) {
            setShowSnackbar(true);
        }
    }, [sendingMailSuccess]);

    return (
        <div className={classnames(className, classes.container)} style={style}>
            {mediaDevice === MEDIA_DEVICE_DESKTOP && (
                <UserProfileCard
                    usedAsDrawer={false}
                    className={classes.userProfileCard}
                    dp={userDetails?.profile_photo || defaultProfile}
                    userName={userDetails?.name || ""}
                    level={parseInt(userDetails?.percentage)}
                    route={history.location.pathname.split("/")[2]}
                    onEditProfile={() => setShowEditProfile(true)}
                    onClose={() => { }}
                    onChangeRoute={(route) => history.push(`/profile/${route}`)}
                />
            )}
            <div className={classes.wrapper}>
                <div className={classes.referAndEarn}>
                    <ReferAndEarn
                        link={userDetails.referral_link || ""}
                        onImportGoogleContacts={(e) => {
                            if (e) {
                                setGoogleContacts(
                                    e.map((el) => ({
                                        name: el.title,
                                        email: el.email,
                                        checked: false,
                                    }))
                                );
                                setShowGoogleContacts(true);
                            }
                        }}
                    />
                </div>
                <div className={classes.content}>
                    <Switch>
                        <Suspense fallback={() => { }}>
                            <Route
                                path="/profile/my-bookings"
                                render={(componentProps) => <MyBookings {...componentProps} />}
                            />
                            <Route
                                path="/profile/win-coins"
                                render={(componentProps) => <WinCoins {...componentProps} />}
                            />

                            <Route
                                path="/profile/recharge-wallet"
                                render={(componentProps) => (
                                    <RechargeWallet
                                        userDetails={userDetails}
                                        {...componentProps}
                                    />
                                )}
                            />

                            <Route
                                path="/profile/settings"
                                render={(componentProps) => (
                                    <Settings {...componentProps} classes={classes} />
                                )}
                            />
                            <Route
                                path="/profile/logout"
                                render={(componentProps) => (
                                    <Logout {...componentProps} logout={logout} />
                                )}
                            />
                            <Route
                                path="/profile/redeem-madrewards"
                                render={(componentProps) => (
                                    <ReedemCoins
                                        {...componentProps}
                                        classes={classes}
                                        userDetails={userDetails}
                                        onRedeemGiftCard={onRedeemGiftCard}
                                        redeemLoading={redeemLoading}
                                        redeemStatus={redeemStatus}
                                    />
                                )}
                            />
                        </Suspense>
                    </Switch>
                </div>
            </div>
            <Dialog open={showEditProfile} onClose={() => setShowEditProfile(false)}>
                <EditProfile
                    name={userDetails?.name || ""}
                    contact={userDetails?.mobile_number || ""}
                    profilePic={userDetails?.profile_photo || defaultProfile}
                    email={userDetails?.email}
                    fbLink={userDetails?.fb_link}
                    instaLink={userDetails?.insta_link}
                    twitterLink={userDetails?.twitter_link}
                    birthDate={userDetails?.dob}
                    onSave={(data) => {
                        editProfile(data);
                        setShowEditProfile(false);
                    }}
                />
            </Dialog>
            <Dialog
                open={showGoogleContacts}
                onClose={() => {
                    setShowGoogleContacts(false);
                    setGoogleContacts([]);
                }}
            >
                <GoogleContacts
                    contacts={googleContacts}
                    onChange={(e) => {
                        sendMails(e.map((el) => ({ name: el.name, email: el.email })));
                        setShowGoogleContacts(false);
                        setGoogleContacts([]);
                    }}
                />
            </Dialog>
            {sendingMailSuccess !== null && (
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={() => {
                        setShowSnackbar(false);
                        setSendingMailSuccess(null);
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    {!sendingMailSuccess ? (
                        <Alert
                            onClose={() => {
                                setSendingMailSuccess(null);
                                setShowSnackbar(false);
                            }}
                            severity="error"
                            elevation={6}
                            variant="filled"
                        >
                            Oops!!!. Something went wrong while sending the mails.Please try
                            later.
                        </Alert>
                    ) : (
                        <Alert
                            onClose={() => {
                                setSendingMailSuccess(null);
                                setShowSnackbar(false);
                            }}
                            severity="success"
                            elevation={6}
                            variant="filled"
                        >
                            Successfull!!!. The mail has been sent.
                        </Alert>
                    )}
                </Snackbar>
            )}
        </div>
    );
}
function ReedemCoins(props) {
    const {
        classes,
        userDetails,
        redeemLoading,
        redeemStatus,
        onRedeemGiftCard,
    } = props;

    const [showRedeemInfo, setShowRedeemInfo] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [coinSnak, setCoinSnak] = useState(false);
    const [allCard, setAllCard] = useState([]);
    const [reward, setReward] = useState(0);

    useEffect(() => {
        if (redeemStatus?.message) {
            setShowRedeemInfo(false);
            setShowMessageBox(true);
        }
    }, [redeemStatus]);

    const handleRedeemButton = () => {
        console.log(userDetails);
        if (parseInt(userDetails.coin) >= GIFT_CARD_AMOUNT) {
            setShowRedeemInfo(true)
        }
        else {
            setShowRedeemInfo(false)
            setCoinSnak(true)
        }

    }

    const token = useSelector((state) => state.auth.token);
    useEffect(() => {
        fetchCard();
    }, [showRedeemInfo])

    useEffect(() => {
        fetchCard();
    }, [token])

    
    function createData(number: string, created: string, action: string) {
        return { number, created, action };
    }
    const fetchCard = () => {
        axios
            .post("/amazon_show_git_card.php", {
                info_type: "1",
                token: token,
            })
            .then((res) => {
                if (res.data.status) {
                    let _tmp = [];
                    console.log(res.data)
                    res.data.error.map((r: any) => {
                        // console.log(r)
                        _tmp.push(createData(r.gift_card, r.added_on, r.gift_card));
                    })
                    setAllCard(_tmp)
                    setReward(res.data.reward)
                }
            })
            .catch((err) => {
                throw err;
            });
    }

    const handleAddCard = (data) =>{
        if(reward && parseInt(reward) > data.price){
            onRedeemGiftCard({ ...data })
        }
        else{
            setCoinSnak(true)
        }
    }
    return (
        <>
            <div className={classes.settings}>
                <div className={classes.head}>Redeem MadRewards</div>
            </div>
            <ReedemMadCoins
                style={{ marginTop: 24 }}
                madCoins={GIFT_CARD_AMOUNT}
                amazonAmount={GIFT_CARD_AMOUNT}
                rewardPoints={reward}
                onReedem={() => handleRedeemButton()}
            />
            <ReedemMadCoinsCards
                redeemCard={showRedeemInfo}
                allCard={allCard}
            />

            <Dialog open={showRedeemInfo} onClose={() => setShowRedeemInfo(false)}>
                <RedeemInfoCard
                    email={userDetails?.email}
                    mobile={userDetails?.mobile}
                    city={userDetails?.city}
                    addressLine1={userDetails?.addressLine1}
                    addressLine2={userDetails?.addressLine2}
                    region={userDetails?.region}
                    pincode={userDetails?.pincode}
                    loading={redeemLoading}
                    onSave={(data) => {
                        handleAddCard(data);
                    }}
                />
            </Dialog>
            {/* <Dialog open={showRedeemInfo} onClose={() => setShowRedeemInfo(false)}>
        <RedeemInfoCard
          email={userDetails?.email}
          mobile={userDetails?.mobile}
          city={userDetails?.city}
          addressLine1={userDetails?.addressLine1}
          addressLine2={userDetails?.addressLine2}
          region={userDetails?.region}
          pincode={userDetails?.pincode}
          loading={redeemLoading}
          onSave={(data) => {
            onRedeemGiftCard({ ...data, price: 10 });
          }}
        />
      </Dialog> */}
            <Dialog open={showMessageBox} onClose={() => setShowMessageBox(false)}>
                <MessageBox
                    type={redeemStatus?.status ? "success" : "failure"}
                    header={redeemStatus?.status ? "Congratulations" : "Oops!!!"}
                    message={
                        redeemStatus?.status
                            ? "Your gift card is generated and send to your email id."
                            : redeemStatus?.message
                    }
                />
            </Dialog>
            {coinSnak ?
                <Snackbar
                    open={coinSnak}
                    autoHideDuration={6000}
                    onClose={() => {
                        setCoinSnak(false);
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={() => {
                            setCoinSnak(false);
                        }}
                        severity="error"
                        elevation={6}
                        variant="filled"
                    >
                        Insufficient funds. Please recharge and try again.
                    </Alert>
                </Snackbar>
                : null
            }
        </>
    );
}

function Settings(props) {
    const { classes } = props;
    const [checked, setChecked] = useState(true);

    // useEffect(() => {
    //   if ("Notification" in window) {
    //     checked && Notification.requestPermission();
    //     console.log("here");
    //   }
    // }, [checked]);

    // function showNotification() {
    //   var options = {
    //     body: "This is the body of the Notification",
    //     icon:
    //       "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    //     dir: "ltr",
    //   };
    //   var notification = new Notification("Notification Demo", options);
    // }

    return (
        <div className={classes.settings}>
            <div className={classes.head}>Settings</div>
            <div className={classes.box}>
                <div className={classes.wrap}>
                    <div className={classes.title}>Desktop Notifications</div>
                    <div className={classes.subtitle}>
                        You'll be notify your updates on desktop
                    </div>
                </div>
                <Toggle
                    checked={checked}
                    onChange={() => {
                        setChecked(!checked);
                    }}
                    name="notifToggle"
                    color="secondary"
                />
            </div>
        </div>
    );
}

function Logout(props) {
    props.logout();
    return <></>;
}

export default withStyles(styles)(withIntl(UserProfile));
