/**
 * WinMadCoins Messages
 *
 * This contains all the text for the WinMadCoins Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.WinMadCoins";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Play Quiz",
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: "Process",
  },
  step1: {
    id: `${scope}.step1`,
    defaultMessage: "Select your favourite celebrity.",
  },
  step2: {
    id: `${scope}.step2`,
    defaultMessage: "You'll appear some questions.",
  },
  step3: {
    id: `${scope}.step3`,
    defaultMessage: "Win coins per successful answer",
  },
  step4: {
    id: `${scope}.step4`,
    defaultMessage: "Win bonus coins after all successful answer.",
  },
});
