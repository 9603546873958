/**
 *
 * RechargeSection Component
 *
 */
import React, { useState } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { capitalizeAttribute } from "../../utils/string";



type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    transaction?: any;

    title:string;
};

function TableSection(props: Props) {
    const { classes, style, className, formatMessage, transaction } = props;


    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.title}>{props.title}</div>
                <div>
                    <table className={"transactionTable"}>
                        <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Credit</th>
                            <th>Debit</th>
                        </tr>
                        {
                            transaction && transaction.map((t:any, i:any) => (
                                <tr key={i}>
                                    <td>{capitalizeAttribute(t.for)}</td>
                                    <td>{t.added_on}</td>
                                    <td>{parseInt(t.coin_earned) > 0 ? t.coin_earned : ''}</td>
                                    <td>{parseInt(t.coin_earned) < 0 ? parseInt(t.coin_earned)*(-1) : ''}</td>
                                </tr>
                            ))
                        }


                    </table>


                </div>

            </div>
        </>
    );
}

export default withStyles(styles)(withIntl(TableSection));
