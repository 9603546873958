const styles = (theme: any) => ({
    wrapper: {
      textAlign: "center",
      fontSize: 16,
      fontFamily: "Poppins",
    },
    header: {
      fontSize: 41,
      fontWeight: 600,
    },
    details: {
        fontSize: 21,
        fontWeight: 500,
        marginBottom: 20,
      },
    buttonwrapper: {
      // opacity: 0.08,
      backgroundColor: "#e90041",
      width: 183,
      height: 60,
      borderRadius: 13,
      margin: "auto",
    },
    button: {
      fontSize: 24,
      fontWeight: 500,
      color: "white",
      paddingTop: 10,
    },
   
    "@media (max-width: 600px)": {
      header: {
        fontSize: 28,
        fontWeight: 500,
      },
      details: {
          fontSize: 15,
          fontWeight: 500,
        },
    },
    "@media (max-width: 400px)": {
      header: {
        fontSize: 20,
        fontWeight: 500,

      },
      details: {
        fontSize: 12,
        fontWeight: 500,
      },
      buttonwrapper:
      {
        width:100,
        height:35,

      },

      button:{
        fontSize:10
      }
    },
  });
  
  export default styles;
  