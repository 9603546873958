/**
 *
 * Celebrity actions
 *
 */

import * as ACTION_TYPES from "./constants";
import { InfluencerDetail } from "./reducer";
import { Influencer } from "../SearchCeleb/reducer";
export function defaultAction() {
  return {
    type: ACTION_TYPES.DEFAULT_ACTION,
  };
}

export function load(celebId: string) {
  return {
    type: ACTION_TYPES.LOAD,
    payload: celebId,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}

export function setInfluencerDetail(value: InfluencerDetail) {
  return {
    type: ACTION_TYPES.SET_INFLUENCER_DETAIL,
    payload: value,
  };
}
export function setRecommendedInfs(value: Influencer[]) {
  return {
    type: ACTION_TYPES.SET_RECOMMENDED_INFLUENCERS,
    payload: value,
  };
}
export function bookOrder(data: {}, celebId: string) {
  return {
    type: ACTION_TYPES.BOOK_ORDER,
    payload: { ...data, influencer_id: celebId },
  };
}
export function setOrderDetail(status: boolean | null, message: string) {
  return {
    type: ACTION_TYPES.SET_ORDER_DETAIL,
    payload: { status, message },
  };
}
export function applyCoupon(couponData: any) {
  return {
    type: ACTION_TYPES.APPLY_COUPON,
    payload: couponData,
  };
}
export function setCouponLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_COUPON_LOADING,
    payload: value,
  };
}
export function setCouponDiscount(value: string) {
  return {
    type: ACTION_TYPES.SET_COUPON_DISCOUNT,
    payload: value,
  };
}
export function setDiscountedAmount(amount: string, videoFor: string) {
  return {
    type: ACTION_TYPES.SET_DISCOUNTED_AMOUNT,
    payload: { amount, videoFor },
  };
}
