const styles = (theme: any) => ({
  container: {
    maxWidth: 236,
    borderRadius: 20,
    position: "relative" as "relative",
    // height: 350,
    fontFamily: "Poppins",
    paddingBottom: 16,
  },
  media: {
    height: 268,
    "object-fit": "cover",
    "object-position": "top",
  },
  priceButton: {
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    margin: 16,
  },
  cardActionRoot: {
    padding: 0,
  },
  cardContent: {
    width: "-webkit-fill-available",
    position: "absolute" as "absolute",
    bottom: 0,
    left: 0,
  },
  celebName: {
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "bold",
  },
  famousFor: {
    display: "inline-block",
    width: "calc(100%)",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  eventName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 18,
    fontWeight: 600,
    marginLeft: 16,
    marginBottom: 0,
    marginTop: 10,
  },
  datehead: {
    marginLeft: 16,
    fontSize: 10,
    fontWeight: "normal",
    display: "flex",
    // width: "min-content",
  },
  datevalue: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: "normal",
  },
});

export default styles;
