/**
 *
 * GoogleContacts Component
 *
 */
import React, { useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import { Typography, Container, Grid, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { grey } from "@material-ui/core/colors";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../Button/index";
import { cloneDeep } from "lodash";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
  contacts: {
    name: string;
    email: string;
    checked: boolean;
  }[];
  onChange?: (updatedList: contact[]) => void;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      maxWidth: 500,
      margin: `${theme.spacing(1)}px auto`,
      padding: 6,
      boxShadow: "none",
    },
  })
);

const GreenCheckbox = withStyles({
  root: {
    color: grey,
    "&$checked": {
      color: grey[900],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
function GoogleContacts(props: Props) {
  const {
    classes: {
      wrapper,
      listwrapper,
      name,
      email,
      close,
      button,
      buttontype,
      check,
      header,

      noofcontacts,

      container,
      centerwrapper,
    },
    style,
    contacts,
    onChange,
  } = props;
  const { paper } = useStyles(props);
  const [state, setState] = React.useState([]);
  const [contact, setContact] = React.useState(contacts);
  const [checkedall, setCheckedall] = React.useState(false);

  const handleChange = (event: any, i: any) => {
    let itemName = event.target.name;
    var updatedList = cloneDeep(contact);
    updatedList = updatedList.map((item: any, index) =>
      index == i ? { ...item, checked: event.target.checked } : item
    );

    let checkedall = updatedList.every((item) => item.checked);
    setCheckedall(checkedall);
    setContact(updatedList);
  };

  const HandleClick = () => {
    const filterchecked = contact.filter((item, i) => item.checked == true);
    onChange(filterchecked);
  };
  return (
    <div className={wrapper} style={style}>
      <Container>
        {/* <div className={close}>
          <CloseIcon />
        </div> */}
        <Typography align="center" variant="h5" className={header}>
          Google Contacts
        </Typography>
        <Typography align="center" variant="h5" className={noofcontacts}>
          ({contacts.length} Contacts)
        </Typography>
        <div className={listwrapper}>
          {/* <Paper className={paper}>
            <Typography align="right" variant="h5">
              <FormControlLabel
                label="Select All"
                labelPlacement="start"
                control={
                  <GreenCheckbox
                    checked={checkedall}
                    onChange={(e) => handleChangeAll(e)}
                    name="checkedall"
                  />
                }
              />
            </Typography> 
          </Paper>  */}
          {contact.map((item, i) => (
            <Paper className={paper}>
              <Grid container spacing={2} className={container}>
                {/* <Grid item>
                  <img src={salman} className={imagewrapper} />
                  
                </Grid> */}
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs className={centerwrapper}>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={name}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={email}
                      >
                        {item.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={item.checked}
                          onChange={(e) => {
                            handleChange(e, i);
                          }}
                          name={`${item.name}`}
                          className={check}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
        <div className={button}>
          <Button
            name="Invite"
            buttonType="secondary"
            className={buttontype}
            onClick={HandleClick}
          />
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(GoogleContacts));
