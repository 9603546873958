import { put, call, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import { selectToken } from "./selectors";
import {
  auth,
  logout,
  setAuthRedirect,
  authCheckStatus,
  authSuccess,
} from "../../store/actions/auth";
import { setLoading, setUserDetails, setNotifications } from "./actions";

const getUserDetails = (token: string) => {
  return axios
    .post("/user.php", {
      info_type: "1",
      token,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const pullNotifications = (token: string) => {
  return axios
    .post("/notification.php", {
      info_type: "1",
      token,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const updateProfile = (token: string, options: {} = {}) => {
  var data = new FormData();
  data.append("token", token);
  data.append("info_type", "1");
  data.append("name", options?.name);
  data.append("mobile_number", options?.mobile_number);
  options?.profile_photo &&
    data.append("profile_photo", options?.profile_photo);
  data.append("email", options?.email);
  data.append("insta_link", options?.insta_link);
  data.append("fb_link", options?.fb_link);
  data.append("twitter_link", options?.twitter_link);
  data.append("dob", options?.dob);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post("/profile_update.php", data, config)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

function* onLoad() {
  // check for login
  try {
    yield put(setLoading(true));
    let token = yield select(selectToken);

    if (!token) {
      const madFanClientToken = localStorage.getItem("madFanClientToken");
      const madFanClientName = localStorage.getItem("madFanClientName");
      if (!madFanClientToken) {
        yield put(logout());
      } else {
        token = madFanClientToken;
        yield put(authSuccess(madFanClientToken, madFanClientName));
      }
    }
    if (token) {
      const userDetails = yield call(getUserDetails, token);
      if (userDetails.status) {
        yield put(setUserDetails(userDetails.data[0]));
      }
      const notifs = yield call(pullNotifications, token);
      if (notifs.status) {
        yield put(setNotifications(notifs.data));
      }
    }

    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* onConfigureUserDetails(action) {
  const token = action.payload;
  if (token) {
    const userDetails = yield call(getUserDetails, token);
    if (userDetails.status) {
      yield put(setUserDetails(userDetails.data[0]));
    }
  }
}
function* getNotifications() {
  const token = yield select(selectToken);
  if (token) {
    const notifs = yield call(pullNotifications, token);
    if (notifs.status) {
      yield put(setNotifications(notifs.data));
    }
  }
}

function* onEditProfile(action) {
  const {
    dp,
    contact,
    name,
    filePath,
    birthDate,
    email,
    fbLink,
    instaLink,
    twitterLink,
  } = action.payload;

  const token = yield select(selectToken);
  if (token) {
    const res = yield call(updateProfile, token, {
      name: name,
      mobile_number: contact,
      profile_photo: filePath,
      email,
      dob: birthDate,
      fb_link: fbLink,
      insta_link: instaLink,
      twitter_link: twitterLink,
    });
    if (!res.status) {
      // yield put(setError(res.msg));
    }
  }
}

function* onLogout() {
  yield localStorage.clear();
  window.location.replace("/");
}

export function* appSaga() {
  yield takeLatest(ACTION_TYPES.LOAD, onLoad);
  yield takeLatest(ACTION_TYPES.CONFIGURE_USER_DETAILS, onConfigureUserDetails);
  yield takeLatest(ACTION_TYPES.LOGOUT, onLogout);
  yield takeLatest(ACTION_TYPES.GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(ACTION_TYPES.EDIT_PROFILE, onEditProfile);
}
