/**
 *
 * CoinAmount Component
 *
 */
import React from "react";
import Grid from '@material-ui/core/Grid';
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import star from "../../images/star.png";
import { parseInt } from "lodash";
type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
  numberOfCoins: string;
  price: string;
  active?: boolean;
  onClick?: Function;
};

function CoinAmount(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    numberOfCoins,
    price,
    active,
    onClick
  } = props;

  return (
    <>
      <Grid className={active ? classes.wrapperActive : classes.wrapper} style={style} onClick={onClick} container >
        <Grid item xs={6}>
          <div className={classes.leftWrapper}>
            <div>
              <img src={star} className={classes.image} alt="" />
            </div>
            <div className={classes.noOfcoin}>{numberOfCoins}</div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.rightWrapper}>₹ {price}</div>
        </Grid>
        {
          parseInt(numberOfCoins) > parseInt(price)
            ?
            <Grid item xs={12} className={classes.discount}>
              <span >{parseInt((parseInt(numberOfCoins)-parseInt(price))/parseInt(numberOfCoins)*100)}% discount</span>
            </Grid>
            : null
        }

      </Grid>


    </>
  );
}

export default withStyles(styles)(withIntl(CoinAmount));
