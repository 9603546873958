/**
 *
 * app.js
 *
 * This is the entry file for the application,only setup and boilerplate
 * code
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./scenes/App";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./utils/configureStore";
import "./index.css";
// import reportWebVitals from '../src/reportWebVitals';

const initialState = {};

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
