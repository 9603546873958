const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "box-shadow":
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19)",
    border: 0,
    fontFamily: "poppins",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    "border-radius": 16,
    // height: 400,
    paddingTop: 60,
    paddingBottom: 60,
    backgroundColor: "white",
  },
  modalBox: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    "border-radius": 16,

    width: "90%",
    // height:'80%',
    backgroundColor: "white",
  },
  heading: {
    marginTop: "3%",
    fontSize: "1.5em",
  },
  wrapper: {
    display: "flex",
    flexFlow: "column",
    flex: 1,
    marginRight: 32,
    "&:last-child": { marginRight: 0 },
  },
  loginBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: "70%",
    color: "white",
    borderRadius: 10,
    fontSize: 14,
    padding: "0 10px",
    margin: "20px 0",
    cursor: "pointer",
  },
  title:{
    color: "black",
  },
  social: {
    height: 32,
    width: 32,
    "border-radius": "50%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  img: {
    height: 18,
    width: 18,
  },
  input: {
    width: "90%",
    margin: "auto",
    height: 30,
    border: "1px solid #ced4da",
    "border-radius": 5,
    "font-size": "1rem",
    padding: ".375rem .75rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    "&:focus": {
      border: "3px solid #e1ecf4",
    },
  },
  error: {
    color: "#bf1650",
  },

  "@media only screen and (max-width: 800px)": {
    container: {
      width: "90%",
    },
  },
};

export default styles;
