const styles = (theme: any) => ({
    wrapper: {
      maxWidth: 400,
      height: "auto",
      borderRadius: 20,
      boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
      backgroundColor: "#ffffff",
      fontFamily: "Poppins",
    },
    close: {
      justifyContent: "flex-end",
      display: "flex",
      padding: 20,
    },
    circle: {
      justifyContent: "center",
      display: "flex",
      minHeight: 177,
      maxWidth: 169,
      objectFit: "contain",
      borderRadius: 30,
      alignItems: "center",
      margin: "auto",

    },
    firsttext: {
      fontSize: 20,
      fontWeight: "600",
      textAlign: "center",
      fontFamily: "Poppins",
      lineHeight: 1.51,
      color: "#000000",
      marginTop:30,
      marginBottom:20,
      maxWidth:196,
      margin:'auto'
    },
    second: {
      fontSize: 14,
      fontWeight: 'normal',
      textAlign: "center",
      fontFamily: "Poppins",
      lineHeight: 1.43,
      color: "#000000",
      marginBottom:20,
     opacity:0.7,
      margin:'auto',
      maxWidth:223,

  
  
    },
    input:{
        backgroundColor: '#f3f3f3',
        outline:'none',
        minWidth:324,
        minHeight:52,
        borderRadius:10,
        border:'none',
        paddingLeft:20,
        fontSize:16,
        opacity: 0.5,
        color: '#000000',
        textAlign:'center',
        



    },
    button: {
      marginTop:30,
      width:'100%',
      textAlign:'left'

    },
    buttontype: {
      borderRadius: 10,
      marginBottom:30,
      backgroundColor:"#e90041 ",
      color:' #ffffff',
      width:'100%',
      justifyContent:"space-between",


      "&:hover": {
        color:"#e90041 ",
      }
  
    },
    '@media (max-width: 400px)': {
      circle:{
        width:'100%'
      },
      firsttext:{
        width:'100%',
        margin:25,
        fontSize:15,
        textAlign:'center'
        
      },
      buttontype:{
          fontSize:15
      },
      input:{
        minWidth:'90%'
      }
    }
  });
  
  export default styles;
  