/**
 *
 * ReferAndEarn Component
 *
 */
import React from "react";
import withIntl from "../../utils/withIntl";
import { withStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Grid } from "@material-ui/core";
import styles from "./styles";
import messages from "./messages";
import GoogleContacts from "react-google-contacts";

type Props = {
    classes: any;
    formatMessage: Function;
    className?: any;
    style?: Object;
    link: string;
    onImportGoogleContacts: Function;
};

export const shareFacebook = (link) => {
    //console.log(window.location.href);
    window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        link+"&quote="+"Hey, use my MadFan referral link to earn 100 Mad Coins."+link,
        "facebook-share-dialog",
        "width=626,height=436"
    );
};

export const shareTwitter = (link) => {
    //console.log(window.location.href);
    window.open(
        "http://twitter.com/share?text=Hey, use my MadFan referral link to earn 100 Mad Coins.&url="+link,
        "width=626,height=436"
    );
};

export const shareWhatsapp = (link) => {
    window.open(
        "https://api.whatsapp.com/send?text="+"Hey, use my MadFan referral link to earn 100 Mad Coins."+link,
        "width=626,height=436"
    );
}

function ReferAndEarn(props: Props) {
    const { classes, style, formatMessage, onImportGoogleContacts } = props;
    const { header, description, copytext, invite, contacts } = messages;

    const handlecopytext = () => {
        var text = document.getElementById("inputText");
        text.select();
        document.execCommand("copy");
        alert("copied");
    };

    const responseCallback = (e) => {
        onImportGoogleContacts(e);
    };

    return (
        <div className={classes.wrapper} style={style}>
            <Grid className={classes.leftwrapper}>
                <div className={classes.header}> {formatMessage(header)}</div>
                <p className={classes.details}>{formatMessage(description)}</p>
                <input className={classes.input} value={props.link} id="inputText" />
                <span className={classes.copytag} onClick={handlecopytext}>
                    <u>{formatMessage(copytext)}</u>
                </span>
            </Grid>
            <Grid className={classes.rightwrapper}>
                <div className={classes.insidewrapper}>
                    <div className={classes.tabwrapper}>
                        <div className={classes.invitewrapper}>
                            <p className={classes.invites}>{formatMessage(invite)}</p>
                        </div>
                        <div>
                            <div>
                                <FacebookIcon
                                    className={classes.icon}
                                    style={{ color: "#385398", cursor: "pointer" }}
                                    onClick={() => shareFacebook(props.link)}
                                />
                                <TwitterIcon
                                    className={classes.icon}
                                    style={{ color: "#7cc5f8", cursor: "pointer" }}
                                    onClick={() => shareTwitter(props.link)}
                                />
                                {/* <InstagramIcon
                  className={classes.icon}
                  style={{ color: "#b72a98" }}
                /> */}
                               
                                    <WhatsAppIcon
                                        className={classes.icon}
                                        style={{ color: "#31ba46" }}
                                        onClick={() =>shareWhatsapp(props.link)}
                                    />
                                
                            </div>
                            <GoogleContacts
                                clientId="579029099620-in07uirbdv1gflc25aajps3hbr55hrqr.apps.googleusercontent.com"
                                render={(renderProps) => (
                                    <div
                                        className={classes.googlecontact}
                                        onClick={renderProps.onClick}
                                    >
                                        <u>{formatMessage(contacts)}</u>
                                    </div>
                                )}
                                buttonText="Import"
                                onSuccess={responseCallback}
                                onFailure={responseCallback}
                            />
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(withIntl(ReferAndEarn));
