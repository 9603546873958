const styles = (theme: any) => ({
  container: {
    maxWidth: 236,
    borderRadius: 20,
    position: "relative" as "relative",
  },
  media: {
    height: 268,
    "object-fit": "cover",
    "object-position": "top",
  },
  mediaImage: {
    "vertical-align": "top",
    "&:after": {
      content: '""',
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute" as "absolute",
      background: "rgba(0,0,0,0.63)",
    },
  },
  bookButton: {
    marginBottom: 16,
  },
  cardActionRoot: {
    padding: 0,
    display: "flex",
    flexFlow: "column",
    marginTop: 20,
  },
  content: {
    width: "-webkit-fill-available",
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexFlow: "column",
    marginTop: 48,
  },
  cardContent: {
    "text-align": "center",
  },
  celebName: {
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "bold",
  },
  famousFor: {
    display: "inline-block",
    width: "calc(80%)",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

export default styles;
