/**
 *
 * Celebrity constants
 *
 */

const scene = "app/Celebrity";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const LOAD = `${scene}/LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_INFLUENCER_DETAIL = `${scene}/SET_INFLUENCER_DETAIL`;
export const SET_RECOMMENDED_INFLUENCERS = `${scene}/SET_RECOMMENDED_INFLUENCERS`;
export const BOOK_ORDER = `${scene}/BOOK_ORDER`;
export const SET_ORDER_DETAIL = `${scene}/SET_ORDER_DETAIL`;
export const APPLY_COUPON = `${scene}/APPLY_COUPON`;
export const SET_COUPON_LOADING = `${scene}/SET_COUPON_LOADING`;
export const SET_COUPON_DISCOUNT = `${scene}/SET_COUPON_DISCOUNT`;
export const SET_DISCOUNTED_AMOUNT = `${scene}/SET_DISCOUNTED_AMOUNT`;
