const styles = (theme: any) => ({
  container: { fontFamily: "Poppins" },
  header: { display: "flex", alignItems: "center" },
  coinIcon: { color: "#e90041", marginRight: 16 },
  winCoins: { fontSize: 28, fontWeight: "600" },
  subTitle: { color: "#e90041", fontSize: 20, marginLeft: 26 },
  influencers: { display: "flex", justifyContent: "flex-start", marginTop: 32 },
  influencer: { margin: "0px 16px 8px 8px" },
  contentBox: { marginTop: 36 },
  link: {
    fontWeight: 700,
    fontSize: 20,
    color: "#000000",
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nameLink: {
    color: "#e90041",
  },
  "@media (max-width: 967px)": {
    influencer: { margin: "0px 8px 4px 4px" },
  },
  "@media (max-width: 600px)": {
    winCoins: { fontSize: 20, fontWeight: "600" },
    subTitle: { color: "#e90041", fontSize: 16, marginLeft: 18 },
    contentBox: { marginTop: 24 },
    link: { fontSize: 10 },
  },
});

export default styles;
