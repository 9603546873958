/**
 *
 * MyBookings Component
 *
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Dialog } from "@material-ui/core";

import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Order } from "./reducer";
import classnames from "classnames";
import {
  MEDIA_DEVICE_DESKTOP,
  MEDIA_DEVICE_MOBILE,
  BREAKING_POINT,
} from "../../utils/constants";
import { useWindowWidth } from "@react-hook/window-size";
import Bookings from "../../components/Bookings";
import ProfileCard from "../../components/ProfileCard";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  orders: Order[];

  loading: boolean;

  load: () => void;
};

function MyBookings(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    orders,
    loading,
    load,
  } = props;
  useEffect(() => {
    load();
  }, []);

  const [bookingType, setBookingType] = useState(0);
  const [orderList, setOrderList] = useState(orders);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogOrder, setDialogOrder] = useState(null);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  const mediaDevice =
    useWindowWidth() > BREAKING_POINT
      ? MEDIA_DEVICE_DESKTOP
      : MEDIA_DEVICE_MOBILE;

  const history = useHistory();
  return (
    <div className={classnames(classes.container, className)} style={style}>
      <div className={classes.header}>
        <div className={classes.myBookings}>
          {mediaDevice === MEDIA_DEVICE_MOBILE && (
            <FontAwesomeIcon icon={faCheckSquare} className={classes.icon} />
          )}
          My Bookings
        </div>
        <div className={classes.buttons}>
          <Button
            className={classes.btn}
            variant="contained"
            color={bookingType === 0 ? "secondary" : "default"}
            disableElevation
            onClick={() => {
              setBookingType(0);
              setOrderList(orders);
            }}
          >
            All
          </Button>
          <Button
            className={classes.btn}
            color={bookingType === 1 ? "secondary" : "default"}
            variant="contained"
            disableElevation
            onClick={() => {
              setOrderList(orders.filter((el) => el.order_status === 1));
              setBookingType(1);
            }}
          >
            Approved Bookings
          </Button>
          <Button
            className={classnames(classes.midBtn, classes.btn)}
            variant="contained"
            disableElevation
            color={bookingType === 2 ? "secondary" : "default"}
            onClick={() => {
              setOrderList(orders.filter((el) => el.order_status === 0));
              setBookingType(2);
            }}
          >
            Pending Bookings
          </Button>
        </div>
      </div>
      {loading ? (
        <CircularProgress
          color="secondary"
          style={{ marginTop: 32, marginLeft: 32 }}
        />
      ) : (
        <div className={classes.content}>
          {orderList &&
            orderList.map((order) => (
              <div className={classes.order}>
                <Bookings
                  img={order?.profile_photo}
                  alt={order?.name}
                  date={order?.order_date?.split(" ").slice(0, -1).join(" ")}
                  price={order?.payment_amount}
                  celebName={order?.name}
                  eventname={order?.occasion}
                  famousFor={order?.famousFor?.split(",")}
                  size={
                    mediaDevice === MEDIA_DEVICE_DESKTOP ? "large" : "small"
                  }
                  onClick={() => {
                    setDialogOrder(order);
                    setShowDialog(true);
                  }}
                />
              </div>
            ))}
        </div>
      )}
      <Dialog open={!!dialogOrder} onClose={() => setDialogOrder(null)}>
        {!!dialogOrder && (
          <ProfileCard
            img={dialogOrder?.profile_photo}
            name={dialogOrder?.name}
            details={dialogOrder?.famousFor}
            reason={dialogOrder?.occasion}
            date={dialogOrder?.order_date?.split(" ").slice(0, -1).join(" ")}
            paymentstatus={dialogOrder?.payment_amount ? "Paid" : "Unpaid"}
            delievery={dialogOrder?.order_status === 0 ? "Pending" : "Done"}
            onNewBooking={() => history.push(`/celebrity/${dialogOrder?.id}`)}
          />
        )}
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(withIntl(MyBookings));
