/**
 *
 * Challenge actions
 *
 */

import * as ACTION_TYPES from "./constants";

export function defaultAction() {
  return {
    type: ACTION_TYPES.DEFAULT_ACTION,
  };
}
export function load() {
  return {
    type: ACTION_TYPES.LOAD,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}
export function setInfluencers(value: any) {
  return {
    type: ACTION_TYPES.SET_INFLUENCERS,
    payload: value,
  };
}
export function onLoadChallenge(id: string) {
  return {
    type: ACTION_TYPES.ON_LOAD_CHALLENGE,
    payload: id,
  };
}
export function setChallenges(ques: Challenge[]) {
  return {
    type: ACTION_TYPES.SET_CHALLENGES,
    payload: ques,
  };
}
export function setChallengeLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_CHALLENGE_LOADING,
    payload: value,
  };
}
export function onChallengeAccepted(
  challenge_id: number,
  coin: number,
  link: string,
  celebId: string
) {
  return {
    type: ACTION_TYPES.ON_CHALLENGE_ACCEPTED,
    payload: { challenge_id, coin, link, celebId },
  };
}

export function acceptChallenge(chg: any) {
  return {
    type: ACTION_TYPES.ACCEPT_CHALLENGE,
    payload: chg,
  };
}

export function setStartChallenge(id: string) {
  return {
    type: ACTION_TYPES.SET_START_CHALLENGE,
    payload: id,
  };
}
