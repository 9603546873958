/**
 * RechargePopUp Messages
 *
 * This contains all the text for the RechargePopUp Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.RechargePopUp";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Enter Manual Coins",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "You can recharge your wallet as  much coins you want",
  },
});
