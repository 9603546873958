const styles = (theme: any) => ({
  container: {},
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  icon: { color: "#e90041", marginRight: 16 },
  myBookings: {
    fontSize: 28,
    fontWeight: "600",
  },
  buttons: {},
  btn: { textTransform: "none", fontFamily: "Poppins" },
  midBtn: { borderRadius: "none" },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap ",
    marginTop: 40,
  },
  order: { marginRight: 30, marginBottom: 30 },
  "@media (max-width: 967px)": {
    myBookings: {
      fontSize: 28,
      fontWeight: "600",
    },
    buttons: { marginRight: 0, whiteSpace: "nowrap" },
    content: { marginTop: 30, justifyContent: "space-around" },
    order: { marginRight: 0, marginBottom: 20 },
  },
  "@media (max-width: 600px)": {
    buttons: { marginTop: 16 },
  },
});

export default styles;
