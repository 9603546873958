/**
 *
 * RechargePopUp Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Container, Box } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Button from "../Button/index";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import sample from "../../images/cash.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  handleManual?: any
};

function RechargePopUp(props: Props) {
  const { classes, style, className, formatMessage, handleManual } = props;

  const [amount, setAmount] = useState('');

  const sendAmount = () =>{
    if(amount > 0){
      handleManual(amount)
    }
  }


  return (
    <div className={classes.wrapper} style={style}>
      <Container>
        <div className={classes.close} onClick={handleManual}>
          <CloseIcon />
        </div>
        <div className={classes.circle}>
          <img src={sample} className={classes.cameraicon} />
        </div>
        <div className={classes.firsttext}>
          {formatMessage(messages.header)}
        </div>
        <div className={classes.second}>
          {formatMessage(messages.description)}
        </div>
        <input
          type="number"
          className={classes.input}
          value={amount}
          onChange = { (e) => setAmount(e.target.value)}
          placeholder="Enter Number of Coins"
        />
        <div className={classes.button}>
          <Button
            name="RECHARGE WALLET"
            buttonType="secondary"
            className={classes.buttontype}
            selected
            rightIcon={faArrowRight}
            onClick={sendAmount}
          />
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(RechargePopUp));
