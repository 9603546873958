const styles = (theme: any) => ({
  container: {
    fontFamily: "Poppins",
    padding: 56,
    borderRadius: 20,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  cardBox: {
    width: '90%',
    margin: 'auto',
    fontFamily: "Poppins",
    padding: '10px 10px',
    borderRadius: 20,
    height: 120,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  cardNumber:{
    fontSize:22,
    fontWeight:'bold',
    marginBottom:30
  },
  date:{
    fontSize:16,
    fontWeight:'bold',
    color:'grey'
  },
  sendMail:{
    'align-items': 'end',
    display: 'block',
    textAlign:'end'
  },
  title: { display: "flex", alignItems: "center" },
  amazonPay: { width: 94, height: 94 },
  head: { fontSize: 28,fontWeight: "bold", maxWidth: 900, marginTop: 20, marginBottom:20 },
  hrLine: { marginTop: 48, marginBottom: 36, borderTop: "1px dashed #707070" },
  content: {},
  uHave: { fontSize: 24, fontWeight: 600 },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 30,
    flexWrap: "wrap",
  },
  wrapper: { display: "flex", alignItems: "center" },
  img: { width: 40, height: 40, marginRight: 16 },
  wrap: {},
  amount: { fontSize: 38, fontWeight: 600 },
  text: { fontSize: 24, fontWeight: 500 },
  equal: { margin: "0px 32px", fontSize: 34 },
  arrowImg: {
    width: 18,
    height: 12,
    marginLeft: 24,
  },
  btn: { marginRight: 12 },
  "@media (max-width: 600px)": {
    container: {
      padding: 28,
    },
    amazonPay: { width: 44, height: 44 },
    head: { fontSize: 16, maxWidth: 600, marginLeft: 12 },
    hrLine: {
      marginTop: 24,
      marginBottom: 18,
    },
    content: {},
    uHave: { fontSize: 16, fontWeight: 600 },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 30,
      flexWrap: "wrap",
    },
    wrapper: { display: "flex", alignItems: "center" },
    img: { width: 20, height: 20, marginRight: 12 },
    wrap: {},
    amount: { fontSize: 18, fontWeight: 600 },
    text: { fontSize: 12, fontWeight: 500 },
    equal: { margin: "0px 32px", fontSize: 16 },
    arrowImg: {
      width: 12,
      height: 8,
      marginLeft: 12,
    },
    btn: { margin: "auto", marginTop: 20, alignSelf: "center" },
  },
});

export default styles;
