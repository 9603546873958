const styles = (theme: any) => ({
  btn: {
    fontFamily: "Poppins",
    fontSize: 17,
    textTransform: "none",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    borderRadius: 0,
    // boxSizing: "border-box",
  },
  btnSelected: {
    backgroundColor: "rgba(253, 180, 40, 0.09)",
    "font-weight": "bold",
    borderLeft: "7px solid #e90041",
  },
});

export default styles;
