// import { put, call, takeEvery, takeLatest,select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import { put, call, takeLatest, select } from "redux-saga/effects";
import { selectToken } from "../App/selectors";
import {
    setCategories,
    setLoading,
    setInfluencers,
  } from "./actions";

  const getFilters = (infoType: string) => {
    return axios
      .post("/filter.php", {
        info_type: infoType,
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };
  
  const getInfluencers = (infoType: string, token: any) => {
    console.log(token, "sadsad");
    
    return axios
      .post("/influencer.php", {
        info_type: infoType,
        token: token
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };
function * onLoad(){
    try {
        const categories = yield call(getFilters, "1");
        if (categories.status) {
          yield put(setCategories(categories.data));
        }
        let token = yield select(selectToken);
        const influencers = yield call(getInfluencers, "1", token);
        console.log(influencers)
        if (influencers.status) {
          console.log(influencers)
          yield put(setInfluencers(influencers.data));
        }
        yield put(setLoading(false));
      } catch (e) {
        yield put(setLoading(false));
      }
}

export function* homePageSaga() {
    yield takeLatest(ACTION_TYPES.ON_LOAD, onLoad);
}