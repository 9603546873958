/**
 * Congratulations Messages
 *
 * This contains all the text for the Congratulations Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.Congratulations";

export default defineMessages({
  Congratulationheader: {
    id: `${scope}.Congratulationheader `,
    defaultMessage: "Congratulations!",
  },
  contactsheader: {
    id: `${scope}.contactsheader`,
    defaultMessage: "Great!",
  },
  challengeheader: {
    id: `${scope}.challengeheader `,
    defaultMessage: "Challenge Accepted",
  },
  bookvideodescription: {
    id: `${scope}.bookvideodescription`,
    defaultMessage: "You have successfully Booked Video Message  ",
  },
  bookvideodetails: {
    id: `${scope}.bookvideodetails`,
    defaultMessage: "You'll be keep notify for the video status. ",
  },
  challengedescription: {
    id: `${scope}.challengedescription`,
    defaultMessage:
      "You have successfully accepted Sharman Joshi Golmaal Dance Challenge",
  },

  coindescription: {
    id: `${scope}.coindescription`,
    defaultMessage: "You have successfully recharge your wallet",
  },
  contactdescription: {
    id: `${scope}.contactdescription`,
    defaultMessage:
      "You have successfully invited your google contacts friends.",
  },
  challengeName: {
    id: `${scope}.challengeName`,
    defaultMessage: " Golmaal Dance Challenge",
  },
  challengePersonName: {
    id: `${scope}.challengePersonName`,
    defaultMessage: "Sharman Joshi",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "How you can win challenge?",
  },
});
