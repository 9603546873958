/**
 *
 * ImageCardHover Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  img: any;

  alt: string;

  celebName: string;

  famousFor: string[];

  onBook: () => void;

  onViewProfile: () => void;
};

function ImageCardHover(props: Props) {
  const {
    classes,
    formatMessage,
    className,
    img,
    alt,
    celebName,
    famousFor,
    onBook,
    onViewProfile,
  } = props;

  return (
    <Card className={classnames(classes.container, className)}>
      <CardActionArea>
        <div className={classes.mediaImage}>
          <CardMedia
            classes={{
              media: classes.media,
            }}
            image={img}
            title={alt}
            component="img"
          />
        </div>
      </CardActionArea>
      <div className={classes.content}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.celebName}>{celebName}</div>
          <div className={classes.famousFor}>{famousFor.join(", ")}</div>
        </CardContent>
        <CardActions classes={{ root: classes.cardActionRoot }}>
          <Button
            className={classes.bookButton}
            size="large"
            variant="contained"
            color="secondary"
            onClick={onBook}
          >
            {formatMessage(messages.bookNow)}
          </Button>
          <Button
            color="secondary"
            onClick={onViewProfile}
            style={{ marginLeft: 0, color: "#fff" }}
          >
            {formatMessage(messages.viewProfile)}
          </Button>
        </CardActions>
      </div>
    </Card>
  );
}

export default withStyles(styles)(withIntl(ImageCardHover));
