/**
 * WinCoins Messages
 *
 * This contains all the text for the WinCoins Scene
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.scene.WinCoins";

export default defineMessages({
  winCoins: {
    id: `${scope}.winCoins`,
    defaultMessage: "Win Mad Coins",
  },
});
