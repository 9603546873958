import React, { useState, useEffect, lazy, Suspense } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import AppBar from "../../components/AppBar";
import UserProfile from "../UserProfile";
import EditProfile from "../../components/EditProfile";
import LoginModal from "../../LoginModal";
import Dialog from "@material-ui/core/Dialog";
import {
  MEDIA_DEVICE_DESKTOP,
  MEDIA_DEVICE_MOBILE,
  BREAKING_POINT,
} from "../../utils/constants";
import { useWindowWidth } from "@react-hook/window-size";
import defaultProfile from "../../images/defaultProfile.png";
import { termsAndConditions } from "../../utils/termsAndConditions";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";

const SearchCeleb = lazy(() => import("../SearchCeleb"));
const Celebrity = lazy(() => import("../Celebrity"));
const Privacy = lazy(() => import("../HTML/Pages/privacy"))
const Terms = lazy(() => import("../HTML/Pages/terms"))
const CancellationRefund = lazy(() => import("../HTML/Pages/cancellation_refund"))


function Main(props: any) {
  const {
    classes,
    userDetails,
    isLoggedIn,
    editProfile,
    notifications,
  } = props;

  const mediaDevice =
    useWindowWidth() > BREAKING_POINT
      ? MEDIA_DEVICE_DESKTOP
      : MEDIA_DEVICE_MOBILE;

  const [loginModal, setLoginModal] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const history = useHistory();
  const currentRoute = history?.location?.pathname?.split("/")?.pop() || "";

  return (
    <>
      <div id="root">
        <AppBar
          device={mediaDevice === MEDIA_DEVICE_DESKTOP ? "large" : "small"}
          onLogin={() => setLoginModal(true)}
          isLoggedIn={!!isLoggedIn}
          // showSearchBar={currentRoute !== "search"}
          showSearchBar={false}
          route={currentRoute}
          noOfCoin={userDetails?.coin}
          noOfNotifs={notifications?.length || 0}
          notifs={notifications}
          dp={userDetails?.profile_photo || defaultProfile}
          onCoinClick={() => history.push("/profile/recharge-wallet")}
          onProfileClick={() => history.push("/profile/my-bookings")}
          // onNotifClick={action("Notif Click")}
          onSearch={() => history.push("/search")}
          userName={userDetails?.name || ""}
          level={userDetails?.percentage}
          onEditProfile={() => {
            setShowEditProfile(true);
          }}
          onChangeRoute={(route) => history.push(`/profile/${route}`)}
          onLogoClick={() => history.push("/")}
        />
        <LoginModal
          handleClose={() => setLoginModal(false)}
          open={loginModal}
        />
        <Dialog
          open={showEditProfile}
          onClose={() => setShowEditProfile(false)}
        >
          <EditProfile
            name={userDetails?.name || ""}
            contact={userDetails?.mobile_number || ""}
            profilePic={userDetails?.profile_photo || defaultProfile}
            email={userDetails?.email}
            fbLink={userDetails?.fb_link}
            instaLink={userDetails?.insta_link}
            twitterLink={userDetails?.twitter_link}
            birthDate={userDetails?.dob}
            onSave={(data) => {
              console.log(data);

              editProfile(data);
              setShowEditProfile(false);
            }}
          />
        </Dialog>
        <Switch>
          <Suspense fallback={() => {}}>
            <Route
              path="/search"
              render={(componentProps) => <SearchCeleb {...componentProps} />}
            />
            <Route
              path="/privacy"
              render={(componentProps) => <Privacy {...componentProps} />}
            />
            <Route
              path="/terms_condition"
              render={(componentProps) => <Terms {...componentProps} />}
            />
            <Route
              path="/cancellation_refund"
              render={(componentProps) => <CancellationRefund {...componentProps} />}
            />
            <Route
              path="/celebrity/:id"
              render={(componentProps) => <Celebrity {...componentProps} />}
            />
            <Route
              path="/profile"
              render={(componentProps) => <UserProfile {...componentProps} />}
            />
            {/* <Route
              path="/terms"
              render={(componentProps) => (
                <Terms {...componentProps} classes={classes} />
              )}
            /> */}
          </Suspense>
        </Switch>
      </div>
    </>
  );
}

export default withStyles(styles)(withIntl(Main));

// function Terms(props) {
//   const { classes } = props;
//   return (
//     <div className={classes.container}>
//       <header className={classes.header}>Terms & Conditions</header>
//       <p className={classes.content}>{termsAndConditions}</p>
//     </div>
//   );
// }
