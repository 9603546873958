import produce from "immer";
import * as ACTION_TYPES from "./constants";
export type UserDetails = {
  profile_photo: string;
  referral_link: string;
  name: string;
  level: string;
  country: string;
  city: string;
  coin: number;
  mobile_number: string;
  twitter_link: string;
  fb_link: string;
  insta_link: string;
  dob: string;
  email: string;
  percentage: string;
};

export type Notification = {
  notif_type: string;
  notif_header: string;
  notification: string;
  added_on: string;
};

export type App = {
  loading: boolean;
  userDetails: UserDetails;
  notifications: Notification[];
};

export const initialState: App = {
  loading: false,
  userDetails: {},
  notifications: [],
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.DEFAULT_ACTION:
        break;
      case ACTION_TYPES.LOAD:
        draft.loading = true;
        break;
      case ACTION_TYPES.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ACTION_TYPES.SET_USER_DETAILS:
        draft.userDetails = action.payload;
        break;
      case ACTION_TYPES.SET_USER_COINS:
        draft.userDetails.coin = action.payload;
        break;
      case ACTION_TYPES.SET_NOTIFICATIONS:
        draft.notifications = action.payload;
        break;
      case ACTION_TYPES.EDIT_PROFILE:
        draft.userDetails.profile_photo = action.payload.dp;
        draft.userDetails.name = action.payload.name;
        draft.userDetails.mobile_number = action.payload.contact;
        draft.userDetails.email = action.payload.email;
        draft.userDetails.dob = action.payload.birthDate;
        draft.userDetails.fb_link = action.payload.fbLink;
        draft.userDetails.insta_link = action.payload.instaLink;
        draft.userDetails.twitter_link = action.payload.twitterLink;

        break;
    }
  });

export default appReducer;
