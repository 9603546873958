const styles = (theme: any) => ({
  container: {
    width: 400,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    padding: 36,
    fontFamily: "Poppins",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(12,132,234,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(100,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  header: { fontWeight: "bold", fontSize: 24, marginBottom: 32 },
  circle: {
    justifyContent: "center",
    display: "flex",
    height: 150,
    width: 150,
    objectFit: "contain",
    borderRadius: 30,
    opacity: 0.8,
    alignItems: "center",
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  cameraicon: {
    color: "white",
    fontSize: 80,
  },
  box: { marginTop: 12, width: "100%" },
  wrapper: { marginTop: 20 },
  inputField: { fontSize: 20, borderRadius: 8, paddingTop: 0 },
  textField: { width: "100%" },
  social: {
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: 20,
    alignSelf: "baseline",
    marginTop: 48,
  },
  logos: { width: 20, height: 20 },
  error: {
    color: "#bf1650",
  },
  "@media (max-width: 600px)": {
    container: {
      width: 300,
      padding: 20,
    }
  }
});

export default styles;
