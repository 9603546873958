import { createSelector } from "reselect";
import { initialState } from "./reducer";
import { State } from "../../utils/configureStore";
/**
 * Direct selector to the userProfile state domain
 */
const selectUserProfileDomain = (state: State) =>
  state.userProfileReducer || initialState;

/**
 * Custom selectors to the userProfile state
 */
export const selectUserProfile = createSelector(
  selectUserProfileDomain,
  (substate) => substate
);

export const selectRedeemLoading: (arg0: State) => boolean = createSelector(
  selectUserProfile,
  (up) => up.redeemLoading
);

export const selectRedeemStatus: (arg0: State) => boolean = createSelector(
  selectUserProfile,
  (up) => up.redeemStatus
);
export const selectSendingMailSuccess: (
  arg0: State
) => boolean = createSelector(selectUserProfile, (up) => up.sendingMailSuccess);
