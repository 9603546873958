/**
 *
 * MyBookings actions
 *
 */

import * as ACTION_TYPES from "./constants";

export function defaultAction() {
  return {
    type: ACTION_TYPES.DEFAULT_ACTION,
  };
}
export function load() {
  return {
    type: ACTION_TYPES.LOAD,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}
export function setOrders(value: any) {
  return {
    type: ACTION_TYPES.SET_ORDERS,
    payload: value,
  };
}
