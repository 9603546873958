/**
*
* WinCoins
*
*/
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";

import Layout from "./layout";
import reducer from "./reducer";

import { } from "./actions";

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = (dispatch) =>
bindActionCreators(
{

},
dispatch
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);