const styles = (theme: any) => ({
  wrapper: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    maxWidth: 1032,
    // height: 527,
    padding: 30,
    fontFamily: "Poppins",
    boxShadow: " 0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    paddingBottom: 30,
  },
  "@media (max-width: 967px)": {

  },
});

export default styles;
