const styles = (theme: any) => ({
  container: {
    fontFamily: "Poppins",
    "box-sizing": "border-box",
  },
  largeDevice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 48px",
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  left: { display: "flex", alignItems: "center" },
  logo: { height: 64, width: "auto", cursor: "pointer" },
  searchBar: {
    marginLeft: 36,
    // height: 60,
    minWidth: 400,
    backgroundColor: "#f3f3f3",
    fontFamily: "Poppins",
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 21,
    borderRadius: 12,
    cursor: "pointer",
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexBasis: 1,
  },
  coinButton: {
    backgroundColor: "#f3f3f3",
    borderRadius: 30,
    fontFamily: "Poppins",
    color: "#e90041",
    fontSize: 24,
    paddingLeft: 20,
    paddingRight: 20,
  },
  coinImg: {
    width: 36,
    height: 36,
    marginRight: 18,
  },
  noOfCoin: {},
  notifButton: {
    marginLeft: 32,
    position: "relative" as "relative",
  },
  notifImg: {
    width: 36,
    height: 36,
  },
  noOfNotifs: {
    position: "absolute" as "absolute",
    fontFamily: "Poppins",
    color: "#fff",
    backgroundColor: "#e90041",
    width: 24,
    height: 24,
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #fff",
    boxSizing: "border-box",
    right: 6,
    top: 8,
  },
  dp: { marginLeft: 32 },
  displayImg: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  //   downButton: {

  //   },
  logoSmall: {
    height: 36,
    width: "auto",
    marginLeft: 8,
    crsor: "pointer",
  },
  smallDevice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 8,
    // maxWidth: 600,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  coinButtonSmall: {
    backgroundColor: "#f3f3f3",
    borderRadius: 30,
    fontFamily: "Poppins",
    color: "#e90041",
    fontSize: 16,
    marginLeft: 16,
  },
  coinImgSmall: {
    width: 22,
    height: 22,
    marginRight: 12,
  },
  noOfCoinSmall: { marginRight: 8 },
  notifButtonSmall: {
    position: "relative" as "relative",
    marginLeft: 8,
    marginRight: 8,
  },
  notifImgSmall: {
    width: 28,
    height: 28,
  },
  noOfNotifsSmall: {
    position: "absolute" as "absolute",
    fontFamily: "Poppins",
    color: "#fff",
    backgroundColor: "#e90041",
    width: 14,
    height: 14,
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #fff",
    boxSizing: "border-box",
    right: 12,
    top: 3,
  },
  iconButton: {
    marginRight: 8,
  },
  zeroPadding: {
    padding: 0,
  },
  celebrity: {
    marginLeft: 20,
    fontSize: 15,
    textTransform: 'capitalize',
    fontFamily: "Poppins",
  },
  allMenu:{
    marginLeft:'20px'
  }
});

export default styles;
