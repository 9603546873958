const styles = (theme: any) => ({
  wrapper: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    maxWidth: 1032,
    // height: 527,
    padding: 30,
    fontFamily: "Poppins",
    boxShadow: " 0 3px 26px 0 rgba(233, 0, 65, 0.1)",
  },
  amountcomp: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 14,
    marginBottom: 20,
    flexWrap: "wrap",
  },
  leftWrapper: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
  },
  rightWrapper: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
  },
  entermanual: {
    float: "right",
    // marginRight: 30,
    color: "#e90041",
    fontSize: 20,
    cursor: "pointer",
  },
  index: {
    // marginLeft: 30,
    color: "#ffffff",
    backgroundColor: "#000000",
    padding: " 5px 12px 4px",
    borderRadius: "50%",
  },
  header: {
    paddingTop: 20,
    padingLeft: 25,
    marginBottom: 20,
    flexWrap: "wrap",
  },
  coinheader: {
    marginLeft: 20,
  },
  arrowImg: {
    width: 35,
    height: 20,
    marginLeft: 24,
    fontSize:19,
    fontWeight:800,
  },
  button: { 
    
    marginLeft:'15%'
  
  },
  btn:{
    width:'70%',
    padding:10,
    fontSize:19,
    fontWeight:800,
    fontFamily: "Poppins",
  },
  "@media (max-width: 967px)": {
    amountcomp: {
      justifyContent: "center",
      width: "100%",
    },
    header: {
      fontSize: 14,
    },
  },
});

export default styles;
