/**
 *
 * AppBar Component
 *
 */
import React, { useState } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import SearchBar from "material-ui-search-bar";
import NotificationPopup from "../NotificationPopUp";
import logo from "../../images/logo.png";
import coin from "../../images/coin.png";
import bell from "../../images/bell.png";
import UserProfile from "../UserProfile";

import Button from "@material-ui/core/Button";
import { IconButton, Slide, SwipeableDrawer, Popover } from "@material-ui/core";
import {
  faChevronDown,
  faSearch,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  device?: string;

  noOfCoin: number;

  noOfNotifs: number;

  dp: any;

  userName: string;

  level: number;

  isLoggedIn?: boolean;

  route: string;

  showSearchBar: boolean;

  notifs: any;

  onCoinClick: () => void;

  onNotifClick: () => void;

  onProfileClick: () => void;

  onSearch: () => void;

  onEditProfile: () => void;

  onChangeRoute: (route: string) => void;

  onLogoClick: () => void;

  onLogin: () => void;
};

function AppBar(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    device = "large",
    isLoggedIn,
    noOfCoin,
    noOfNotifs,
    dp,
    userName,
    level,
    route,
    showSearchBar,
    notifs,
    onCoinClick,
    onNotifClick,
    onProfileClick,
    onSearch,
    onEditProfile,
    onChangeRoute,
    onLogin,
    onLogoClick,
  } = props;

  const [showDrawer, setShowDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleNotifClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {!showDrawer && (
        <div className={classnames(classes.container, className)} style={style}>
          {device === "small" ? (
            <div className={classes.smallDevice}>
              <section className={classes.left}>
                <img
                  src={logo}
                  alt="Logo"
                  className={classes.logoSmall}
                  onClick={onLogoClick}
                />
                {isLoggedIn && (
                  <Button
                    className={classes.coinButtonSmall}
                    onClick={onCoinClick}
                    size="small"
                  >
                    <img
                      src={coin}
                      alt="Coin"
                      className={classes.coinImgSmall}
                    />
                    <div className={classes.noOfCoinSmall}>{noOfCoin}</div>
                  </Button>
                )}
              </section>
              <section className={classes.right}>
                {isLoggedIn ? (
                  <>
                    <IconButton
                      onClick={onSearch}
                      classes={{ root: classes.zeroPadding }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </IconButton>

                    <Button
                      className={classes.notifButtonSmall}
                      onClick={handleNotifClick}
                      size="small"
                    >
                      <img
                        src={bell}
                        alt="Notification Bell"
                        className={classes.notifImgSmall}
                      />
                      <div className={classes.noOfNotifsSmall}>
                        {noOfNotifs}
                      </div>
                    </Button>
                    <IconButton
                      onClick={() => setShowDrawer(true)}
                      classes={{ root: classes.zeroPadding }}
                      className={classes.iconButton}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                  </>
                ) : (
                  <Button
                    size="small"
                    onClick={onLogin}
                    // variant="contained"
                    color="secondary"
                  >
                    Login
                  </Button>
                )}
              </section>
            </div>
          ) : (
            <div className={classes.largeDevice}>
              <section className={classes.left}>
                <img
                  src={logo}
                  alt="Logo"
                  className={classes.logo}
                  onClick={onLogoClick}
                />
                {showSearchBar ? (
                  <div onClick={onSearch} className={classes.searchBar}>
                    <SearchBar
                      placeholder="Search Your Celebrity"
                      //   value={this.state.value}
                      onChange={(newValue) => {}}
                      onRequestSearch={() => {}}
                    />
                  </div>
                ) : (
                  <>
                  <div className={classes.allMenu} >
                      <Button
                        color="secondary"
                        onClick={() => window.location.replace("/#about")}
                        className={classes.celebrity}
                      >
                        About Us
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => window.location.replace("/#mad-fan")}
                        className={classes.celebrity}
                      >
                        How It works
                      </Button>
                      <Button
                        color="secondary"
                        onClick={onSearch}
                        className={classes.celebrity}
                      >
                        Celebrity
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => window.location.replace("/#mad-coin")}
                        className={classes.celebrity}
                      >
                        Mad Coin
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => window.location.replace("/#mad-quiz")}
                        className={classes.celebrity}
                      >
                        Mad Quiz
                      </Button>
                    </div>
                  </>

                  
                )}
              </section>
              <section className={classes.right}>
                {isLoggedIn ? (
                  <>
                    <Button
                      className={classes.coinButton}
                      onClick={onCoinClick}
                      size="large"
                      disableRipple
                    >
                      <img src={coin} alt="Coin" className={classes.coinImg} />
                      <div className={classes.noOfCoin}>{noOfCoin}</div>
                    </Button>
                    <Button
                      className={classes.notifButton}
                      onClick={handleNotifClick}
                      size="large"
                    >
                      <img
                        src={bell}
                        alt="Notification Bell"
                        className={classes.notifImg}
                      />
                      <div className={classes.noOfNotifs}>{noOfNotifs}</div>
                    </Button>

                    <Button
                      className={classes.dp}
                      onClick={onProfileClick}
                      size="large"
                    >
                      <img
                        src={dp}
                        alt="Profile"
                        className={classes.displayImg}
                      />
                      {/* <IconButton classes={{ root: classes.downButton }}>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </IconButton> */}
                    </Button>
                  </>
                ) : (
                  <Button
                    size="large"
                    onClick={onLogin}
                    variant="contained"
                    color="secondary"
                  >
                    Login
                  </Button>
                )}
              </section>
            </div>
          )}
        </div>
      )}
      <SwipeableDrawer
        open={showDrawer}
        anchor="top"
        onClose={() => setShowDrawer(false)}
        onOpen={() => setShowDrawer(true)}
      >
        <UserProfile
          usedAsDrawer={true}
          dp={dp}
          userName={userName}
          level={level}
          route={route}
          onEditProfile={onEditProfile}
          onClose={() => setShowDrawer(false)}
          onChangeRoute={(route) => {
            setShowDrawer(false);
            onChangeRoute(route);
          }}
        />
      </SwipeableDrawer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotifClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <>
          <NotificationPopup
            items={notifs?.map((n) => ({
              time: n.added_on,
              title: n.notif_type,
              name: n.notification,
              header: n.notif_header,
            }))}
          />
        </>
      </Popover>
      {/* <Backdrop show={showDrawer} clicked={() => setShowDrawer(false)} /> */}
    </>
  );
}

export default withStyles(styles)(withIntl(AppBar));
