/**
 *
 * BonusCoin Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import cash from "../../images/cash.png";
import Button from "@material-ui/core/Button";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  coinReward?: any;

  allCorrect: boolean;

  onClick: () => void;
};

function BonusCoin(props: Props) {
  const {
    classes,
    style,
    className,
    onClick,
    formatMessage,
    allCorrect,
    coinReward
  } = props;

  return (
    <div className={classes.wrapper}>
      <div>
        <img src={cash} alt="Cash" />
      </div>
      <div className={classes.header}>
        {allCorrect
          ? "Earned "+coinReward+" Mad Coins"
          : formatMessage(messages.headerFailure)}
      </div>
      <div className={classes.details}>
        {allCorrect
          ? formatMessage(messages.details)
          : formatMessage(messages.detailsFailure)}
      </div>

      <div className={classes.buttonwrapper}>
        <Button className={classes.button} onClick={onClick}>
          Go To Quiz
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(withIntl(BonusCoin));
