/**
*
* HomePage constants
*
*/

const scene = "app/HomePage";
export const ON_LOAD = `${scene}/ON_LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_CATEGORIES = `${scene}/SET_CATEGORIES`;
export const SET_INFLUENCERS = `${scene}/SET_INFLUENCERS`;