import { createStore, applyMiddleware } from "redux";
import { combineReducers, compose } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { fork, all } from "redux-saga/effects";
import "regenerator-runtime/runtime";
import thunk from "redux-thunk";

import appReducer from "../scenes/App/reducer";
import searchCelebReducer from "../scenes/SearchCeleb/reducer";
import celebrityReducer from "../scenes/Celebrity/reducer";
import quizReducer from "../scenes/Quiz/reducer";
import myBookingsReducer from "../scenes/MyBookings/reducer";
import challengeReducer from "../scenes/Challenge/reducer";
import homePageReducer from "../scenes/HomePage/reducer";
import userProfileReducer from "../scenes/UserProfile/reducer";

import auth from "../store/reducers/auth";

// to:do combine all saga into
import { appSaga } from "../scenes/App/saga";
import { searchCelebSaga } from "../scenes/SearchCeleb/saga";
import { celebritySaga } from "../scenes/Celebrity/saga";
import { quizSaga } from "../scenes/Quiz/saga";
import { myBookingsSaga } from "../scenes/MyBookings/saga";
import { challengeSaga } from "../scenes/Challenge/saga";
import { homePageSaga } from "../scenes/HomePage/saga";
import { userProfileSaga } from "../scenes/UserProfile/saga";
export type State = any;

const rootReducer = combineReducers({
  appReducer,
  searchCelebReducer,
  celebrityReducer,
  userProfileReducer,
  quizReducer,
  myBookingsReducer,
  challengeReducer,
  auth,
  homePageReducer,
});
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(searchCelebSaga),
    fork(celebritySaga),
    fork(quizSaga),
    fork(myBookingsSaga),
    fork(challengeSaga),
    fork(homePageSaga),
    fork(userProfileSaga),
  ]);
}

// if (module.hot) {
//   module.hot.accept(() => {});
// }

sagaMiddleware.run(rootSaga);
