/**
 *
 * MyBookings constants
 *
 */

const scene = "app/MyBookings";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const LOAD = `${scene}/LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_ORDERS = `${scene}/SET_ORDERS`;
