/**
 *
 * Button Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";

import MUIButton from "@material-ui/core/Button";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  name: string;

  buttonType: string;

  buttonSize: string;

  disabled?: boolean;

  selected?: boolean;

  leftIcon?: any;

  rightIcon?: any;

  leftImg?: any;

  onClick: () => void;
};

function Button(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    name,
    buttonType = "primary",
    buttonSize = "regular",
    disabled = false,
    leftIcon,
    rightIcon,
    selected,
    leftImg,
    onClick,
  } = props;

  const buttonClass = classnames(
    className,
    classes.root,
    {
      [classes.containedPrimary]: buttonType === "primary",
    },
    {
      [classes.containedSecondary]: buttonType === "secondary",
    },
    {
      [classes.containedSizeRegular]: buttonSize === "regular",
    },
    {
      [classes.containedSizeSmall]: buttonSize === "small",
    },
    {
      [classes.selected]: selected,
    }
  );

  return (
    <MUIButton
      variant="contained"
      style={style}
      color={buttonType === "primary" ? "default" : "secondary"}
      disableElevation
      className={buttonClass}
      disabled={disabled}
      startIcon={leftIcon && <FontAwesomeIcon icon={leftIcon} />}
      endIcon={rightIcon && <FontAwesomeIcon icon={rightIcon} />}
      onClick={onClick}
    >
      {name}
    </MUIButton>
  );
}

export default withStyles(styles)(withIntl(Button));
