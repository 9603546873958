/**
 *
 * App Component
 *
 */
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";
import Main from "../../scenes/Main/index";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";

const HomePage = React.lazy(() => {
  return import("../HomePage");
});

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  loading: boolean;

  token: string;

  configureUserDetails: (token: String) => void;

  getNotifications: Function;

  load: () => void;
};

function App(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    loading,
    token,
    load,
    configureUserDetails,
    getNotifications,
  } = props;
  useEffect(() => {
    load();
  }, []);

  // setting user details every time token changes
  useEffect(() => {
    configureUserDetails(token);
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      getNotifications();
    }, 60000);
  }, []);

  return (
    <>
      <Helmet defaultTitle="MadFan">
        <meta name="description" content="Mad fan by Codebucket" />
      </Helmet>
      {loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props: any) => <HomePage {...props} />}
            />
            <Route
              path="/referral/:referral_id"
              exact
              render={(props: any) => <HomePage {...props} />}
            />
            <Route path="*" render={(props: any) => <Main {...props} />} />
          </Switch>
        </Suspense>
      )}
    </>
  );
}

export default withStyles(styles)(withIntl(App));
