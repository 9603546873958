/**
 * ImageCardHover Messages
 *
 * This contains all the text for the ImageCardHover Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.ImageCardHover";

export default defineMessages({
  bookNow: {
    id: `${scope}.bookNow`,
    defaultMessage: "BOOK NOW",
  },
  viewProfile: {
    id: `${scope}.viewProfile`,
    defaultMessage: "VIEW PROFILE",
  },
});
