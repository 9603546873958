import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the myBookings state domain
 */
const selectMyBookingsDomain = (state: State) =>
  state.myBookingsReducer || initialState;

/**
 * Custom selectors to the myBookings state
 */
export const selectMyBookings = createSelector(
  selectMyBookingsDomain,
  (substate) => substate
);

export const selectLoading: (arg0: State) => boolean = createSelector(
  selectMyBookings,
  (mb) => mb.loading
);

export const selectOrders: (arg0: State) => any = createSelector(
  selectMyBookings,
  (mb) => mb.orders
);
