import produce from "immer";
import * as ACTION_TYPES from "./constants";

export type Influencer = {
    profile_photo: string;
    profile_photo_small: string;
    tags: string[];
    name: string;
    category: string[];
    rate: string;
    id: string;
    language: string[];
    gender: string;
    familiar_for: string[];
  };
export type HomePage = {
    loading: boolean;
    categories: { id: number; name: string }[];
    influencers: Influencer[];
};

export const initialState: HomePage = {
    loading: false,
    categories: [],
    influencers: [],
  };


  const homePageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.ON_LOAD: {
        draft.loading = true;
        break;
      }
      case ACTION_TYPES.SET_LOADING: {
        draft.loading = action.payload;
        break;
      }
      case ACTION_TYPES.SET_CATEGORIES: {
        draft.categories = action.payload;
        break;
      }
      case ACTION_TYPES.SET_INFLUENCERS: {
        draft.influencers = action.payload;
        break;
      }
    }
  });


export default homePageReducer;