import "../../index.css";

const styles = (theme: any) => ({
  wrapper: {
    // maxWidth: 1032,
    // maxHeight: 203,
    borderRadius: 10,
    border: "solid 1px #fdb428",
    backgroundColor: "#fff6e5",
    display: "flex",
    fontFamily: "Poppins",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    paddingRight: 30,
  },
  leftwrapper: {
    flexGrow: 5,
    marginLeft: "55px",
    marginTop: "20px",
    textAlign: "left",
    letterSpacing: "normal",
    fontStyle: "normal",
  },
  rightwrapper: {
    flexGrow: 0,
    // maxHeight: "177px",
    borderRadius: 10,
    border: "solid 1px #fdb428",
    backgroundColor: "#ffffff",
    marginTop: 12,
    marginBottom: 12,
  },
  input: {
    minWidth: "353px",
    minHeight: "50px",
    borderRadius: "8px",
    border: "solid 1px #d2d2d2",
    backgroundColor: "#ffffff",
    opacity: 0.76,
    lineHeight: 1.56,
    fontSize: 16,
    paddingLeft: 15,
  },
  header: {
    fontSize: "35px",
    fontWeight: "bold",
    lineHeight: 1.51,
  },
  details: {
    fontSize: 21,
    fontWeight: 500,
    lineHeight: 1.48,
    marginTop: 0,
  },
  invites: {
    fontSize: 21,
    fontWeight: 500,
    lineHeight: 1.48,
    marginTop: 0,
  },
  copytag: {
    fontSize: 21,
    fontWeight: 500,
    textAlign: "left",
    color: "#e90041",
    marginLeft: 20,
    fontFamily: "Poppins",
    cursor: "pointer",
  },
  insidewrapper: {
    margin: "24px 31px 15px 40px",
  },
  icon: {
    marginRight: 16,
    fontSize: 28,
    lineHeight: "1.36",
  },
  googlecontact: {
    marginTop: "30px",
    color: "blue",
    cursor: "pointer",
  },
  "@media (max-width: 967px)": {
    wrapper: {
      flexDirection: "column",
      maxHeight: "initial",
    },
    rightwrapper: {
      marginLeft: 25,
    },
    tabwrapper: {
      display: "flex",
    },
    invitewrapper: {
      width: "70%",
    },
    googlecontact: {
      marginTop: "0px",
    },
  },
  "@media (max-width: 600px)": {
    leftwrapper: {
      marginLeft: 20,
    },
    rightwrapper: {
      marginLeft: 20,
    },

    header: {
      fontSize: 20,
    },
    details: {
      fontSize: 12,
    },
    invites: {
      fontSize: 12,
      fontWeight: "bold",
    },
    input: {
      minWidth: 240,
      fontSize: 12,
      minHeight: "30px !important",
    },
    copytag: {
      fontSize: 9,
      marginTop: 7,
    },
    icon: {
      fontSize: 19,
      marginRight: 14,
    },
    tabwrapper: {
      display: "flex",
      flexDirection: "row",
    },
    invitewrapper: {
      width: "34%",
      marginRight: 60,
    },
    googlecontact: {
      marginTop: "12px 5px",
      fontSize: 11,
    },
    insidewrapper: {
      margin: "17px",
    },
  },
  "@media (max-width: 400px)": {
    leftwrapper: {
      marginLeft: 10,
    },
    rightwrapper: {
      marginLeft: 10,
    },

    header: {
      fontSize: 18,
    },
    details: {
      fontSize: 12,
      fontWeight: "bold",
    },
    invites: {
      fontSize: 12,
    },
    input: {
      minWidth: 155,
      fontSize: 9,
      minHeight: "30px !important",
    },
    copytag: {
      fontSize: 9,
      marginTop: 7,
    },
    icon: {
      fontSize: 19,
      marginRight: 12,
    },
    tabwrapper: {
      display: "flex",
      flexDirection: "row",
    },
    invitewrapper: {
      width: "37%",
      marginRight: 6,
    },
    googlecontact: {
      marginTop: "12px 5px",
      fontSize: 11,
    },
    insidewrapper: {
      margin: "17px",
    },
  },
});

export default styles;
