const styles = (theme: any) => ({
  container: {
    width: 400,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    padding: 36,
    fontFamily: "Poppins",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },
  header: { fontWeight: "bold", fontSize: 24 },
  box: { marginTop: 12, width: "100%" },
  wrapper: { marginTop: 20 },
  inputField: { fontSize: 20, borderRadius: 8, paddingTop: 0 },
  textField: { width: "100%" },
  social: {
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: 20,
    alignSelf: "baseline",
    marginTop: 48,
  },
  error: {
    color: "#bf1650",
  },
  "@media (max-width: 600px)": {
    container: {
      width: 300,
      padding: 20,
    }
  }
});

export default styles;
