const styles = (theme: any) => ({
  wrapper: {
    maxWidth: "500px",
    borderRadius: "20px",
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    backgroundColor: "#ffffff",
    paddingTop: 20,
    minWidth: 400,
  },

  listwrapper: {
    height: "400px",
    overflowX: "hidden",
    width: "100%",
    paddingRight: 16,
    marginTop: 16,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(233,0,65,0.5)",
      webkitBoxShadow: "inset 0 0 6px rgba(233,0,65,0.5)",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: " rgba(233,0,65,0.8)",
      // outline: "1px solid rgba(233,0,65,1)",
      borderRadius: 10,
    },
  },
  name: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: "left",
  },
  phone: {
    fontSize: 15,
    fontWeight: "normal",
    textAlign: "left",
  },
  close: {
    justifyContent: "flex-end",
    display: "flex",
    paddingTop: 20,
    paddingRight: 10,
  },
  buttontype: {
    borderRadius: 10,
    marginBottom: 30,
    width: "100%",
    "&:hover": {
      backgroundColor: "#e90041",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  check: {
    marginRight: -30,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
  },
  noofcontacts: {
    borderBottom: "solid 1px #707070",
    color: "#707070",
    lineHeight: 1.5,
    fontSize: 18,
    paddingBottom: 20,
  },
  // image: {
  //   marginLeft: "12px",
  //   paddingTop: "13px",
  //   color: "blue ",
  // },
  imagewrapper: {
    height: "60px",
    width: "60px",
    backgroundColor: "#fdb428",
    borderRadius: "50%",
  },
  controls: {
    display: "flex",
    flexFlow: "column",
  },
  labelPlacementStart: {
    flexFlow: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 0,
  },
  label: {
    fontSize: 18,
  },
  checkboxChecked: {
    color: "black",
  },
  "@media (max-width: 967px)": {
    header: {
      // textAlign: "left",
      fontSize: 18,
    },
    noofcontacts: {
      // textAlign: "left",
      fontSize: 16,
    },
    imagewrapper: {
      height: 36,
      width: 37,
    },
    // icon: {
    //   fontSize: 30,
    // },
    // image: {
    //   marginLeft: 4,
    //   paddingTop: 2,
    // },
    container: {
      flexWrap: "nowrap !important",
    },
    centerwrapper: {
      paddingTop: "3px !important",
    },
    name: {
      fontSize: 15,
      marginBottom: 0,
    },
    phone: {
      fontSize: 12,
    },
    buttontype: {
      width: "100%",
      fontSize: 18,
      "&:hover": {
        backgroundColor: "#e90041",
      },
    },
  },
  "@media (max-width: 600px)": {
    header: {
      textAlign: "left",
      fontSize: 18,
    },
    noofcontacts: {
      textAlign: "left",
      fontSize: 16,
    },
    imagewrapper: {
      height: 36,
      width: 37,
    },
    // icon: {
    //   fontSize: 30,
    // },
    // image: {
    //   marginLeft: 4,
    //   paddingTop: 2,
    // },
    container: {
      flexWrap: "nowrap !important",
    },
    centerwrapper: {
      paddingTop: "3px !important",
    },
    name: {
      fontSize: 15,
      marginBottom: 0,
    },
    phone: {
      fontSize: 12,
    },
    buttontype: {
      width: "100%",
      fontSize: 18,
      "&:hover": {
        backgroundColor: "#e90041",
      },
    },
  },
  "@media (max-width: 400px)": {
    header: {
      textAlign: "left",
      fontSize: 18,
    },
    noofcontacts: {
      textAlign: "left",
      fontSize: 16,
    },
    imagewrapper: {
      height: 36,
      width: 37,
    },
    // icon: {
    //   fontSize: 30,
    // },
    // image: {
    //   marginLeft: 4,
    //   paddingTop: 2,
    // },
    container: {
      flexWrap: "nowrap !important",
    },
    centerwrapper: {
      paddingTop: "3px !important",
    },
    name: {
      fontSize: 15,
      marginBottom: 0,
    },
    phone: {
      fontSize: 12,
    },
    buttontype: {
      width: "100%",
      fontSize: 18,
      "&:hover": {
        backgroundColor: "#e90041",
      },
    },
  },
});

export default styles;
