import * as actionTypes from '../action.js';
import axios from '../../shared/axios-details';

export const authStart = () =>{
    return {
        type:actionTypes.AUTH_START
    };
};

export const authSuccess = (token, name) =>{
    return {
        type:actionTypes.AUTH_SUCCESS,
        token:token,
        name:name,
    }
}


export const authFailure = (error) =>{
    return {
        type:actionTypes.AUTH_FAILED,
        error:error
    }
}

export const logout=() =>{
    localStorage.removeItem('madFanClientToken');
    localStorage.removeItem('madFanClientName');
    return {
        type:actionTypes.INIT_LOGOUT
    }
}


export const auth = (token, name) =>{
    return dispatch =>{
        dispatch(authStart());

        localStorage.setItem('madFanClientToken', token);
        localStorage.setItem('madFanClientName', name);

        dispatch(authSuccess(token, name));
      

    };
};

// export const auth_oauth = (email, name, profile) =>{
//     return dispatch =>{
//         dispatch(authStart());
//         const authData={
//             email:email,
//             name:name,
//             profile:profile,
//         }
//         //console.log(authData);
//         axios.post('/login_oauth.php',authData)
//         .then(res => {
//             if(res.data.status){
//                 console.log(res.data);
//                 //save to local
//                 localStorage.setItem('madFanClientToken',res.data.data.token);
//                 localStorage.setItem('madFanClientName',res.data.data.name);
//                 //dispatch
//                 dispatch(authSuccess(res.data.data.token, res.data.data.name));
//             }
//             else{
//                 dispatch(authFailure(res.data.message));
//             }
//             console.log(res.data);
//         }).catch(err =>{
//             console.log(err);
//             dispatch(authFailure("Server Error"));
//         });
//     };
// }


export const setAuthRedirect= (path) =>{
    return{
        type:actionTypes.SET_AUTH_REDIRECT_PATH,
        path:path
    }
}

export const authCheckStatus= () =>{
    return dispatch =>{
        const madFanClientToken = localStorage.getItem('madFanClientToken');
        const madFanClientName =localStorage.getItem('madFanClientName'); 
        if(!madFanClientToken){
            dispatch(logout());
        }
        else{
            dispatch(authSuccess(madFanClientToken, madFanClientName));
        }
    }
}

export  default{
    auth, logout, setAuthRedirect, authCheckStatus
} ;