const styles = (theme: any) => ({
  container: {
    fontFamily: "Poppins",
  },
  bookVideo: {
    fontFamily: "Poppins",
    fontSize: 30,
    fontWeight: "bold",
  },
  awesome: {
    fontFamily: "Poppins",
    fontSize: 18,
    opacity: 0.7,
  },
  card: {
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    borderRadius: 20,
    padding: 28,
    marginTop: 42,
  },
  sno: {
    height: 42,
    width: 42,
    borderRadius: "50%",
    backgroundColor: "#000000",
    color: "#ffffff",
    fontSize: 20,
    fontFamily: "Poppins",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    border: "solid 1px #707070",
  },
  table: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  smallCol: { alignSelf: "flex-start" },
  bigCol: { marginLeft: 20, width: "100%" },
  stepMsg: { fontSize: 20, fontWeight: "bold", marginBottom: 30 },
  selfButton: { fontSize: 20, textTransform: "none" },
  giftButton: { fontSize: 20, marginLeft: 16, textTransform: "none" },
  toFrom: { display: "flex", flexFlow: "row", marginTop: 30 },
  label: { fontSize: 16, fontWeight: "bold", marginBottom: 8 },
  wrapper: {
    display: "flex",
    flexFlow: "column",
    flex: 1,
    marginRight: 32,
    "&:last-child": { marginRight: 0 },
  },
  inputField: { borderRadius: 10 },
  textField: {
    padding: '14px 12px'
  },
  instrn: {
    marginTop: 30,
  },
  hrLine: {
    borderTop: "1px dashed #707070",
    marginTop: 68,
    marginBottom: 68,
  },
  stepSubMsg: {
    fontSize: 18,
    opacity: 0.7,
    fontWeight: "normal",
  },
  coupon: {
    padding: "16px 28px",
    backgroundColor: "#fff6e5",
    marginTop: 58,
    borderRadius: 10,
    border: "1px solid #fff6e5",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  couponButton: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff6e5",
    },
  },
  tagImage: {
    width: 36,
    height: 36,
  },
  apply: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 20,
  },
  ifYouHave: {
    fontWeight: "normal",
  },
  leftIcon: {
    marginLeft: 20,
  },
  payment: {
    display: "flex",
    flexWrap: "wrap",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  methodImg: {},
  bookButton: {
    marginTop: 64,
    padding: "28px 46px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  wrap: {
    display: "flex",
    fontSize: 20,
  },
  price: {
    // marginLeft: 28,
  },
  arrowImg: {
    width: 38,
    height: 26,
  },
  deliveryContainer: {
    padding: "26px 36px",
    backgroundColor: "#fff6e5",
    marginTop: 50,
    borderRadius: 10,
  },
  deliveryHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 22,
  },
  row: {
    display: "flex",
    alignItems: "baseline",
  },
  stapSmallCol: { alignSelf: "flex-start" },
  stepBigCol: { marginLeft: 10, width: "100%", fontSize: 16 },
  stepSno: {
    height: 18,
    width: 18,
    borderRadius: "50%",
    backgroundColor: "#fdb428",
    color: "#000000",
    fontSize: 9,
    fontWeight: "bold",
    fontFamily: "Poppins",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  error: {
    color: "#bf1650",
  },
  coinImg: {
    width: 22,
    height: 22,
    alignSelf: "center",
    marginLeft: 20,
    marginRight: 8,
  },
  "@media (max-width: 967px)": {},
  "@media (max-width: 600px)": {
    bookVideo: {
      fontSize: 22,
    },
    awesome: {
      fontSize: 14,
      marginTop: 12,
    },
    sno: {
      height: 20,
      width: 20,
      fontSize: 10,
      fontWeight: "bold",
      // marginTop: 4,
    },
    card: {
      "box-shadow": "none",
      borderRadius: "none",
      padding: 0,
      marginTop: 26,
    },
    bigCol: { marginLeft: 10 },
    stepMsg: { fontSize: 12, marginBottom: 16 },
    selfButton: { fontSize: 12, borderRadius: 3 },
    giftButton: { fontSize: 12, borderRadius: 3 },
    toFrom: { marginTop: 20 },
    label: { fontSize: 10 },
    wrapper: { marginRight: 16 },
    inputField: { fontSize: 10, borderRadius: 3 },
    instrn: {
      marginTop: 20,
    },
    hrLine: {
      marginTop: 28,
      marginBottom: 28,
    },
    stepSubMsg: {
      fontSize: 14,
    },
    coupon: {
      padding: "8px 14px",
      marginTop: 24,
      borderRadius: 3,
    },
    tagImage: {
      width: 18,
      height: 18,
    },
    apply: {
      fontSize: 10,
      marginLeft: 10,
    },
    leftIcon: {
      marginLeft: 10,
      width: 8,
      height: 8,
    },
    methodImg: {
      height: 12,
      width: "auto",
    },
    bookButton: {
      marginTop: 40,
      padding: "14px 22px",
      borderRadius: 3,
    },
    wrap: {
      fontSize: 10,
      display: "flex",
      alignItems: "center",
    },
    price: {
      // marginLeft: 28,
    },
    arrowImg: {
      width: 18,
      height: 12,
    },
    deliveryContainer: {
      padding: "12px 18px",
      marginTop: 24,
      borderRadius: 3,
    },
    deliveryHeader: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 12,
    },
    stepBigCol: { marginLeft: 6, fontSize: 10 },
    stepSno: {
      height: 18,
      width: 18,
    },
    error: {
      fontSize: 10,
    },
  },
  "@media (max-width: 468px)": {},
});

export default styles;
