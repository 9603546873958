import { createSelector } from "reselect";
import { Influencer } from "../SearchCeleb/reducer";
import { initialState, Question } from "./reducer";

/**
 * Direct selector to the quiz state domain
 */
const selectQuizDomain = (state: State) => state.quizReducer || initialState;

/**
 * Custom selectors to the quiz state
 */
export const selectQuiz = createSelector(
  selectQuizDomain,
  (substate) => substate
);

export const selectLoading: (arg0: State) => boolean = createSelector(
  selectQuiz,
  (quiz) => quiz.loading
);

export const selectInfluencers: (arg0: State) => Influencer[] = createSelector(
  selectQuiz,
  (quiz) => quiz.influencers
);

export const selectQuizQuestions: (arg0: State) => Question[] = createSelector(
  selectQuiz,
  (quiz) => quiz.quizQuestions
);
export const selectQuizLoading: (arg0: State) => boolean = createSelector(
  selectQuiz,
  (quiz) => quiz.quizLoading
);
export const selectStartQuiz: (arg0: State) => boolean = createSelector(
  selectQuiz,
  (quiz) => quiz.startQuiz
);

export const selectCoinEarned: (arg0: State) => boolean = createSelector(
  selectQuiz,
  (quiz) => quiz.singleCoinEarned
);
