/**
 *
 * UserProfile constants
 *
 */

const scene = "app/UserProfile";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const ON_REDEEM_GIFT_CARD = `${scene}/ON_REDEEM_GIFT_CARD`;
export const SET_REDEEM_LOADING = `${scene}/SET_REDEEM_LOADING`;
export const SET_REDEEM_STATUS = `${scene}/SET_REDEEM_STATUS`;
export const SEND_MAILS = `${scene}/SEND_MAILS`;
export const SET_SENDING_MAIL_SUCCESS = `${scene}/SET_SENDING_MAIL_SUCCESS`;

export const BREAKING_POINT = 967;
export const GIFT_CARD_AMOUNT  = 10;
