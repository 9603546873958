const styles = (theme: any) => ({
  container: {
    maxWidth: 236,
    borderRadius: 20,
    position: "relative" as "relative",
  },
  smallContainer: {
    maxWidth: 176,
  },
  media: {
    height: 268,
    "object-fit": "cover",
    "object-position": "top",
    width: 236,
  },
  smallMedia: {
    height: 200,
    width: 176,
    "object-fit": "cover",
    "object-position": "top",
  },
  priceButton: {
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    margin: 16,
    backgroundColor: "#f3f3f3",
    borderRadius: 30,
    fontFamily: "Poppins",
    color: "#e90041",
    fontSize: 16,
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  noOfCoin: { marginLeft: 8 },
  coinImg: { width: 22, height: 22 },
  cardActionRoot: {
    padding: 0,
  },
  cardContent: {
    width: "-webkit-fill-available",
    position: "absolute" as "absolute",
    bottom: 0,
    left: 0,
  },
  celebName: {
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "bold",
  },
  celebNameSmall: {
    fontSize: 14,
  },
  famousFor: {
    display: "inline-block",
    width: "calc(100%)",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  famousForSmall: { fontSize: 10 },
  "@media (max-width: 1367px)": {
    media: {
      height: 232,
      width: 185,
    }
  },
  "@media (max-width: 600px)": {
    smallMedia: {
      height: 170,
      width: 170,
    }
  }
});

export default styles;
