import produce from "immer";
import * as ACTION_TYPES from "./constants";

export type Order = {
  profile_photo: string;
  id: string;
  video_to: string;
  name: string;
  occasion: string;
  by_when: string;
  payment_amount: string;
  order_status: number;
  order_date: string;
  order_id: string;
  content: string;
  video_link: string;
  famousFor: string;
};

export type MyBookings = {
  loading: boolean;
  orders: Order[];
};

export const initialState: MyBookings = {
  loading: false,
  orders: [],
};

const myBookingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.DEFAULT_ACTION:
        break;
      case ACTION_TYPES.LOAD:
        draft.loading = true;
        break;
      case ACTION_TYPES.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ACTION_TYPES.SET_ORDERS:
        draft.orders = action.payload;
        break;
    }
  });

export default myBookingsReducer;
