/**
 *
 * Challenge constants
 *
 */

const scene = "app/Challenge";
export const DEFAULT_ACTION = `${scene}/DEFAULT_ACTION`;
export const LOAD = `${scene}/LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_INFLUENCERS = `${scene}/SET_INFLUENCERS`;
export const ON_LOAD_CHALLENGE = `${scene}/ON_LOAD_CHALLENGE`;
export const SET_CHALLENGES = `${scene}/SET_CHALLENGES`;
export const SET_CHALLENGE_LOADING = `${scene}/SET_CHALLENGE_LOADING`;
export const ON_CHALLENGE_ACCEPTED = `${scene}/ON_CHALLENGE_ACCEPTED`;
export const ACCEPT_CHALLENGE = `${scene}/ACCEPT_CHALLENGE`;
export const SET_START_CHALLENGE = `${scene}/SET_START_CHALLENGE`;
