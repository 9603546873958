import { createSelector } from "reselect";
import { initialState, UserDetails, Notification } from "./reducer";

/**
 * Direct selector to the app state domain
 */
const selectAppDomain = (state: State) => state.appReducer || initialState;

/**
 * Custom selectors to the app state
 */
export const selectApp = createSelector(
  selectAppDomain,
  (substate) => substate
);

export const selectLoading: (arg0: State) => boolean = createSelector(
  selectApp,
  (app) => app.loading
);

export const selectToken = (state) => {
  if (state.auth.token) {
    return state.auth.token;
  }
  return null;
};

export const selectIsLoggedIn: (arg0: any) => boolean = createSelector(
  selectToken,
  (token) => {
    if (token) {
      return true;
    } else {
      if (localStorage.getItem("madFanClientToken")) {
        return true;
      }
    }
    return false;
  }
);

export const selectUserDetails: (arg0: State) => UserDetails = createSelector(
  selectApp,
  (app) => app.userDetails
);

export const selectTotalCoins: (arg0: State) => UserDetails = createSelector(
  selectApp,
  (app) => app.userDetails.coin
);

export const selectNotifications: (
  arg0: State
) => Notification[] = createSelector(selectApp, (app) => app.notifications);
