/**
 *
 * ReedemMadCoins Component
 *
 */
import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import axios from "../../shared/axios-details";



type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    redeemCard?: any;

    allCard: any;


};


function ReedemMadCoins(props: Props) {
    const {
        classes,
        style,
        className,
        formatMessage,
        redeemCard,
        allCard
    } = props;



    const token = useSelector((state) => state.auth.token);

    
    console.log(redeemCard)
    

    const sendMail = (gift_card) => {
        console.log({
            info_type: "2",
            token: token,
            gift_card: gift_card
        });
        axios
            .post("/amazon_show_git_card.php", {
                info_type: "2",
                token: token,
                gift_card: gift_card
            })
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                throw err;
            });
    }

    return (
        <>
            <div className={classes.head}>Gift Cards</div>
            <Grid container >
                {allCard.map((row) => (
                    <Grid container item xs={12} md={4} key={row.number} style={{ marginBottom: 20 }} >
                        <div className={classes.cardBox}>
                            <Grid container item xs={12} className={classes.cardNumber}>
                                {row.number}
                            </Grid>
                            <Grid container >
                                <Grid container item xs={7} className={classes.date}>
                                    {row.created}
                                </Grid>
                                <Grid container item xs={5} className={classes.sendMail} >
                                    <Button variant="contained" onClick={() => sendMail(row.action)} color="secondary">Send Mail</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                ))}
            </Grid>
            {/* <div className={classnames(classes.container, className)} style={style}>



      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Card Number</TableCell>
            <TableCell align="right">Created On</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.number}>
              <TableCell component="th" scope="row"> 
                {row.number}
              </TableCell>
              <TableCell align="right">{row.created}</TableCell>
              <TableCell align="right"><Button variant="contained" onClick={() =>sendMail(row.action)} color="secondary">Send Mail</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div> */}
        </>
    );
}

export default withStyles(styles)(withIntl(ReedemMadCoins));
