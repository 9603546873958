import produce from "immer";
import * as ACTION_TYPES from "./constants";
import { Influencer } from "../SearchCeleb/reducer";

export type ChallengeType = {
  id: number;
  challenge_name: string;
  challenge_desc: string;
  challenge_for: string;
  challenge_content_type: string;
  name: string;
  challenge_demo_video: string;
  profile_photo: string;
  coin: number;
};

export type Challenge = {
  loading: boolean;
  challengeLoading: boolean;
  influencers: Influencer[];
  challenges: ChallengeType[];
  startChallenge: string;
};

export const initialState: Challenge = {
  loading: false,
  challengeLoading: false,
  influencers: [],
  challenges: [],
  startChallenge: "",
};

const challengeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.DEFAULT_ACTION:
        break;
      case ACTION_TYPES.LOAD:
        draft.loading = true;
        break;
      case ACTION_TYPES.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ACTION_TYPES.SET_INFLUENCERS:
        draft.influencers = action.payload;
        break;
      case ACTION_TYPES.ON_LOAD_CHALLENGE:
        draft.challengeLoading = true;
        break;
      case ACTION_TYPES.SET_CHALLENGES:
        draft.challenges = action.payload;
        break;
      case ACTION_TYPES.SET_CHALLENGE_LOADING:
        draft.challengeLoading = action.payload;
        break;
      case ACTION_TYPES.SET_START_CHALLENGE:
        draft.startChallenge = action.payload;
        break;
    }
  });

export default challengeReducer;
