/**
 *
 * PlayQuiz Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import { green } from "@material-ui/core/colors";
import Radio, { RadioProps } from "@material-ui/core/Radio";
type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;
    image: any;
    question: string;
    options: {}[];
    quesNo: number;
    quesLength:any;
    onChange: (option: string) => void;
};
const GreenRadio = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
function PlayQuiz(props: Props) {
    const {
        classes,
        style,
        className,
        formatMessage,
        image,
        question,
        options,
        onChange,
        quesNo,
        quesLength
    } = props;
    const [selectedValue, setSelectedValue] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        onChange(event.target.value);
    };
    return (
        <div className={classes.wrapper}>
            <div className={classes.imagewrapper}>
                <img src={image} className={classes.image} />
                <button className={classes.button}>Q .{quesNo}</button>
            </div>
            <div className={classes.rightwrapper}>
                <div style={{display: 'flex'}}>
                    <div className={classes.question}>{question}</div><span className={classes.questCount}>{"("+quesNo+"/"+quesLength+")"}</span>
                </div>
                <div className={classes.optionwrapper}>
                    {options.map((item, i) => (
                        <div className={classes.option}>
                            <Radio
                                checked={selectedValue === `${item}`}
                                onChange={handleChange}
                                value={item}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": { item } }}
                            />
                            {/* <input type="radio" name="option" value={item}  className={classes.input} /> */}
                            <label className={classes.label}>{item}</label>
                            <br></br>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(withIntl(PlayQuiz));
