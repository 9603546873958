/**
 *
 * WinCoins Component
 *
 */
import React, { Suspense } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { Switch, Route, useHistory } from "react-router-dom";

import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowWidth } from "@react-hook/window-size";
import {
  MEDIA_DEVICE_DESKTOP,
  MEDIA_DEVICE_MOBILE,
  BREAKING_POINT,
} from "../../utils/constants";

import AcceptChallenge from "../../components/AcceptChallenge";
import WinMadCoins from "../../components/WinMadCoins";
import Quiz from "../Quiz";
import Challenge from "../Challenge";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
};

function WinCoins(props: Props) {
  const { classes, style, className, formatMessage } = props;
  const history = useHistory();

  const mediaDevice =
    useWindowWidth() > BREAKING_POINT
      ? MEDIA_DEVICE_DESKTOP
      : MEDIA_DEVICE_MOBILE;

  return (
    <div className={classnames(classes.container, className)} style={style}>
      <Switch>
        <Suspense fallback={() => {}}>
          <Route
            path="/profile/win-coins/quiz"
            render={(componentProps) => <Quiz {...componentProps} />}
          />
          {/* <Route
            path="/profile/win-coins/challenge"
            render={(componentProps) => <Challenge {...componentProps} />}
          /> */}
          <Route
            path="/profile/win-coins"
            exact
            render={(componentProps) => (
              <OptionsCards
                {...props}
                history={history}
                mediaDevice={mediaDevice}
              />
            )}
          />
        </Suspense>
      </Switch>
    </div>
  );
}

function OptionsCards(props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    mediaDevice,
    history,
  } = props;
  return (
    <div className={classes.optionsCards}>
      <div className={classes.header}>
        <div className={classes.winCoins}>
          {mediaDevice === MEDIA_DEVICE_MOBILE && (
            <FontAwesomeIcon icon={faCoins} className={classes.coinIcon} />
          )}
          <span>{formatMessage(messages.winCoins)}</span>
        </div>
        {/* <div className={classes.subTitle}>{}</div> */}
      </div>
      <div className={classes.options}>
        <WinMadCoins
          style={
            mediaDevice === MEDIA_DEVICE_DESKTOP
              ? { marginRight: 40 }
              : { marginBottom: 32 }
          }
          onClick={() => history.push("/profile/win-coins/quiz")}
        />
        {/* <AcceptChallenge
          onClick={() => history.push("/profile/win-coins/challenge")}
        /> */}
      </div>
    </div>
  );
}

export default withStyles(styles)(withIntl(WinCoins));
