/**
 * EditProfile Messages
 *
 * This contains all the text for the EditProfile Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.EditProfile";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Edit Profile",
  },
});
