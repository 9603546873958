/**
 *
 * App actions
 *
 */

import * as ACTION_TYPES from "./constants";

export function defaultAction() {
  return {
    type: ACTION_TYPES.DEFAULT_ACTION,
  };
}
export function load() {
  return {
    type: ACTION_TYPES.LOAD,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  };
}

export function setUserDetails(userDetails: any) {
  return {
    type: ACTION_TYPES.SET_USER_DETAILS,
    payload: userDetails,
  };
}
export function configureUserDetails(token: string) {
  return {
    type: ACTION_TYPES.CONFIGURE_USER_DETAILS,
    payload: token,
  };
}
export function setUserCoins(totalCoins: number) {
  return {
    type: ACTION_TYPES.SET_USER_COINS,
    payload: totalCoins,
  };
}
export function logout() {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
}
export function setNotifications(payload) {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS,
    payload,
  };
}
export function getNotifications() {
  return {
    type: ACTION_TYPES.GET_NOTIFICATIONS,
  };
}
export function editProfile(data: any) {
  return {
    type: ACTION_TYPES.EDIT_PROFILE,
    payload: data,
  };
}
