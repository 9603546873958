/**
 *
 * ProfileCard Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Container, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// import sample from "../../images/sample.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
  name: string;
  details: string;
  reason: string;
  paymentstatus: string;
  date: string;
  delievery: string;
  img: any;
  onNewBooking: () => void;
};

function ProfileCard(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    name,
    details,
    reason,
    date,
    paymentstatus,
    delievery,
    img,
    onNewBooking,
  } = props;

  return (
    <div className={classes.wrapper} style={style}>
      <Container>
        {/* <div className={classes.close}>
          <CloseIcon />
        </div> */}
        <div className={classes.headerwrapper}>
          <div>
            <img className={classes.image} src={img} />
          </div>
          <div className={classes.header}>
            <div className={classes.firsttext}>{name}</div>
            <div className={classes.second}>{details}</div>
          </div>
        </div>
        <div className={classes.labelwrapper}>
          <label className={classes.second}>Booked For:</label>
          <Typography className={classes.innerwrapper}>{reason}</Typography>
        </div>
        <div className={classes.labelwrapper}>
          <label className={classes.second}>Tentative Receiving Date:</label>
          <Typography className={classes.innerwrapper}>{date}</Typography>
        </div>
        <div className={classes.labelwrapper}>
          <label className={classes.second}>Payment Status:</label>
          <Typography className={classes.innerwrapper}>
            {paymentstatus}
          </Typography>
        </div>
        <div className={classes.labelwrapper}>
          <label className={classes.second}>Delivery Status:</label>
          <Typography className={classes.innerwrapper}>{delievery}</Typography>
        </div>

        <div className={classes.button}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.buttontype}
            onClick={onNewBooking}
            fullWidth
          >
            Book for another wishes
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(ProfileCard));
