/**
 *
 * RechargeSection Component
 *
 */
import React, { useState } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CoinAmount from "../CoinAmount/index";
import { Button } from "@material-ui/core";
import arrow from "../../images/arrow.png";


type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  handleRecharge: Function,

  coinAmount: any;

  rechargeManual: any
};

function RechargeSection(props: Props) {
  const { classes, style, className, formatMessage, handleRecharge, coinAmount, rechargeManual } = props;

  const [selected, setSelected] = useState(null);

  const handleSelectedRecharge = (coin: any) => {
    console.log(coin);
    setSelected(coin);
  }

  const submitRecharge = () => {
    if (selected) {
      handleRecharge(selected);
    }
  }
  // console.log(coinAmount);
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.leftWrapper}>
          <div className={classes.header}>
            <span className={classes.index}>1</span>
            <span className={classes.coinheader}>Select Recharge Bundle</span>
            <span className={classes.entermanual} onClick={rechargeManual}>Enter Manual</span>
          </div>
          <div>
            <div className={classes.amountcomp}>
              {
                coinAmount && coinAmount.map((c, i) => (
                  <CoinAmount
                    style={{ marginBottom: 16 }}
                    price={c.price}
                    active={selected === c ? true : false}
                    numberOfCoins={c.mad_coin}
                    onClick={() => handleSelectedRecharge(c)}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div className={classes.rightWrapper}>
          {/* <div className={classes.header}>
          <span className={classes.index}>2</span>
          <span className={classes.coinheader}>Payment Options:</span>
        </div> */}
          <div className={classes.button}>
            <Button
              variant="contained"
              onClick={() => submitRecharge()}
              color="secondary"
              size="large"
              className={classes.btn}
            >
              Recharge
              <img src={arrow} alt="Arrow" className={classes.arrowImg} />
            </Button>
          </div>
        </div>
      </div>


    </>
  );
}

export default withStyles(styles)(withIntl(RechargeSection));
