import { put, call, takeLatest,select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import { selectToken } from "../App/selectors";
import {
  setCategories,
  setLoading,
  setLanguages,
  setInfluencers,
} from "./actions";
import axios from "../../shared/axios-details";

const getFilters = (infoType: string) => {
  return axios
    .post("/filter.php", {
      info_type: infoType,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getInfluencers = (infoType: string, token: any) => {
  return axios
    .post("/influencer.php", {
      token:token,
      info_type: infoType,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

function* onLoad() {
  try {
    const categories = yield call(getFilters, "1");
    if (categories.status) {
      yield put(setCategories(categories.data));
    }
    const languages = yield call(getFilters, "2");
    if (languages.status) {
      yield put(setLanguages(languages.data));
    }
    let token = yield select(selectToken);
    const influencers = yield call(getInfluencers, "1", token);
    if (influencers.status) {
      yield put(setInfluencers(influencers.data));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

export function* searchCelebSaga() {
  yield takeLatest(ACTION_TYPES.ON_LOAD, onLoad);
}
