/**
 *
 * UserProfile
 *
 */
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";

import Layout from "./layout";
import reducer from "./reducer";
import { selectUserDetails, selectIsLoggedIn } from "./../App/selectors";

import { logout, editProfile } from "../App/actions";
import { onRedeemGiftCard, sendMails, setSendingMailSuccess } from "./actions";
import {
  selectRedeemLoading,
  selectRedeemStatus,
  selectSendingMailSuccess,
} from "./selectors";

const mapStateToProps = createStructuredSelector({
  userDetails: selectUserDetails,
  isLoggedIn: selectIsLoggedIn,
  redeemLoading: selectRedeemLoading,
  redeemStatus: selectRedeemStatus,
  sendingMailSuccess: selectSendingMailSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logout, editProfile, onRedeemGiftCard, sendMails, setSendingMailSuccess },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);
