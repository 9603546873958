const styles = (theme: any) => ({
  container: {
    fontFamily: "Poppins",
    height: "100%",
  },
  card: {
    borderRadius: 14,
    "box-shadow": "0 3px 34px 0 rgba(233, 0, 65, 0.09)",
    maxWidth: 300,
  },
  redBox: {
    height: 140,
    backgroundColor: "#e90041",
    borderRadius: 14,
    display: "flex",
  },
  dp: {
    width: 140,
    height: 140,
    borderRadius: "50%",
    border: "5px solid #fff",
    margin: "auto",
    marginTop: 70,
  },
  wrapper: {
    marginTop: 90,
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
  },
  name: {
    fontWeight: "bold",
    fontSize: 24,
  },
  level: {
    fontSize: 18,
    color: "#211f1f",
  },
  editButton: {
    backgroundColor: "rgba(253, 180, 40, 0.09)",
    color: "#e90041",
    marginTop: 8,
    fontSize: 12,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 10,
  },
  editIcon: {
    fontSize: 12,
  },
  links: {
    marginTop: 36,
    display: "flex",
    flexFlow: "column",
  },
  link: {
    marginTop: 18,
    "&:first-child": {
      marginTop: 0,
    },
  },
  topLinks: {
    display: "flex",
    flexFlow: "column",
  },
  bottomLinks: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 44,
    marginTop: 96,
  },
  drawer: { maxWidth: 414 },
  cutIcon: {
    display: "flex",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  dpSmall: {
    width: 82,
    height: 82,
    borderRadius: "50%",
    marginLeft: 22,
  },
  wrap: {
    display: "flex",
    alignItems: "center",
  },
  box: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    marginLeft: 16,
  },
  nameDrawer: {
    fontWeight: "bold",
    fontSize: 18,
  },
  levelDrawer: {
    fontSize: 12,
    color: "#211f1f",
  },
  editButtonDrawer: {
    backgroundColor: "rgba(253, 180, 40, 0.09)",
    color: "#e90041",
    marginTop: 8,
    fontSize: 8,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 4,
  },
});

export default styles;
