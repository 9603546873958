import * as actionTypes from '../action';
import { updateObj } from '../../shared/utility';

const initialState ={
    token:null,
    name:null,
    error:null,
    loading:false,
    authRedirect: '/',
}



const reducer = (state = initialState, action) =>{
    switch (action.type){
        case actionTypes.AUTH_START:
            return updateObj(state, {error:null, loading:true});
        case actionTypes.AUTH_SUCCESS:
            return updateObj(state, {token:action.token, name:action.name,error:null,loading:false});
        case actionTypes.AUTH_FAILED:
            return updateObj(state, {error:action.error, loading:false});
        case actionTypes.INIT_LOGOUT:
            return updateObj(state, {token:null,name:null});
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return updateObj(state, {authRedirect: action.path});
        default:
            return state;
    }
}

export default reducer;