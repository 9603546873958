/**
 *
 * EarnedCoin Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import star from "../../images/star.png";
import Button from "@material-ui/core/Button";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  message?: string;

  buttonName: string;

  onClick: () => void;
};

function EarnedCoin(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    buttonName,
    message,
    onClick,
  } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.imagewrapper}>
        <img src={star} className={classes.image} />
      </div>
      <div className={classes.header}>{message}</div>
      {/* <div className={classes.buttonwrapper}> */}
      <Button className={classes.button} onClick={onClick}>
        {buttonName}
      </Button>
      {/* </div> */}
    </div>
  );
}

export default withStyles(styles)(withIntl(EarnedCoin));
