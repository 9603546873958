const styles = (theme: any) => ({
  wrapper: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Poppins",
    // padding: 60,
  },
  header: {
    fontSize: 41,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
  },

  button: {
    fontSize: 24,
    fontWeight: 500,
    color: "#e90041",
    paddingTop: 10,
    backgroundColor: "#FFC0CB",
    width: 183,
    height: 60,
    borderRadius: 13,
    margin: "auto",
  },
  imagewrapper: {
    height: 150,
    width: 150,
    margin: "auto",
  },
  image: {
    height: "100%",
    widht: "100%",
  },

  "@media (max-width: 600px)": {
    header: {
      fontSize: 30,
      fontWeight: 500,
      marginTop: 30,
      marginBottom: 30,
    },
    button: {
      fontSize: 20,
      fontWeight: 500,
      // height: 40,
      color: "#e90041 ",
      backgroundColor: "#FFC0CB",
      // width: 100,
      borderRadius: 13,
    },
  },
  "@media (max-width: 400px)": {
    header: {
      fontSize: 20,
      fontWeight: 500,
      marginTop: 30,
      marginBottom: 30,
    },

    button: {
      fontSize: 15,
      fontWeight: 500,
      height: 40,
      color: "#e90041 ",
      backgroundColor: "#FFC0CB",
      width: 80,
      borderRadius: 13,
    },
  },
});

export default styles;
