import { styled } from "@material-ui/core/styles";
const styles = (theme: any) => ({
  wrapper: {
    maxWidth: "500px",
    borderRadius: "20",
    // boxShadow: '0 3px 26px 0 rgba(233, 0, 65, 0.1)',
    backgroundColor: "#ffffff",
  },

  listwrapper: {
    // height: "700px",
    // overflow: "scroll",
  },
  header: {
    marginLeft: "20px",
    padding: "34px 2px",
    borderBottom: "solid 1px #707070",
  },
  image: {
    marginLeft: "12px",
    paddingTop: "13px",
    color: "blue ",
  },
  imagewrapper: {
    height: "60px",
    width: "60px",
    backgroundColor: "rgb(253,180,40,0.12)",
    borderRadius: "50%",
  },
  bookingwrapper: {
    height: 60,
    width: 60,
    backgroundColor: "rgb(7,217,126,0.12)",
    borderRadius: "50%",
  },
  mediawrapper: {
    height: 60,
    width: 60,
    backgroundColor: "rgb(86,209,234,0.12)",
    borderRadius: "50%",
  },
  name: {
    fontWeight: 600,
  },
  "@media (max-width: 967px)": {
    header: {
      textAlign: "left",
      fontSize: 18,
    },
    noofcontacts: {
      textAlign: "left",
      fontSize: 16,
    },
    imagewrapper: {
      height: 50,
      width: 51,
      backgroundColor: "rgb(253,180,40,0.12)",
    },
    bookingwrapper: {
      height: 50,
      width: 51,
      backgroundColor: "rgb(7,217,126,0.12)",
    },
    mediawrapper: {
      height: 50,
      width: 51,
      backgroundColor: "rgb(86,209,234,0.12)",
    },
    image: {
      marginLeft: 10,
      paddingTop: 10,
    },
    icon: {
      fontSize: 30,
    },

    container: {
      flexWrap: "nowrap !important",
    },
    centerwrapper: {
      paddingTop: "6px !important",
      paddingLeft: "0px !important",
    },
    name: {
      fontSize: 15,
      marginBottom: 0,
      fontWeight: 600,
    },
    details: {
      fontSize: 12,
    },
    buttontype: {
      width: "100%",
      fontSize: 18,
    },
  },
  "@media (max-width: 600px)": {
    header: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    name: {
      fontSize: 15,
      marginBottom: 0,
      fontWeight: 600,
    },
    details: {
      fontSize: 12,
    },
    time: {
      fontSize: 15,
    },
    imagewrapper: {
      height: 45,
      width: 45,
      backgroundColor: "rgb(253,180,40,0.12)",
    },
    bookingwrapper: {
      height: 45,
      width: 45,
      backgroundColor: "rgb(7,217,126,0.12)",
    },
    mediawrapper: {
      height: 45,
      width: 45,
      backgroundColor: "rgb(86,209,234,0.12)",
      borderRadius: "50%",
    },
    image: {
      marginLeft: "8px",
      paddingTop: "8px",
    },
  },
  "@media (max-width: 400px)": {
    header: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    name: {
      fontSize: 13,
      marginBottom: 0,
      fontWeight: 600,
    },
    details: {
      fontSize: 12,
    },
    time: {
      fontSize: 10,
    },
    containerwrapper: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
});

export default styles;
