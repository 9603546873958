const styles = (theme: any) => ({
  container: {
    width: "95%",
    margin: "auto",
    display: "flex",
    marginTop: 26,
    paddingBottom: 100,
  },
  userProfileCard: { minWidth: 300, marginRight: 36, boxSizing: "content-box" },
  wrapper: { display: "flex", flexFlow: "column", width: "100%" },
  referAndEarn: {},
  content: { marginTop: 48 },
  settings: {},
  head: {
    fontSize: 28,
    fontWeight: 600,
  },
  box: {
    padding: "20px 36px",
    marginTop: 24,
    borderRadius: 20,
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    display: "flex",
    justifyContent: "space-between",
    aliginItems: "flex-start",
  },
  wrap: {},
  title: { fontSize: 20, fontWeight: 600 },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "rgba(0,0,0,0.6)",
    marginTop: 8,
  },
  "@media (max-width: 967px)": {
    head: {
      fontSize: 20,
      fontWeight: 600,
    },
    title: { fontSize: 16 },
    subtitle: {
      fontSize: 12,
    },
  },
});

export default styles;
