const styles = (theme: any) => ({
  wrapper: {
    borderRadius: 10,
    backgroundColor: "#f3f3f3",
    width: 286,
    height: 62,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: 'Poppins',
    fontSize: 18

  },

  wrapperActive: {
    borderRadius: 10,
    backgroundColor: "#fff6e5",
    width: 286,
    height: 62,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: 'Poppins',
    fontSize: 18,
    border: '1.6px solid #fdbc40',

  },

  discount:{
    fontSize: 12,
    fontWeight: 500,
    color: 'green',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: -15,
  },

  image: {
    width: 28,
    height: 28,
  },
  leftWrapper: {
    display: "flex",
    alignItems: 'center',
    margin: "auto 24px",

  },
  noOfcoin: {
    marginLeft: 12,
  },
  rightWrapper: {
    fontWeight: 600,

    margin: "auto 31px",
  },
});

export default styles;
