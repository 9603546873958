/**
 *
 * SearchCeleb constants
 *
 */

const scene = "app/SearchCeleb";

export const ON_LOAD = `${scene}/ON_LOAD`;
export const SET_LOADING = `${scene}/SET_LOADING`;
export const SET_CATEGORIES = `${scene}/SET_CATEGORIES`;
export const SET_LANGUAGES = `${scene}/SET_LANGUAGES`;
export const SET_INFLUENCERS = `${scene}/SET_INFLUENCERS`;

export const SUCCESS = "Success";
export const BREAKING_POINT = 967;
export const LIMIT_LARGE_CATEGORIES = 6;
