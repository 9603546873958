/**
 *
 * MyBookings
 *
 */
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose, bindActionCreators } from "redux";

import Layout from "./layout";
import reducer from "./reducer";

import { load } from "./actions";
import { selectLoading, selectOrders } from "./selectors";

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  orders: selectOrders,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      load,
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Layout);
