/**
 *
 * NotificationPopUp Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MovieIcon from "@material-ui/icons/Movie";
import StarsIcon from "@material-ui/icons/Stars";
import { Typography, Container, Grid, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import media from "../../images/media.png";
import checkcircle from "../../images/checkcirclenoti.png";
import coin from "../../images/coin.png";

type Props = {
  classes: any;
  className?: any;
  style?: Object;
  unread: number;
  items: {
    title: string;
    time: string;
    name?: string;
    header?:string;
  }[];
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      maxWidth: 500,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      boxShadow: "none",
    },
  })
);

function NotificationPopUp(props: Props) {
  const {
    classes: {
      containerwrapper,
      wrapper,
      listwrapper,
      header,
      image,
      imagewrapper,
      icon,
      centerwrapper,
      time,
      container,
      name,
      details,
      bookingwrapper,
      mediawrapper,
    },
    style,
    unread,
    items,
  } = props;
  const { paper } = useStyles(props);

  return (
    <div className={wrapper} style={style}>
      <Container className={containerwrapper}>
        <Typography align="left" variant="h5" className={header}>
          Notifications
        </Typography>
        <div className={listwrapper}>
          {items.map((item, i) => (
            <Paper className={paper}>
              <Grid container spacing={2} className={container}>
                <Grid item>
                  <div
                    className={
                      item.title == "coin" || item.title == "bonus"
                        ? imagewrapper
                        : item.title == "booking"
                        ? bookingwrapper
                        : mediawrapper
                    }
                  >
                    <Grid item xs={2} className={image}>
                      {item.title == "coin" || item.title == "bonus" ? (
                        <img src={coin} className={icon} />
                      ) : item.title == "booking" ? (
                        <img src={checkcircle} className={icon} />
                      ) : (
                        <img src={media} className={icon} />
                      )}
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs className={centerwrapper}>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={name}
                      >
                        {item.header}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={details}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={time}
                    >
                      {item.time}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(NotificationPopUp));
