import React from 'react';
import { injectIntl } from 'react-intl';
type formatMessageProp = {
  formatMessage: Function;
};
export default function withIntl<P extends {}>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof formatMessageProp>> {
  const wrapped = (props: any) => (
    <WrappedComponent
      {...props}
      formatMessage={(message: any, values?: any) => {
        if (props.intl) {
          return props.intl.formatMessage(message, values);
        }

        return message.defaultMessage;
      }}
    />
  );
  const injected = injectIntl(wrapped);
  injected.displayName = WrappedComponent.displayName || WrappedComponent.name;
  return injected;
}