const styles = (theme: any) => ({
  container: {
    maxWidth: 400,
    height: "auto",
    borderRadius: 20,
    boxShadow: "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    padding: 40,
  },
  circle: {
    justifyContent: "center",
    display: "flex",
  },
  header: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    marginTop: 32,
    marginBottom: 16,
  },
  message: { fontSize: 16, textAlign: "center" },
});

export default styles;
