/**
 *
 * LoginModal Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { TextField } from "@material-ui/core";

import AllActions from "../store/actions";
import axios from "../shared/axios-details";
import OTPModal from "./otpModal";

import google from "../images/google.png";
import fb from "../images/fb.png";
import mobile from "../images/mobile.png";

type Props = {
  // internal
  classes: any;

  // internal
  formatMessage: Function;

  // class applied to top level container
  className?: Object;

  // style applied to top level container
  style?: Object;

  handleClose: any;

  open: boolean;

  referral: any;
};

function LoginModal(props: Props) {
  const { classes, formatMessage, className, style, handleClose, open, referral } = props;

  const [isFacebookLogin, setFacebookLogin] = useState(false);
  const [isGoogleLogin, setGoogleLogin] = useState(false);

  const [otpModal, setOTPModal] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const responseFacebook = (res: any) => {
    console.log(res);
    OAuth(res.email, res.name, res.picture.data.url);
    setFacebookLogin(false);
  };

  const responseGoogle = (res: any) => {
    if (res.profileObj) {
      setGoogleLogin(false);
      OAuth(res.profileObj.email, res.profileObj.name, res.profileObj.imageUrl);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
      if(referral){
        checkReferral();
      }

  },[referral])

  const checkReferral = () => {
    const authData = {
      referral_code: referral,
    };
    axios
      .post("/login_oauth.php", authData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {

        } else {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OAuth = (email: any, name: any, profile: any) => {
    const authData = {
      email: email,
      name: name,
      profile: profile,
      referral_code: error ? null : referral,
    };
    axios
      .post("/login_oauth.php", authData)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          //save to local
          dispatch(
            AllActions.Auth.auth(res.data.data.token, res.data.data.name)
          );
          props.handleClose();
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setStates = () => {
    setLoginStatus(true);
  };

  const handleCloseOTP = () =>{
    console.log("sadsad");
    setLoginStatus(false);
    props.handleClose();
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modal}
        style={{ backgroundColor: "transparent" }}
      >
        <div className={classes.container}>
          <div className={classes.modalBox} style={style}>
            <div className={classes.heading}>
              {formatMessage(messages.heading)}
            </div>

            {/* FaceBook Oauth */}
            <div
              className={classes.loginBox}
              style={{ backgroundColor: "#e84660" }}
              onClick={() => setFacebookLogin(true)}
            >
              <div className={classes.social}>
                <img src={fb} alt="Facebook" className={classes.img} />
              </div>
              Sign up to Facebook
            </div>
            {isFacebookLogin ? (
              <div style={{ display: "none" }}>
                <FacebookLogin
                  appId="367547034232322" //APP ID NOT CREATED YET
                  fields="name,email,picture"
                  callback={responseFacebook}
                  autoLoad
                />
              </div>
            ) : null}

            <div
              className={classes.loginBox}
              style={{ backgroundColor: "#fca53e" }}
              onClick={() => setGoogleLogin(true)}
            >
              <div className={classes.social}>
                <img src={google} alt="Google" className={classes.img} />
              </div>
              Sign up with Google
            </div>
            {isGoogleLogin ? (
              <div style={{ display: "none" }}>
                <GoogleLogin
                  clientId="579029099620-in07uirbdv1gflc25aajps3hbr55hrqr.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                  buttonText="LOGIN WITH GOOGLE"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  autoLoad
                />
              </div>
            ) : null}

            <Grid
              container
              style={{
                width: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={2}>
                OR
              </Grid>
              <Grid item xs={10}>
                <hr />
              </Grid>
            </Grid>

            <div
              className={classes.loginBox}
              style={{ backgroundColor: "#f8913d" }}
              onClick={() => setLoginStatus(true)}
            >
              <div className={classes.social}>
                <img src={mobile} alt="mobile" className={classes.img} />
              </div>
              Sign up with Mobile
            </div>
              {referral ? <div className={classes.loginBox} style={{padding:0, display:'block'}}>
                <span className={classes.title}>Referral Code</span>
                <TextField
                  style={{width: '100%'}}
                  placeholder="Referral"
                  variant="filled"
                  color="secondary"
                  value={referral}
                  InputProps={{
                    classes: { root: classes.inputField },
                  }}
                  disabled
                  classes={{ root: classes.textField }}
                  name="addressLine2"
                  margin="none"
                  // type="number"
                  size="small"
                />
                <br />
                {error && (
                  <span className={classes.error}>
                    ⚠ <ErrorMessage error={error} />
                  </span>
                )}
            </div> : null}
          </div>
        </div>
      </Modal>
      <OTPModal handleClose={() => handleCloseOTP()} open={loginStatus} />
    </>
  );
}

export function ErrorMessage({ error }) {
  if (error) {
    return error;
  }

  return null;
}

export default withStyles(styles)(withIntl(LoginModal));
