/**
 *
 * UserProfile Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExclamationCircle,
  faCheckSquare,
  faCoins,
  faWallet,
  faCog,
  faPowerOff,
  faPiggyBank,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Button, IconButton } from "@material-ui/core";
import classnames from "classnames";

import NavigationLink from "../NavigationLink";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  usedAsDrawer: boolean;

  dp: any;

  userName: string;

  level: string;

  route: string;

  onEditProfile: () => void;

  onClose: () => void;

  onChangeRoute: (route: string) => void;
};

const UserProfile = React.forwardRef((props: Props, ref) => {
  const {
    classes,
    style,
    className,
    // formatMessage,
    usedAsDrawer,
    dp,
    userName,
    level,
    route,
    onEditProfile,
    onClose,
    onChangeRoute,
  } = props;

  const [selectedRoute, setSelectedRoute] = useState(route || "my-bookings");
  useEffect(() => {
    setSelectedRoute(route);
  }, [route]);

  const links = (
    <div className={classes.links}>
      <div className={classes.topLinks}>
        <NavigationLink
          name="My Bookings"
          icon={faCheckSquare}
          className={classes.link}
          onClick={() => {
            setSelectedRoute("my-bookings");
            onChangeRoute("my-bookings");
          }}
          selected={selectedRoute === "my-bookings"}
        />
        <NavigationLink
          name="Win Mad Coins"
          icon={faCoins}
          onClick={() => {
            setSelectedRoute("win-coins");
            onChangeRoute("win-coins");
          }}
          selected={selectedRoute === "win-coins"}
          className={classes.link}
        />
        <NavigationLink
          name="Recharge Wallet"
          icon={faWallet}
          onClick={() => {
            setSelectedRoute("recharge-wallet");
            onChangeRoute("recharge-wallet");
          }}
          selected={selectedRoute === "recharge-wallet"}
          className={classes.link}
        />
        <NavigationLink
          name="Redeem MadRewards"
          icon={faPiggyBank}
          onClick={() => {
            setSelectedRoute("redeem-madrewards");
            onChangeRoute("redeem-madrewards");
          }}
          selected={selectedRoute === "redeem-madrewardss"}
          className={classes.link}
        />
      </div>
      <div className={classes.bottomLinks}>
        <NavigationLink
          name="Settings"
          icon={faCog}
          onClick={() => {
            setSelectedRoute("settings");
            onChangeRoute("settings");
          }}
          selected={selectedRoute === "settings"}
          className={classes.link}
        />
        <NavigationLink
          name="Logout"
          icon={faPowerOff}
          onClick={() => {
            setSelectedRoute("logout");
            onChangeRoute("logout");
          }}
          selected={selectedRoute === "logout"}
          className={classes.link}
        />
      </div>
    </div>
  );

  return (
    <div
      ref={ref}
      className={classnames(classes.container, className)}
      style={style}
    >
      {usedAsDrawer ? (
        <div className={classes.drawer}>
          <IconButton classes={{ root: classes.cutIcon }} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
          <div className={classes.wrap}>
            <img src={dp} alt="Profile Pic" className={classes.dpSmall} />
            <div className={classes.box}>
              <div className={classes.nameDrawer}>{userName}</div>
              <div className={classes.levelDrawer}>
                {`Profile Completed - ${level}%`}
                {/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
              </div>
              <Button
                onClick={onEditProfile}
                variant="contained"
                classes={{ root: classes.editButtonDrawer }}
                disableElevation
                startIcon={
                  <div style={{ fontSize: 8 }}>
                    <FontAwesomeIcon icon={faPen} />
                  </div>
                }
                size="small"
              >
                Edit Profile
              </Button>
            </div>
          </div>
          {links}
        </div>
      ) : (
        <div className={classes.card}>
          <div className={classes.redBox}>
            <img src={dp} alt="Profile Pic" className={classes.dp} />
          </div>
          <div className={classes.wrapper}>
            <div className={classes.name}>{userName}</div>
            <div className={classes.level}>
              {`Profile Completed - ${level}%`}
              {/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
            </div>
            <Button
              onClick={onEditProfile}
              variant="contained"
              classes={{ root: classes.editButton }}
              disableElevation
              startIcon={
                <div style={{ fontSize: 12 }}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              }
              size="large"
            >
              Edit Profile
            </Button>
          </div>
          {links}
        </div>
      )}
    </div>
  );
});
export default withStyles(styles)(UserProfile);
