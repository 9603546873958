const styles = (theme: any) => ({
  wrapper: {
    display: "flex",
    fontFamily: "poppins",
  },
  imagewrapper: {
    width: 300,
    height: 404,
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  button: {
    position: "absolute",
    top: 26,
    right: -30,
    color: "#e90041",
    borderRadius: 30,
    outline: "none",
    padding: "6px 12px",
    borderColor: "#e90041",
    fontSize: 25,
    width: 85,
    height: 49,
  },
  rightwrapper: {
    marginLeft: 41,
    marginTop: 18,
  },
  question: {
    fontSize: 41,
    fontWeight: 600,
  },
  optionwrapper: {
    fontSize: 30,
    marginTop: 35,
  },
  option: {
    marginBottom: 5,
  },
  label: {
    marginLeft: 20
  },
  input: {
    height: 30,
    width: 30,
  },
  questCount: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  "@media (max-width: 967px)": {
  imagewrapper: {
    width: 500,
      height: 404,
        position: "relative",
    },
},
"@media (max-width: 600px)": {
  imagewrapper: {
    display: "inline-block",
      width: "100%",
        textAlign: "center",
    },
  wrapper: {
    flexDirection: "column",
    },
  button: {
    position: "absolute",
      left: '35% !important',
        bottom: '-18px !important',
          top: 'revert'
  },
  rightwrapper: {
    marginLeft: 0,
      marginTop: 40,
      },
  question: {
    fontSize: 28,
      fontWeight: 600,
      },
  optionwrapper: {
    fontSize: 24,
      marginTop: 35,
      },
  option: {
    marginBottom: 5,
      },
  input: {
    height: 20,
      width: 20,
    },
},
"@media (max-width: 400px)": {
  question: {
    fontSize: 18,
      fontWeight: 500,
      },
  optionwrapper: {
    fontSize: 15,
      marginTop: 35,
      },
  option: {
    marginBottom: 5,
      },
  input: {
    height: 18,
      width: 18,
    },
},
});

export default styles;
