export const updateObj = (oldObj:any, updatedProperties:any) =>{
    return{
        ...oldObj,
        ...updatedProperties
    }
};

export const shareFacebook = () =>{
    //console.log(window.location.href);
    window.open(
        'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent("http://mad.fan/"), 
        'facebook-share-dialog', 
        'width=626,height=436'); 
}

export const shareTwitter = () =>{
    //console.log(window.location.href);
    window.open(
        'http://twitter.com/share?text=Im Sharing on Twitter&url=http://mad.fan/','width=626,height=436'); 
}