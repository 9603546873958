/**
 *
 * EditProfile Component
 *
 */
import React, { useState, useEffect } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, InputAdornment } from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useForm, Controller } from "react-hook-form";
import classnames from "classnames";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { ErrorMessage } from "../BookVideoForm";
import fb from "../../images/fb.png";
import ig from "../../images/instagram.png";
import twitter from "../../images/twitter.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  name: string;

  contact: string;

  profilePic: string;

  email: string;

  instaLink: string;

  fbLink: string;

  twitterLink: string;

  birthDate: string;

  onSave: Function;
};

function convertDate(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function EditProfile(props: Props) {
  const {
    classes,
    style,
    className,
    formatMessage,
    name,
    contact,
    profilePic,
    email,
    instaLink,
    fbLink,
    twitterLink,
    birthDate,
    onSave,
  } = props;

  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      name: name,
      email: email,
      contact: contact,
      instaLink: instaLink,
      fbLink: fbLink,
      twitterLink: twitterLink,
    },
  });

  const handleData = (data) => {
    onSave({
      ...data,
      dp,
      birthDate: convertDate(selectedDate),
      filePath,
    });
  };

  const [selectedDate, setSelectedDate] = React.useState<Date | null | string>(
    birthDate || new Date()
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const [dp, setDP] = useState(profilePic);
  const [filePath, setFilePath] = useState("");

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    setFilePath(target.files[0]);
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      setDP(e.target.result);
    };
  };

  return (
    <div className={classnames(className, classes.container)} style={style}>
      <div className={classes.header}>{formatMessage(messages.header)}</div>
      <div
        className={classes.circle}
        style={{
          backgroundImage: `url(${dp})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleCapture}
        />
        <label htmlFor="raised-button-file">
          <Button
            // variant="raised"
            component="span"
            className={classes.button}
          >
            <CameraAltIcon className={classes.cameraicon} />
          </Button>
        </label>
      </div>
      <div className={classes.box}>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Enter Name"
            variant="filled"
            color="secondary"
            size="small"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="name"
            margin="none"
            inputRef={register({ required: true })}
          />
          {errors.name && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.name} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Enter Email"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="email"
            margin="none"
            size="small"
            inputRef={register({
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
          {errors.email && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.email} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Enter Mobile"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
            }}
            classes={{ root: classes.textField }}
            name="contact"
            margin="none"
            type="number"
            size="small"
            inputRef={register({ minLength: 10 })}
          />
          {errors.contact && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.contact} />
            </span>
          )}
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label=""
            style={{
              backgroundColor: "#f3f3f3",
              paddingTop: 18,
              borderRadius: 8,
              width: "100%",
              //   height: 60,
            }}
            InputProps={{
              style: { paddingLeft: 16 },
            }}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />{" "}
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.social}>Social Profile</div>
      <div className={classes.box}>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Instagram"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={ig} alt="Instagram" className={classes.logos} />
                </InputAdornment>
              ),
            }}
            classes={{ root: classes.textField }}
            name="instaLink"
            margin="none"
            size="small"
            inputRef={register()}
          />
          {errors.instaLink && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.instaLink} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Facebook"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={fb} alt="Facebook" className={classes.logos} />
                </InputAdornment>
              ),
            }}
            classes={{ root: classes.textField }}
            name="fbLink"
            margin="none"
            size="small"
            inputRef={register()}
          />
          {errors.fbLink && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.fbLink} />
            </span>
          )}
        </div>
        <div className={classes.wrapper}>
          <TextField
            placeholder="Twitter"
            variant="filled"
            color="secondary"
            InputProps={{
              classes: { root: classes.inputField },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={twitter} alt="Twitter" className={classes.logos} />
                </InputAdornment>
              ),
            }}
            classes={{ root: classes.textField }}
            size="small"
            name="twitterLink"
            margin="none"
            inputRef={register()}
          />
          {errors.twitterLink && (
            <span className={classes.error}>
              ⚠ <ErrorMessage error={errors.twitterLink} />
            </span>
          )}
        </div>
        <Button
          color="secondary"
          variant="contained"
          style={{ width: "100%", marginTop: 40 }}
          onClick={handleSubmit((d) => handleData(d))}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(withIntl(EditProfile));
