import { put, call, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import {
  setLoading,
  setInfluencers,
  setChallenges,
  setChallengeLoading,
  setStartChallenge,
} from "./actions";
import { getNotifications, setUserCoins } from "./../App/actions";
import { selectToken } from "./../App/selectors";

const challengeApi = (infoType: string, token: string, options: {} = {}) => {
  return axios
    .post("/challenge.php", {
      info_type: infoType,
      token,
      ...options,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
function* onLoad() {
  try {
    const token = yield select(selectToken);
    if (token) {
      const influencers = yield call(challengeApi, "1", token);
      if (influencers.status) {
        yield put(setInfluencers(influencers.data));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* onLoadChallenge(action) {
  const id = action.payload;

  try {
    const token = yield select(selectToken);
    if (token) {
      const quizChallenge = yield call(challengeApi, "2", token, {
        influencer_id: id,
      });
      if (quizChallenge.status) {
        yield put(setChallenges(quizChallenge.data));
      }
    }
    yield put(setChallengeLoading(false));
  } catch (e) {
    yield put(setChallengeLoading(false));
  }
}

function* handleChallengeAccepted(action) {
  const { celebId, challenge_id, coin, link } = action.payload;

  try {
    yield put(setChallengeLoading(true));

    const token = yield select(selectToken);
    if (token) {
      const res = yield call(challengeApi, "3", token, {
        challenge_id,
        coin,
        link,
      });
      if (res.status) {
        yield put(setUserCoins(res.coin));
        const quizChallenge = yield call(challengeApi, "2", token, {
          influencer_id: celebId,
        });
        if (quizChallenge.status) {
          yield put(setChallenges(quizChallenge.data));
        }
        yield put(getNotifications());
      }
    }
    yield put(setChallengeLoading(false));
  } catch (e) {
    yield put(setChallengeLoading(false));
    console.log(e);
  }
}

export function* challengeSaga() {
  yield takeLatest(ACTION_TYPES.LOAD, onLoad);
  yield takeLatest(ACTION_TYPES.ON_LOAD_CHALLENGE, onLoadChallenge);
  yield takeLatest(ACTION_TYPES.ON_CHALLENGE_ACCEPTED, handleChallengeAccepted);
}
