/**
 *
 * ImageCard Component
 *
 */
import React, { useState } from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

// import Button from "../Button";
import Button from "@material-ui/core/Button";
import ImageCardHover from "../ImageCardHover";
import coin from "../../images/coin.png";
import "./index.style.css";
type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;

  img: any;

  alt: string;

  price?: number;

  celebName: string;

  famousFor: string[];

  size?: string;

  isHoverable?: boolean;

  onClick: () => void;

  onBook: () => void;

  onViewProfile: () => void;
};

function ImageCard(props: Props) {
  const {
    className,
    classes,
    img,
    alt,
    price,
    celebName,
    famousFor,
    size = "large",
    isHoverable,
    onClick,
    onBook,
    onViewProfile,
  } = props;

  const [imageHovered, setImageHovered] = useState(false);

  return isHoverable ? (
    <Hover
      onHover={
        <ImageCardHover
          img={img}
          alt={alt}
          celebName={celebName}
          famousFor={famousFor}
          onBook={onBook}
          onViewProfile={onViewProfile}
        />
      }
    >
      <Card
        className={classnames(className, classes.container, {
          [classes.smallContainer]: size === "small",
        })}
        onClick={onClick}
      >
        <CardActionArea>
          <CardMedia
            classes={{
              media: size === "small" ? classes.smallMedia : classes.media,
            }}
            image={img}
            title={alt}
            component="img"
            onClick={onClick}
          />
          <CardContent classes={{ root: classes.cardContent }}>
            <div
              className={classnames(classes.celebName, {
                [classes.celebNameSmall]: size === "small",
              })}
            >
              {celebName}
            </div>
            <div
              className={classnames(classes.famousFor, {
                [classes.famousForSmall]: size === "small",
              })}
            >
              {famousFor && famousFor.join(", ")}
            </div>
          </CardContent>
        </CardActionArea>
        {!!price && (
          <CardActions classes={{ root: classes.cardActionRoot }}>
            <Button
              className={classes.priceButton}
              onClick={onClick}
              size="small"
            >
              <img src={coin} alt="Coin" className={classes.coinImg} />
              <div className={classes.noOfCoin}>{`${price?.toString()}`}</div>
            </Button>
          </CardActions>
        )}
      </Card>
    </Hover>
  ) : (
    <Card
      className={classnames(className, classes.container, {
        [classes.smallContainer]: size === "small",
      })}
      onClick={onClick}
    >
      <CardActionArea>
        <CardMedia
          classes={{
            media: size === "small" ? classes.smallMedia : classes.media,
          }}
          image={img}
          title={alt}
          component="img"
          onClick={onClick}
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <div
            className={classnames(classes.celebName, {
              [classes.celebNameSmall]: size === "small",
            })}
          >
            {celebName}
          </div>
          <div
            className={classnames(classes.famousFor, {
              [classes.famousForSmall]: size === "small",
            })}
          >
            {famousFor && famousFor.join(", ")}
          </div>
        </CardContent>
      </CardActionArea>
      {!!price && (
        <CardActions classes={{ root: classes.cardActionRoot }}>
          <Button
            className={classes.priceButton}
            onClick={onClick}
            size="small"
          >
            <img src={coin} alt="Coin" className={classes.coinImg} />
            <div className={classes.noOfCoin}>{`${price?.toString()}`}</div>
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

function Hover({ onHover, children }) {
  return (
    <div className="hover">
      <div className="hover__no-hover">{children}</div>
      <div className="hover__hover">{onHover}</div>
    </div>
  );
}

export default withStyles(styles)(withIntl(ImageCard));
