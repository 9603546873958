import { put, call, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import { selectToken } from "../App/selectors";
import {
  setRedeemLoading,
  setRedeemStatus,
  setSendingMailSuccess,
} from "./actions";

const redeemGiftCard = (data: any, token: string, infoType: string) => {
  console.log(data);
  return axios
    .post("/amazon_order.php", {
      ...data,
      token,
      info_type: infoType,
    })
    .then((res) =>{
      console.log(res.data);
      return res.data
    })
    .catch((err) => {
      throw err;
    });
};

const sendMails = (data: any, token: string, infoType: string) => {
  return axios
    .post("/user.php", {
      ...data,
      token,
      info_type: infoType,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

function* onRedeemGiftCard(action) {
  try {
    const data = action.payload;

    const updatedData = {
      ...data,
      line1: data.addressLine1,
      line2: data.addressLine2,
    };
    const token = yield select(selectToken);
    const res = yield call(redeemGiftCard, updatedData, token, "1");

    yield put(setRedeemStatus(res.status, res.message));
    yield put(setRedeemLoading(false));
  } catch (e) {
    yield put(setRedeemStatus(false, "Please try later!"));
    yield put(setRedeemLoading(false));
  }
}

function* onSendMails(action) {
  try {
    const mails = action.payload;
    const token = yield select(selectToken);
    const res = yield call(sendMails, mails, token, "1");
    if (res.status) {
      yield put(setSendingMailSuccess(true));
    } else {
      yield put(setSendingMailSuccess(false));
    }
  } catch (e) {
    yield put(setSendingMailSuccess(false));
  }
}

export function* userProfileSaga() {
  //   yield takeLatest(ACTION_TYPES.LOAD, onLoad);
  yield takeLatest(ACTION_TYPES.ON_REDEEM_GIFT_CARD, onRedeemGiftCard);
  yield takeLatest(ACTION_TYPES.SEND_MAILS, onSendMails);
}
