const styles = (theme: any) => ({
  container: { fontFamily: "Poppins" },
  header: {},
  coinIcon: { color: "#e90041", marginRight: 16 },
  winCoins: { fontSize: 28, fontWeight: "600" },
  options: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 28,
  },
  subTitle: {},
  "@media (max-width: 967px)": {
    winCoins: { fontSize: 20, fontWeight: "600" },
    options: {
      justifyContent: "center",
      marginTop: 28,
      flexWrap: "wrap",
    },
  },
});

export default styles;
