import { put, call, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as ACTION_TYPES from "./constants";
import axios from "../../shared/axios-details";
import { selectToken } from "./../App/selectors";
import { setLoading, setOrders } from "./actions";

const orders = (infoType: string, token: string, options: {} = {}) => {
  return axios
    .post("/order.php", {
      info_type: infoType,
      token,
      ...options,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
function* onLoad() {
  try {
    const token = yield select(selectToken);
    if (token) {
      const myBookings = yield call(orders, "2", token);
      if (myBookings.status) {
        yield put(setOrders(myBookings.data));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

export function* myBookingsSaga() {
  yield takeLatest(ACTION_TYPES.LOAD, onLoad);
}
