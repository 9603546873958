/**
 *
 * Congratulations Component
 *
 */
import React from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Container, Box, Button } from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";
import ListSubheader from "@material-ui/core/ListSubheader";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import checkcircle from "../../images/checkcircle.png";
import star from "../../images/star.png";

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
  type: string;
  onClose: (message: string) => void;
  challengeName: string;
  celebName: string;
  challengePersonName: string;
  onClick: () => void;
  coin?: any;
  handleButton?: any;
};

function Congratulations(props: Props) {
  const {
    classes,
    style,
    formatMessage,
    type,
    onClose,
    challengeName,
    celebName,
    challengePersonName,
    onClick,
    coin,
    handleButton
  } = props;
  const {
    contactsheader,
    Congratulationheader,
    bookvideodescription,
    bookvideodetails,
    contactdescription,
    coindescription,
    header,
  } = messages;
  const close = () => {
    onClose("closed");
  };
  const ChallengeFragment = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          className={classes.challenge}
        >
          {formatMessage(header)}
        </ListSubheader>
      }
      className={classes.listheader}
    >
      <ListItem>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>1</Avatar>
        </ListItemAvatar>
        <ListItemText primary="Share on any social media." />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>2</Avatar>
        </ListItemAvatar>
        <ListItemText primary="Mail us your challenge links shared on social media" />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>3</Avatar>
        </ListItemAvatar>
        <ListItemText primary="Our team will judge all links." />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarwrapper}>
          <Avatar className={classes.avatar}>4</Avatar>
        </ListItemAvatar>
        <ListItemText primary="Our Judiciary panel will declare lucky winner. You'll be notified." />
      </ListItem>
    </List>
  );
  return (
    <div className={classes.wrapper} style={style}>
      <Container>
        {/* <div className={classes.close} onClick={close}>
          <CloseIcon />
        </div> */}
        <div className={classes.circle}>
          <img src={checkcircle} className={classes.circleIcon} />
        </div>
        <div className={classes.header}>
          {type == "coin"
            ? formatMessage(Congratulationheader)
            : type == "contact"
            ? formatMessage(contactsheader)
            : type == "bookvideo"
            ? formatMessage(Congratulationheader)
            : formatMessage(Congratulationheader)}
        </div>
        <div className={classes.success}>
          {type == "bookvideo"
            ? celebName
            : type == "challenge"
            ? "You have successfully accepted  " + challengeName + " challenge"
            : type == "coin"
            ? formatMessage(coindescription)
            : type == "contact"
            ? formatMessage(contactdescription)
            : null}
        </div>
        <div className={classes.bottom}>
          {type == "bookvideo" ? (
            <>
            {formatMessage(bookvideodetails)}

              <Button className={classes.buttonBooking} onClick={handleButton}>
                View Booking
              </Button>
            </>
          ) : type == "coin" ? (
            <>
              <Box
                textAlign="center"
                color="initial"
                fontSize="18px"
                fontWeight="bold"
                className={classes.buttonwrapper}
              >
                Your Total Coins:
              </Box>
              <Button className={classes.button}>
                <img src={star} className={classes.coinicon} />
                {/* <StarsIcon className={classes.coinicon} />  */}
                {coin}
              </Button>
            </>
          ) : type == "challenge" ? (
            <ChallengeFragment />
          ) : null}
        </div>
        {/* <Button
          onClick={onClick}
          variant="contained"
          color="secondary"
          style={{
            margin: "auto",
            marginTop: 32,
            marginBottom: 32,
            width: "100%",
          }}
        >
          My Bookings
        </Button> */}
      </Container>
    </div>
  );
}

export default withStyles(styles)(withIntl(Congratulations));
