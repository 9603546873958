const styles = (theme: any) => ({
  root: {
    boxSizing: "border-box",
    fontFamily: "Poppins",
    textTransform: "none",
    "&:hover": { backgroundColor: "rgba(233, 0, 65, 0.1)" },
  },
  containedPrimary: {
    backgroundColor: "#f3f3f3",
  },
  containedSecondary: {},
  containedSizeRegular: {
    fontWeight: 500,
    padding: "16px 44px",
    borderRadius: 30,
    fontSize: 20,
    height: 60,
    minWidth: 192,
  },
  containedSizeSmall: {
    fontWeight: 500,
    padding: "4px 16px",
    borderRadius: 18,
    fontSize: 18,
    height: 36,
    minWidth: 126,
  },
  selected: {
    color: "#e90041",
    backgroundColor: "rgba(233, 0, 65, 0.1)",
  },

  "@media (max-width: 1377px)": {
    containedSizeRegular:{
      minWidth: 160,
      padding: "5px 15px"
    }
  },

  "@media (max-width: 600px)": {
    containedSizeSmall:{
      minWidth: 100,
      padding: "4px 8px"
    }
  }
});

export default styles;
