/**
 * BonusCoin Messages
 *
 * This contains all the text for the BonusCoin Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.BonusCoin";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Earned Bonus Coins",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "You have successfully answered all questions.",
  },
  headerFailure: {
    id: `${scope}.headerFailure`,
    defaultMessage: "Oops!!",
  },
  detailsFailure: {
    id: `${scope}.detailsFailure`,
    defaultMessage: "You got one or more answers wrong",
  },
});
